import React, { useState, useRef } from "react";
import "./Convocation7th.css";
import Iframe from "react-iframe";
import Carousel from "nuka-carousel";
import newgif from "../NEW/new.gif";

import AnnoucementforseventhconvocationoftheUniversity from "../Notifications/Notification Files/Announcement for seventh annual convocation of the university.pdf";
import RegistrationFormFor7thConvocation from "../Notifications/Notification Files/Registration Form for 7th Convocation .pdf";
import circularfor7thconvo from "../Notifications/Notification Files/Circular for 7th convocation.pdf";
import convocationCostume from "../Notifications/Notification Files/Academic Costume for Convocation.pdf";

import Extensionofthestudentregistrationforthe7tgconvo from "../Notifications/Notification Files/Extension of the Student registration for the 7th convocation.pdf";
import generalInstuctionforstudents7thconvocatation from "../Notifications/Notification Files/General Instructions to the Candidates.pdf";
import RehearsalInstructionFor7thConvoon4thMarch from "../Notifications/Notification Files/Rehearsal Instructions for 7th Convocation on 4th March 2025.pdf";
import seventConvoInvitationCard from "../Notifications/Notification Files/7th Comvocation Invitation Card.pdf";
import convoFirstImage from "./Convo Media/convocation 7th Backdrop.jpeg";

import convo01 from "../TakeATour/Home Gallery/7th Convocation/convo 01.jpg";
import convo02 from "../TakeATour/Home Gallery/7th Convocation/convo 02.jpg";
import convo002 from "../TakeATour/Home Gallery/7th Convocation/convo 002.jpg";
import convo03 from "../TakeATour/Home Gallery/7th Convocation/convo 03.jpg";
import convo04 from "../TakeATour/Home Gallery/7th Convocation/convo 04.jpg";
import convo05 from "../TakeATour/Home Gallery/7th Convocation/convo 05.jpg";
import convo06 from "../TakeATour/Home Gallery/7th Convocation/convo 06.jpg";
import convo07 from "../TakeATour/Home Gallery/7th Convocation/convo 07.jpg";
import convo08 from "../TakeATour/Home Gallery/7th Convocation/convo 08.jpg";
import convo09 from "../TakeATour/Home Gallery/7th Convocation/convo 09.jpg";
import convo10 from "../TakeATour/Home Gallery/7th Convocation/convo 10.jpg";

import crouselImg01 from "./Convo Media/crousel img/New/img01.jpg";
import crouselImg02 from "./Convo Media/crousel img/New/img02.jpg";
import crouselImg03 from "./Convo Media/crousel img/New/img03.jpg";
import crouselImg04 from "./Convo Media/crousel img/New/img04.jpg";
import crouselImg05 from "./Convo Media/crousel img/New/img05.jpg";
import crouselImg06 from "./Convo Media/crousel img/New/img06.jpg";
import crouselImg07 from "./Convo Media/crousel img/New/img07.jpg";
import crouselImg08 from "./Convo Media/crousel img/New/img08.jpg";
import crouselImg09 from "./Convo Media/crousel img/New/img09.jpg";
import crouselImg10 from "./Convo Media/crousel img/New/img10.jpg";
import crouselImg11 from "./Convo Media/crousel img/New/img11.jpg";

import DelhiGovernorImg from "./Convo Media/delhi_governor-Image_0.jpg";
import ShreeHarivansh from "./Convo Media/Harivansh_DC_ccee5852e7.jpg";
import MsNandaniPaliwal from "./Convo Media/msNandani paliwal.jpg";
import JitenderNVerma from "./Convo Media/Jitendra N Verma sir.jpeg";
import ProfRavichandiranv from "./Convo Media/prof v ravichandiran.jpeg";

import PhdAward01 from "./Convo Media/PhD Award/PhD Pankaj Musyuni.jpg";
import PhdAward02 from "./Convo Media/PhD Award/PhD Ashwini Kumar Mishra.jpg";
import PhdAward03 from "./Convo Media/PhD Award/PhD Gautam Bhardwaj.jpg";
import PhdAward04 from "./Convo Media/PhD Award/PhD Pankaj Kumar.jpg";
import PhdAward05 from "./Convo Media/PhD Award/PhD Vaishali Agrawal.jpg";
import PhdAward06 from "./Convo Media/PhD Award/PhD Ritu Tiwari.jpg";
import PhdAward07 from "./Convo Media/PhD Award/PhD Renu Bala Yadav.jpg";
import PhdAward08 from "./Convo Media/PhD Award/PhD Vivek Kumar.jpg";
import PhdAward09 from "./Convo Media/PhD Award/PhD Arzoo.jpg";

import GoldMedlist01 from "./Convo Media/Gold Medelist/1 Mr. Rohit Rathore.JPG";
import GoldMedlist02 from "./Convo Media/Gold Medelist/2 Ms. Dishti Narang.JPG";
import GoldMedlist03 from "./Convo Media/Gold Medelist/3 Mr. Titir Santra.JPG";
import GoldMedlist04 from "./Convo Media/Gold Medelist/4 Ms. Somya Singhal.JPG";
import GoldMedlist05 from "./Convo Media/Gold Medelist/5 Ms. Jessica Joy.JPG";
import GoldMedlist06 from "./Convo Media/Gold Medelist/6 Ms. Bhawna.JPG";
import GoldMedlist07 from "./Convo Media/Gold Medelist/7 Mr. Abhishek Anand.JPG";
import GoldMedlist08 from "./Convo Media/Gold Medelist/8 Vrishti Arora.JPG";
import GoldMedlist09 from "./Convo Media/Gold Medelist/9 Ms. Anjali.JPG";
import GoldMedlist10 from "./Convo Media/Gold Medelist/10 Ms.Priyanka Gupta.JPG";
import GoldMedlist11 from "./Convo Media/Gold Medelist/11 Ms. Somya Singhal.JPG";
import GoldMedlist12 from "./Convo Media/Gold Medelist/12 Ms. Muskaan.JPG";
import GoldMedlist13 from "./Convo Media/Gold Medelist/13 Ms. Aishwarya Chand.JPG";
import GoldMedlist14 from "./Convo Media/Gold Medelist/14 Ms. Deeksha Khatri.JPG";
import GoldMedlist15 from "./Convo Media/Gold Medelist/15 Ms. Sapna.jpg";
import GoldMedlist16 from "./Convo Media/Gold Medelist/16 Ms. Gauri Sharma.JPG";
import GoldMedlist17 from "./Convo Media/Gold Medelist/17 Nano Safe Solutions.JPG";
import GoldMedlist18 from "./Convo Media/Gold Medelist/18 Mr. Abhishek Wahi.JPG";

import preBookletConvocation from "./Convo Media/Pre convocation booklet.pdf";

const Convocation7th = () => {
  const marqueeRef = useRef(null);
  const marqueeRefsec = useRef(null);

  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };

  const handleMouseOut = () => {
    marqueeRef.current.start();
  };

  const handleMouseOver2 = () => {
    marqueeRefsec.current.stop();
  };

  const handleMouseOut2 = () => {
    marqueeRefsec.current.start();
  };

  return (
    <div className="dpsru-every-component-css-convocation convocation-component">
      {/* <h2 className="dpsru-primary-heading-for-every-page"> 7th Convocation</h2> */}

      <marquee
        ref={marqueeRef}
        behavior="scroll"
        direction="left"
        className="marquee-notification"
        loop=""
        Scrollamount="7"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <ul className="convo-7th-ul">
          <li>
            <a href={seventConvoInvitationCard}>
              7th Convocation Invitation Card
            </a>{" "}
          </li>
          <li>
            <a href={RehearsalInstructionFor7thConvoon4thMarch}>
              Rehearsal Instructions for 7th Convocation on 4th March 2025
            </a>{" "}
          </li>
          <li>
            <a href={generalInstuctionforstudents7thconvocatation}>
              General Instructions to the Candidates (7th Convocation)
            </a>{" "}
          </li>
          <li>
            <a href={Extensionofthestudentregistrationforthe7tgconvo}>
              Extension of the Student registration for the 7th convocation
            </a>{" "}
          </li>

          <li>
            <a href={convocationCostume}>Academic Costume for Convocation</a>{" "}
          </li>

          <li>
            <a href={circularfor7thconvo}>7th convocation Circular</a>{" "}
          </li>

          <li>
            <a href={RegistrationFormFor7thConvocation}>
              Registration Form for 7th Convocation
            </a>{" "}
          </li>

          <li>
            <a href={AnnoucementforseventhconvocationoftheUniversity}>
              Announcement for seventh annual convocation of the university
            </a>{" "}
          </li>
          <li>
            <a href={preBookletConvocation}>Pre convocation booklet</a>{" "}
          </li>
        </ul>
      </marquee>

      <div className="convocation-inner-container">
        <div className="convocation-home-page-first-section-backdrop">
          <div className="convocation-backdrop-container-image">
            <img src={convoFirstImage} alt="Convocation" />
          </div>
        </div>
        <div className="convocation-photo-video-gallery-section">
          <div className="convo-photo-gallery">
            <h2 className="convocation-photo-video-heading-text">
              Photos Gallery
            </h2>
            <Carousel
              autoplay="true"
              autoplayReverse="true"
              animation="zoom"
              pauseOnHover="true"
              wrapAround={true}
              withoutControls={false}
              autoplayInterval={5000}
              className="convoation-nuca-crousel"
            >
              <img src={convo10} alt="logo" />
              <img src={convo09} alt="logo" />

              <img src={convo04} alt="logo" />

              <img src={crouselImg01} alt="logo" />
              <img src={crouselImg02} alt="logo" />

              <img src={crouselImg08} alt="logo" />
              <img src={crouselImg09} alt="logo" />
              <img src={crouselImg10} alt="logo" />
              <img src={convo08} alt="logo" />
              <img src={convo07} alt="logo" />
              <img src={crouselImg07} alt="logo" />
              <img src={convo06} alt="logo" />
              <img src={convo05} alt="logo" />
              <img src={convo002} alt="logo" />
              <img src={convo02} alt="logo" />
              <img src={convo01} alt="logo" />

              <img src={crouselImg11} alt="logo" />
              <img src={crouselImg03} alt="logo" />
              <img src={convo03} alt="logo" />
              <img src={crouselImg06} alt="logo" />
              <img src={crouselImg05} alt="logo" />
              <img src={crouselImg04} alt="logo" />
            </Carousel>
          </div>

          {/* Video */}
          <div className="convo-photo-gallery">
            <h2 className="convocation-photo-video-heading-text">
            7th Convocation of DPSRU | 5th March 2025
            </h2>

            <iframe
              src="https://www.youtube.com/embed/oom03yPtzLs?si=kPFKwyVlzpdbiW2s"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              className="convoation-ifram-video"
            ></iframe>
          </div>
        </div>
        <div className="convocation-text-box-container">
          <div className="convo-text-box">
            <b>
              {" "}
              Delhi Pharmaceutical Sciences and Research University (DPSRU)
              welcomes you all to the 7th Annual Convocation Ceremony!{" "}
            </b>
            <br />
            This momentous occasion marks the celebration of dreams,
            achievements, academic and research excellence, and the aspirations
            of our graduating students. As you step into the world of
            innovation, healthcare, and discovery, you carry the knowledge and
            skills to make a meaningful impact on society.
            <br />
            We are confident that our graduates will uphold the values and
            legacy of DPSRU, contributing to the advancement of pharmaceutical
            and healthcare sciences while serving the nation with dedication.
            <br /> <br />
            <b>
              Heartiest congratulations to all the graduating students, proud
              parents, esteemed faculty members, and the entire DPSRU
              fraternity!
            </b>
          </div>
        </div>
        <div className="guest-of-convocation-main-container">
          <h2 className="guest-of-convo-heading phd-degree-heading-convo">Guests</h2>
          <div className="chief-guest-list-container-convocation">
            {/* Chief Guest box */}
            <div className="chief-guest-convo-box">
              <div className="guest-heading-name-convo">Chancellor</div>
              <img
                src={DelhiGovernorImg}
                alt="Chief Guest"
                className="guest-convo-image"
              />
              <div className="name-and-designation-of-guest-convo">
                Sh. Vinai Kumar Saxena <br />
                Hon'ble Lt. Governor of Delhi
              </div>
            </div>

            {/* Chief Guest box */}
            <div className="chief-guest-convo-box">
              <div className="guest-heading-name-convo">Chief Guest</div>
              <img
                src={ShreeHarivansh}
                alt="Chief Guest"
                className="guest-convo-image"
              />
              <div className="name-and-designation-of-guest-convo">
                Shri Harivansh <br />
                Hon'ble Deputy Chairman <br /> Rajya Sabha
              </div>
            </div>

            {/* Chief Guest box */}
            <div className="chief-guest-convo-box">
              <div className="guest-heading-name-convo">Special Guest</div>
              <img
                src={MsNandaniPaliwal}
                alt="Chief Guest"
                className="guest-convo-image"
              />
              <div className="name-and-designation-of-guest-convo">
                Smt. Nandini Paliwal <br /> Secretary <br />
                DTTE
              </div>
            </div>

            {/* Chief Guest box */}
            <div className="chief-guest-convo-box">
              <div className="guest-heading-name-convo">Chairman, BOG</div>
              <img
                src={JitenderNVerma}
                alt="Chief Guest"
                className="guest-convo-image"
              />
              <div className="name-and-designation-of-guest-convo">
                Dr. Jitendra N. Verma <br /> Chairman <br /> Board of Governers
              </div>
            </div>

            {/* Chief Guest box */}
            <div className="chief-guest-convo-box">
              <div className="guest-heading-name-convo">Vice Chancellor</div>
              <img
                src={ProfRavichandiranv}
                alt="Chief Guest"
                className="guest-convo-image"
              />
              <div className="name-and-designation-of-guest-convo">
                Prof. Ravichandiran V. <br />
                Vice Chancellor <br /> DPSRU New Delhi
              </div>
            </div>
          </div>
          <br /> <br />
        </div>
        {/* PhD students to receive the degree from the Hon’ble
        Chancellor */}
        <div className="phd-receving-degree-container">
          <br />
          <h2 className="phd-degree-heading-convo">Awardees</h2>

          <h2 className="phd-degree-heading-convo-secondary">
          PhD Students Who Received Their Degrees from the Hon’ble Chancellor
          </h2>
          <div className="phd-receving-degree-inner-container">
            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward01}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">
                Mr. Pankaj Musyuni
              </div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward02}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">
                Mr. Ashwini Kumar Mishra
              </div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward03}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">
                Mr. Gautam Bhardwaj
              </div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward04}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">Mr. Pankaj Kumar</div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward05}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">
                Ms. Vaishali Agrawal
              </div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward06}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">Ms. Ritu Tiwari</div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward07}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">
                Ms. Renu Bala Yadav
              </div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward08}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">Mr. Vivek Kumar</div>
            </div>

            {/* phd degree box */}
            <div className="Phd-student-degree-box">
              <img
                src={PhdAward09}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder">Ms. Arzoo</div>
            </div>
          </div>

          {/* GOLD medlist */}
          <h2 className="phd-degree-heading-convo-secondary">Gold Medalists and Awardees</h2>
          <div className="phd-receving-degree-inner-container">
            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SITARAM JINDAL GOLD MEDAL
              </div>
              <img
                src={GoldMedlist01}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Mr. Rohit Rathore <br />
                B. Pharm.{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SITARAM JINDAL GOLD MEDAL
              </div>
              <img
                src={GoldMedlist02}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Dishti Narang <br />
                Bachelor of Physiotherapy
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                VICE CHANCELLOR AWARD
              </div>
              <img
                src={GoldMedlist03}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Mr. Titir Santra <br />
                B. Sc. (Hons.) Sports Sciences{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                VIJAYIN ALNICHE GOLD MEDAL
              </div>
              <img
                src={GoldMedlist04}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Ms. Somya Singhal <br />
                M. Pharm.{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                PROF. S.K. GUPTA GOLD MEDAL
              </div>
              <img
                src={GoldMedlist05}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Jessica Joy <br />
                M. Pharm. Clinical Research{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                B.D MIGLANI AWARD and S.L. NASA HOSPITAL PHARMACY FOUNDATION
                AWARD
              </div>
              <img
                src={GoldMedlist06}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Bhawna <br />
                M. Pharm. Hospital Pharmacy{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                AIMIL PHARMACEUTICALS INDIA AWARD
              </div>
              <img
                src={GoldMedlist07}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Mr. Abhishek Anand <br />
                M. Pharm. Pharmacognosy and Phytochemistry{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SOSHIL KUMAR JAIN, PANACEA BIOTEC GOLD MEDAL
              </div>
              <img
                src={GoldMedlist08}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Vrishti Arora <br />
                M. Pharm. Biotechnology{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SHRI RAJESH CHAND ARORA, AAGYA BIOTECH GOLD MEDAL
              </div>
              <img
                src={GoldMedlist09}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Anjali <br />
                M. Pharm. Pharmacology{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SNEH PRABHA NEMI CHAND JAIN AWARD
              </div>
              <img
                src={GoldMedlist10}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms.Priyanka Gupta <br />
                M. Pharm. Pharmaceutics / Cosmeceutics / Quality Assurance /
                Industrial Pharmacy{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                ‘RAJRISHI’ AJAY PANKAJ PARASHAR AWARD
              </div>
              <img
                src={GoldMedlist11}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Somya Singhal <br /> M. Pharm. Drug Regulatory Affairs{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SHRI M.G. GEORGE MUTHOOT AWARD
              </div>
              <img
                src={GoldMedlist12}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Ms. Muskaan <br />
                MBA-Pharmaceutical Management and International Trade Management{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SHRI M.G. GEORGE MUTHOOT AWARD
              </div>
              <img
                src={GoldMedlist13}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Ms. Aishwarya Chand <br /> MBA-Healthcare and Hospital
                Management
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                SHRI M.G. GEORGE MUTHOOT AWARD
              </div>
              <img
                src={GoldMedlist14}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Ms. Deeksha Khatri <br />
                Master In Public Health{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                PROF. S S AGRAWALGOLD MEDAL AWARD
              </div>
              <img
                src={GoldMedlist15}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Sapna <br />
                D. Pharm.{" "}
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                PROF. S.S. AGRAWAL TROPHY
              </div>
              <img
                src={GoldMedlist16}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                Ms. Gauri Sharma <br />
                Bachelor of Physiotherapy
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">
                JWALAT ALNICHE GOLD MEDAL
              </div>
              <img
                src={GoldMedlist17}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Nano Safe Solutions
              </div>
            </div>

            {/* Gold Medist box */}
            <div className="Phd-student-degree-box">
              <div className="gold-medelist-heading-convo">LG TROPHY AWARD</div>
              <img
                src={GoldMedlist18}
                alt="Chief Guest"
                className="phd-degree-image"
              />
              <div className="name-of-phd-degree-holder-gold-medelist">
                {" "}
                Mr. Abhishek Wahi (Received by Mother) <br /> Bachelor of
                Pharmacy (Dipsar)
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Convocation7th;
