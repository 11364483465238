import React from "react";
import "../DIPSAR/Dipsar.css";
import { NavLink } from "react-router-dom";

// Import files
// import BPT from './SopFiles/BPT.pdf'
// import BPT from './SopFiles/BPT Final syllabus 2023 onwards.pdf'
import BPT from "./SopFiles/BPT Syllabus 2023 onwards Replaced.pdf";
import MPT from "./SopFiles/MPT.pdf";
//Faculty
import DrRicha from "./Physiotherapy faculty/Dr. Richa Hirendra Rai/IMG_6479-scaled-e1685346918487.jpg";
import drshilpaJain from "./Physiotherapy faculty/Dr. Shilpa Jain/IMG_6480-scaled-e1685347118414.jpg";
import DrSheetalK from "./Physiotherapy faculty/Dr. Sheetal Kalra/IMG_6485-scaled-e1685347032993.jpg";
import DrSapna from "./Physiotherapy faculty/05-Dr. Sapna Dhiman/IMG_6471-scaled-e1685347232883.jpg";
import DrParulS from "./Physiotherapy faculty/04-Dr. Parul Sharma/IMG_6488-scaled-e1685347312636.jpg";
import TabassumS from "./Physiotherapy faculty/07-Dr. Tabassum Saher/tab-new-pic-tabassum-saher-150x150.jpg";
// import ShilpaJ from './Physiotherapy faculty/Dr. Shilpa Jain/IMG_6480-scaled-e1685347118414.jpg'
import Jitender from "./Physiotherapy faculty/09-Dr. Jitender Munjal/IMG_6468-scaled-e1685347599650.jpg";
import VarshaC from "./Physiotherapy faculty/08- Dr. Varsha Chorsiya/IMG_6487-scaled-e1685347398153.jpg";
import NehaK from "./Physiotherapy faculty/06-Dr. Neha Kumari/IMG_6490-scaled-e1685347561595.jpg";
import DrSavita from "./Physiotherapy faculty/10- Dr. Savita Tamaria/IMG_6489-scaled-e1685347470654.jpg";
import DrRavinder from "./Physiotherapy faculty/11- Dr. Ravinder Kumar/IMG_6470-scaled-e1685347666997.jpg";
import susahiniD from "./Physiotherapy faculty/12-Ms. Suhasini D/WhatsApp-Image-2023-01-30-at-2.49.24-PM-150x150.jpeg";
import priyanakaSonam from "./SopFiles/IMG_2576-1-2048x1365.4dd4725da17818f5faff.jpg";

import act01 from "./SopFiles/2018-19-RECENT-ADVANCEMENT-AND-LUMBO-SACRAL-MANUAL-THERAPY.pdf";
import act02 from "./SopFiles/2019-20-CLINICAL-APPLICATION-OF-PROPRIOCEPTIVE-NEUROMUSCULAR-FACILITATION.pdf";
import act03 from "./SopFiles/2019-20-ANNUAL-SPORTS-MEET.pdf";
import act04 from "./SopFiles/2019-20-SEMINAR-ALL-INDIA-PHYSIOTHERAPY-SEMINAR-ON-ANTIDOPPING-2.pdf";
import act05 from "./SopFiles/report.pdf";
import act06 from "./SopFiles/career-counselling-webinar-report.pdf";
import act07 from "./SopFiles/eye-camp.pdf";
import act08 from "./SopFiles/Activities-organized-by-School-of-Physiotherapy.pdf";
import act09 from "./SopFiles/report-Workshop-on-Dryneedling.pdf";
import act10 from "./SopFiles/Workshop-on-Kinesiotaping.pdf";
import act11 from "./SopFiles/15-12-2022_Visceral-Osteopathy.pdf";

import act12HealthcamofschoolOfPhysiotheraphyinAssocwsob from "./SopFiles/Health camp of School of Physiotherapy in association with Special Olympics Bharat.pdf";
import HIVaidsWorkshop210923 from "./SopFiles/21-09-2023_ HIV AIDS workshop.pdf";
import reportOnWorkshoponBLS171023 from "./SopFiles/17-10-2023_Report on Workshop on BLS.pdf";
import dpsruatNitrdWorkshop161123 from "./SopFiles/16-11-2023_DPSRU AT NITRD WORKSHOP.pdf";
import AmateurContenderSeries from "./SopFiles/amateur contender series.pdf";
import inceptAiims from "./SopFiles/incpt aiims.pdf";
import webinarOnCarrer from "./SopFiles/webinar on career counselling.pdf";
import orientationProgramReportMpt2023 from "./SopFiles/ORIENTATION PROGRAM REPORT MPT 2023.pdf";
import WorkshopBLs from "./SopFiles/workshop bls.pdf";
import ReportOfWorkshoponPersonalityDeveloper from "./SopFiles/REPORT OF WORKSHOP ON PERSONALITY DEVELOPMENT.pdf";
import wpd2022writeupFinal from "./SopFiles/wpd 2022 write up final.pdf";
import WorkshopOnPedagogyForMptStudents from "./SopFiles/Workshop on Pedagogy for MPT students.pdf";
import psysiotheraphyHealthcampasaPartofVIksitBharatabhiyan from "./SopFiles/Physiotherapy health camp as a part of Vikshit Bharat Abhiyan.pdf";
import partiipationofMptstudentmssakshiwithdelhistatefootballteam from "./SopFiles/PARTICIPATION OF MPT STUDENT MS SAKSHI WITH DELHI STATE FOOTBALL TEAM.pdf";
import participationofMptstudentMrdaniyalinu20footballchapionship from "./SopFiles/PARTICIPATION OF MPT STUDENT MR DANIYAL IN U-20 FOOTBALL CHAMPIONSHIP.pdf";
import mptpediatricsSyllabus24 from "./SopFiles/MPT Pediatrics Syllabus 2024.pdf";
import MptcaridovascularpandpulSyllabus from "./SopFiles/MPT Cardiovascular and Pulmonary  syllabus 2024.pdf";
import ishikahainwins2ndprizeinscintificpaperpresentaiton from './SopFiles/ISHIKA JAIN WINS 2ND PRIZE IN SCIENTIFIC PAPER PRESENTATION.pdf'
// import worldPhysiotherapyday2 from './SopFiles/World physiotherapy day -2.pdf'
import worldPhysiotherapyday2 from './SopFiles/WORLD PHYSIOTHEERAPY DAY SPORTS CARNIVAL.pdf'
import mptstudentmsDeepshikhawins3rdinpaperpreantaritahos from './SopFiles/MPT student Ms Deepshikha wins 3rd prize in paper presentation at Amrita hospital conference.pdf'
import farewellofBPTandMPTstudents from './SopFiles/Farewell of BPT and MPT students.pdf'
import dpsruMptssatindiavsgemhocbilmatch from './SopFiles/DPSRU MPT SPORTS STUDENTS AT INDIA VS GERMANY HOCKEY BILATERAL MATCH.pdf'
import WorkshoponSystematicreviewandMetanalysisforBPTandMPTStudents from './SopFiles/WORKSHOP ON SYSTEMATIC REVIEW AND METANALYSIS FOR BPT AND MPT STUDENTS.pdf'
import Drsheetalkalrainvitedforguestlacturebydept from './SopFiles/Dr Sheetal Kalra invited for Guest Lecture by Deptt of Geriatric medicine,AIIMS.pdf'
import Mptspeotsstudentspostedatsportsauthofindia from './SopFiles/MPT sports students posted at Sports Authority of India.pdf'
import Reporton73allindiapoliceathleticclusterchamp2024injln from './SopFiles/Report on 73rd All India Police Athletics cluster Championship 2024 in JLN stadium.pdf'
// import MPTPediatricsStudentsAttendedAFPsicon25 from './SopFiles/MPT-Pediatrics students attended AFPSICON- 2025.pdf'
import MPTPediatricsStudentsAttendedAFPsicon25 from './SopFiles/MPT-Pediatrics students attended AFPSICON- 2025-.pdf'
// import ReportOnSeminamrANdWorkShoponStrengthandcondinsports from './SopFiles/Report on Seminar and Workshop on Strength and Conditioning in Sports.pdf'
// import ReportOnSeminamrANdWorkShoponStrengthandcondinsports from './SopFiles/Report on Seminar and Workshop on Strength and Conditioning in Sports final.pdf'
import ReportOnSeminamrANdWorkShoponStrengthandcondinsports from './SopFiles/WORKSHOP ON STRENGTH AND CONDIITIONING.pdf'
import InternationalSeminaronIntegratedMedicine from './SopFiles/International Seminar on Integrated Medicine.pdf'
import ReportonthisstrokeRehabilitationworkshopHeldon28feb25 from './SopFiles/Report on the Stroke Rehabilitation Workshop Held on 28th February 2025.pdf'
import ParticiaptionofMPTStudentsinKhoKhoworkdCup from './SopFiles/PARTICIAPTION OF MPT STUDENTS IN KHO KHO WORLD CUP.pdf'
import OutReactActivityunderunntBharatAbhiyan from './SopFiles/Out Reach Activity under Unnat Bharat Abhiyan.pdf'

function SchoolofPhysiotherapy() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        School of Physiotherapy
      </h2>
      <h2 className="dipsar-sub-heading">Introduction</h2>
      <p className="dipsar-paragrauph">
        School of physiotherapy was started in the year 2015 and has since grown
        into an institution of recognition and repute in India. Housed in
        spacious campus of Delhi Pharmaceutical Sciences and Research University
        in Pushp Vihar, M.B road, New Delhi. It is a premier Institution of
        excellence in Physiotherapy education & training that develops
        professionals and leaders of high caliber imbued with values of
        entrepreneurship, ethics and social responsibility. The Institute is
        managed under the Delhi Pharmaceutical sciences and research university
        DPSRU, Govt of NCT New Delhi. The Institute emphasizes to strengthen
        fundamental issues of applied physiotherapy by consultancy and external
        research collaboration. In order to meet with the emerging challenges of
        medical education in our seamless economical spurt, DPSRU took a
        strategic decision of establishing School of Physiotherapy in the year
        2015. Since then school of Physiotherapy is promoting education in
        Physiotherapy through its education programs and services. School of
        Physiotherapy and its OPD with state of art Physiotherapy laboratories
        (Anatomy, Exercise therapy, Electrotherapy, Biomechanics, Neurology with
        demonstration and gait and balance training facilities) was established
        in the year 2015. Enriched with proficient and dedicated faculty
        members, School of Physiotherapy is an ideal combination of professional
        and academic excellence. School has adopted innovative methods of
        teaching and learning with aim to achieve excellence by integrating,
        teaching, learning and research. The school has a well-organized,
        innovative and job-oriented course curriculum with innovative delivery
        modes of teaching emphasizing on evidence- based practices. Presently,
        the School is running three specialization programs of PG i.e. MPT-
        Sports Medicine, MPT- Cardiovascular and Pulmonary and MPT- Pediatrics
        and aims to commence more specializations to provide the globe with best
        of the therapist from DPSRU
      </p>
      <br />
      <h2 className="dpsru-secondary-heading-for-every-page">Academics</h2>
      <p className="dipsar-paragrauph">
        The under graduate program in physiotherapy is four and half year course
        with a compulsory six months rotatory internship and the PG is for two
        years.
      </p>
      <br />
      <h2 className="dpsru-secondary-heading-for-every-page">
        Facilities
      </h2>{" "}
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Hostel – </b>The hostel is a comfortable and convenient place for
        student to stay in serene, well furnished & clean environment with
        separate provisions for girls and boys.
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Physiotherapy OPD –</b> Students are allowed to practice and learn in
        well equipped Outpatient department of Physiotherapy situated in campus
        of DPSRU under supervision of experienced faculty members. Pediatric
        Physiotherapy unit has been established in physiotherapy OPD along with
        advanced equipments to improve motor as well as sensory skills of the
        differently abled or special children. The unit aims to treat the
        children with congenital and acquired disorders.
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Labs- –</b> School of physiotherapy also provides well equipped labs
        like Anatomylab , physiology lab , exercise therapy lab and
        electrotherapy lab, sports lab, neurology lab, and biomechanics lab for
        learning and gaining knowledge. Recently, a new cardio-pulmonary
        physiotherapy and simulation laboratory has been established in the
        school. It houses fully equipped with diagnostic equipments such as
        blood pressure cuffs and sphygmomanometers & supportive equipments on
        headwalls like oxygen and air outlets, Pulse Oxymeter, Suction
        apparatus, nebulizer & expensive Mannikin for CPR training for the
        purpose of teaching and research.
        <br /> <br />
        The School is equipped with sophisticated equipments for assessing
        sports performance to prevent and manage sports injuries with a recovery
        room which makes it exceptional and different from other institutes
        like.
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Auditorium</b> DPSRU state-of-the-art auditorium is equipped with the
        latest audiovisual equipment. The auditorium is used round the year for
        hosting intra and inter-college events like seminars, conference and
        debates and cultural activities. It is a most happening place where
        socio-technical interaction occurs between the students, faculty and
        experts from industry and academic.
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Infrastructure-</b> The institute has well ventilated and equipped
        classrooms with all modern facilities and audio visual aids like LCD
        Projectors, Mike System, Overhead Projectors, etc. that provide the best
        ambience for teaching and learning activities
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Library- </b>library provides comfortable environment for prolonged
        reading sessions. Library attendance is mandatory for students and
        planned in their academic schedule. To cater to the needs of the
        students a photocopy facility is also available.
      </p>
      <br />
      <p className="dipsar-paragrauph">
        {" "}
        <b>Students Mentorship–</b> In order to ensure a value based education
        and encourage active learning, the faculties of school of Physiotherapy
        provide comprehensive guidance to the physiotherapy students during the
        entire course. At times of stress, such as terminal exams, the faculty
        counsels the students and helps them to alleviate the fears and give
        their best to the course. These unique interactions provide a mutual
        bonding and respect which carries on the legacy of this institution.
      </p>
      <br /> <br />
      The teaching faculty members of School of Physiotherapy also encourage the
      students to work differently for innovation in Physiotherapy with their
      huge experience in the profession and their competent innovative ideas to
      participate in collaboration with other organizations in carrying out
      outreach programs including industrial visits and extension activities
      including health awareness programs for contributing to the holistic
      development of students and sustained community development.
      <br />
      <br />
      The School is very particular in providing hospital clinical training to
      acquaint the students to deal with different patient exposure and their
      handling to give them a quality and independent life.
      <h2 className="dpsru-secondary-heading-for-every-page">
        Service Activities
      </h2>
      <p className="dipsar-paragrauph">
        Physiotherapy services are provided to outdoor patients and differently
        abled children. The physiotherapy OPD is equipped with latest and
        sophisticated equipment. <br />
        <br />
        Physiotherapy OPD has got its new unit in the expanded area of the old
        Physiotherapy OPD. The new unit has been established for Pediatric
        Physiotherapy. The new equipment installed in the unit help in improving
        motor as well as sensory skills of the differently abled children. The
        unit aims to treat the children with congenital and acquired disorders.
        The unit welcomes the habilitation to rehabilitation of the infants,
        toddlers and children till 10- 12 years of age with all motor, sensory,
        attention disorders, autism etc. In the OPD various disorders have also
        been treated like Cerebral Palsy, Autism, ADHD, congenital deformities
        and many more by increasing in the quality of life of the children as
        informed by the guardians and still the unit focusing with the same goal
        to create awareness in parents and independence in such children.
      </p>
      <div className="program-container">
        <h2 className="dipsar-sub-heading">Programmes</h2>

        <h3 className="dpsru-secondary-heading-for-every-page">
          Undergraduate Programs
        </h3>
        <div className="undergradute-program-box">
          <p
            style={{
              fontWeight: "bolder",
              margin: "4px 0",
              textDecoration: "underline",
            }}
          >
            BPT :<a href={BPT}> Syllabus</a>
          </p>
          <ul>
            <li>Total No. of Seats: 50</li>
            <li>
              Duration of Course: 9 semesters (including 6 months of internship)
            </li>
          </ul>
          <h3 className="dpsru-secondary-heading-for-every-page">
            Post Graduate Programs
          </h3>
          <p
            style={{
              fontWeight: "bolder",
              margin: "4px 0",
              textDecoration: "underline",
            }}
          >
            MPT :{/* <a href={MPT}> Syllabus</a> */}
          </p>
          <ul>
            <li>
              {" "}
              Sports Medicine : <a href={MPT}> Syllabus </a>
            </li>
            <li>
              Cardiovascular and Pulmonary :{" "}
              <a href={MptcaridovascularpandpulSyllabus}> Syllabus </a>{" "}
            </li>
            <li>
              Pediatrics: <a href={mptpediatricsSyllabus24}> Syllabus </a>
            </li>
            <li>Total No. of Seats: 5 in each specialization</li>
            <li>Duration of Course: Four Semesters</li>
          </ul>
        </div>
      </div>
      {/* Faculty */}
      <h2 className="dipsar-sub-heading">Faculty</h2>
      {/* each faculty Box */}
      <NavLink
        to="/Prof-Dr-Richa-Hirendra-Rai"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrRicha} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Richa Hirendra Rai</h3>
            <h4 className="faculty-position">Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-sheetal-kalra"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrSheetalK} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Sheetal Kalra</h3>
            <h4 className="faculty-position">
              Associate Professor & Head (PG), school of physiotherapy
            </h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box  Shilpa Jain Profile Removed From website at 14Jan 2025 Due to order of Dr Sheetal Kalra*/}
      {/* <NavLink
        to="/dr-shilpa-jain"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={drshilpaJain} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Shilpa Jain</h3>
            <h4 className="faculty-position">
              Associate Professor| School of Physiotherapy{" "}
            </h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr /> */}
      {/* each faculty Box */}
      <NavLink
        to="/dr-parul-sharma"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrParulS} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Parul Sharma</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-sapna-dhiman"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrSapna} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Sapna Dhiman</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-neha-kumari"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={NehaK} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Neha Kumari</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-tabassum-sahar"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={TabassumS} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Tabassum Saher</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      {/* <NavLink
        to="/dr-varsha-chorsiya"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={VarshaC} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Varsha Chorsiya</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>
      </NavLink>{" "}
      <hr /> */}
      {/* each faculty Box */}
      <NavLink
        to="/dr-jitender-munjal"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Jitender} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Jitender Munjal</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-savita-tanaria"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrSavita} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Savita Tamaria</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/dr-ravinder-kumar"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={DrRavinder} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Ravinder Kumar</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      <h2 className="dpsru-secondary-heading-for-every-page">Medical Unit</h2>
      {/* each faculty Box */}
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={priyanakaSonam} alt="faculty-profile" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Priyanka Sonam</h3>
          <h4 className="faculty-position">Medical Officer</h4>
        </div>
      </div>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/ms-suhasini-D"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={susahiniD} alt="faculty-profile" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Ms. Suhasini D</h3>
            <h4 className="faculty-position">Nurse</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities & Events</h2>

        <ul>
          <li>
            <a href='https://drive.google.com/drive/folders/1Fm862HvTSlYRemzQIA0vxnM5dNyw_Fh8?usp=drive_link'>
            Selective photos of all events since Jan 25
            </a>
          </li>
          <li>
            <a href={OutReactActivityunderunntBharatAbhiyan}>
            Out Reach Activity under Unnat Bharat Abhiyan
            </a>
          </li>
          <li>
            <a href={ParticiaptionofMPTStudentsinKhoKhoworkdCup}>
            PARTICIAPTION OF MPT STUDENTS IN KHO KHO WORLD CUP
            </a>
          </li>
          <li>
            <a href={ReportonthisstrokeRehabilitationworkshopHeldon28feb25}>
            Report on the Stroke Rehabilitation Workshop Held on 28th February 2025
            </a>
          </li>
          <li>
            <a href={InternationalSeminaronIntegratedMedicine}>
            International Seminar on Integrated Medicine
            </a>
          </li>
          <li>
            <a href={ReportOnSeminamrANdWorkShoponStrengthandcondinsports}>
            Report on Seminar and Workshop on Strength and Conditioning in Sports
            </a>
          </li>
          <li>
            <a href={MPTPediatricsStudentsAttendedAFPsicon25}>
            MPT-Pediatrics students attended AFPSICON- 2025
            </a>
          </li>
          <li>
            <a href={Reporton73allindiapoliceathleticclusterchamp2024injln}>
            Report on 73rd All India Police Athletics cluster Championship 2024 in JLN stadium
            </a>
          </li>
          <li>
            <a href={Mptspeotsstudentspostedatsportsauthofindia}>
            MPT sports students posted at Sports Authority of India
            </a>
          </li>
          <li>
            <a href={act01}>
              2018-19 RECENT ADVANCEMENT AND LUMBO SACRAL MANUAL THERAPY
            </a>
          </li>
          <li>
            <a href={act02}>
              2019-20 CLINICAL APPLICATION OF PROPRIOCEPTIVE NEUROMUSCULAR
              FACILITATION
            </a>
          </li>
          <li>
            <a href={act03}>2019-20 ANNUAL SPORTS MEET</a>
          </li>
          <li>
            <a href={act04}>
              2019-20 SEMINAR ALL INDIA PHYSIOTHERAPY SEMINAR ON ANTIDOPPING
            </a>
          </li>
          <li>
            <a href={act05}>PHYSIOTHERAPY HEALTH CAMP REPORT</a>
          </li>
          <li>
            <a href={act06}>CAREER COUNSELLING WEBINAR REPORT</a>
          </li>
          <li>
            <a href={act07}>EYE CAMP</a>
          </li>
          <li>
            <a href={act08}>ACTIVITIES ORGANIZED BY SCHOOL OF PHYSIOTHERAPY</a>
          </li>
          <li>
            <a href={act09}>REPORT WORKSHOP ON DRYNEEDLING</a>
          </li>
          <li>
            <a href={act10}>WORKSHOP ON KINESIOTAPING</a>
          </li>
          <li>
            <a href={act11}>
              WORKSHOP ON SYSTEMIC MEDICINE: VISCERAL OSTEOPATHY
            </a>
          </li>
          <li>
            <a href={act12HealthcamofschoolOfPhysiotheraphyinAssocwsob}>
              Health camp of School of Physiotherapy in association with Special
              Olympics Bharat
            </a>
          </li>
          <li>
            <a href={HIVaidsWorkshop210923}>21-09-2023_ HIV AIDS workshop</a>
          </li>
          <li>
            <a href={reportOnWorkshoponBLS171023}>
              17-10-2023_Report on Workshop on BLS
            </a>
          </li>
          <li>
            <a href={dpsruatNitrdWorkshop161123}>
              16-11-2023_DPSRU AT NITRD WORKSHOP
            </a>
          </li>
          <li>
            <a href={AmateurContenderSeries}>Amateur contender series</a>
          </li>
          <li>
            <a href={inceptAiims}>Incpt AIIMS</a>
          </li>
          <li>
            <a href={webinarOnCarrer}>Webinar on career counselling</a>
          </li>
          <li>
            <a href={orientationProgramReportMpt2023}>
              ORIENTATION PROGRAM REPORT MPT 2023
            </a>
          </li>
          <li>
            <a href={WorkshopBLs}>Workshop bls</a>
          </li>
          <li>
            <a href={ReportOfWorkshoponPersonalityDeveloper}>
              REPORT OF WORKSHOP ON PERSONALITY DEVELOPMENT
            </a>
          </li>
          <li>
            <a href={wpd2022writeupFinal}>Wpd 2022 write up final</a>
          </li>
          <li>
            <a href={WorkshopOnPedagogyForMptStudents}>
              Workshop on Pedagogy for MPT students
            </a>
          </li>
        </ul>
      {/* </div>
      <div className="dipsar-activity-container results-data-link-container"> */}
        {/* <h2 className="dpsru-secondary-heading-for-every-page">Events</h2> */}

        <ul>
          <li>
            <a href={Drsheetalkalrainvitedforguestlacturebydept}>
            Dr Sheetal Kalra invited for Guest Lecture by Deptt of Geriatric medicine, AIIMS
            </a>
          </li>
          <li>
            <a href={WorkshoponSystematicreviewandMetanalysisforBPTandMPTStudents}>
            WORKSHOP ON SYSTEMATIC REVIEW AND METANALYSIS FOR BPT AND MPT STUDENTS
            </a>
          </li>
          <li>
            <a href={dpsruMptssatindiavsgemhocbilmatch}>
            DPSRU MPT SPORTS STUDENTS AT INDIA VS GERMANY HOCKEY BILATERAL MATCH
            </a>
          </li>
          <li>
            <a href={farewellofBPTandMPTstudents}>
            Farewell of BPT and MPT students
            </a>
          </li>
          <li>
            <a href={mptstudentmsDeepshikhawins3rdinpaperpreantaritahos}>
            MPT student Ms Deepshikha wins 3rd prize in paper presentation at Amrita hospital conference
            </a>
          </li>
          <li>
            <a href={worldPhysiotherapyday2}>
            World Physiotherapy Day Sports Carnival 2024
            </a>
          </li>
          <li>
            <a href={ishikahainwins2ndprizeinscintificpaperpresentaiton}>
            ISHIKA JAIN WINS 2ND PRIZE IN SCIENTIFIC PAPER PRESENTATION
            </a>
          </li>
          <li>
            <a href={psysiotheraphyHealthcampasaPartofVIksitBharatabhiyan}>
              Physiotherapy health camp as a part of Vikshit Bharat Abhiyan
            </a>
          </li>
          <li>
            <a
              href={partiipationofMptstudentmssakshiwithdelhistatefootballteam}
            >
              PARTICIPATION OF MPT STUDENT MS SAKSHI WITH DELHI STATE FOOTBALL
              TEAM
            </a>
          </li>
          <li>
            <a
              href={participationofMptstudentMrdaniyalinu20footballchapionship}
            >
              PARTICIPATION OF MPT STUDENT MR DANIYAL IN U-20 FOOTBALL
              CHAMPIONSHIP
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SchoolofPhysiotherapy;
