import React from "react";
import "./Dipsar.css";
import { NavLink } from "react-router-dom";

// import faculty images
import dPharma from "./DipsarFiles/D-Pharma-Syllabus.pdf";
import bPharma from "./DipsarFiles/Syllabus_B_Pharm.pdf";
import mPharma from "./DipsarFiles/Syllabus_M_Pharm.pdf";

import pkSahoo from "./Dipsar Faculty/Prof PK Sahoo/Prof.-P.K-Sahoo-scaled-e1686903307416.jpg";
import deeptiPandita from "./Dipsar Faculty/Dr. Deepti Pandita/IMG_2071-scaled-e1689059370335.jpg";
import Meenakshi from "./Dipsar Faculty/Dr Meenakshi K Chauhan/Dr.-MKC-Photo--150x150.jpg";
import Gaurav from "./Dipsar Faculty/Dr. Gaurav Kumar Jain/IMG_0024-scaled-e1686635295411.jpg";
import Dalpati from "./Dipsar Faculty/Dr. Dalapathi Gugulothu/WhatsApp-Image-2023-07-19-at-10.19.30-AM-e1689743369529.jpeg";
import Pooja from "./Dipsar Faculty/Dr. Pooja Mongia Raj/IMG_0031-scaled-e1686638011713.jpg";
import Anshul from "./Dipsar Faculty/Dr. Anshul/IMG_0635-scaled-e1688466726177.jpg";
import Bhagchand from "./DipsarFiles/Bhagchand.png";
import Beauty from "./Dipsar Faculty/Dr. Beauty Behera/IMG_0035-scaled-e1686638323855.jpg";
// import Lalit from "./DipsarFiles/lalit.jpg";
import Atul from "./Dipsar Faculty/Dr. Atul Jain/IMG_0028-scaled-e1686638404122.jpg";

// Department of Pharmacology/ Clinical Research
import Rajni from "./Dipsar Faculty/Dr. Rajani Mathur/IMG_0629-scaled-e1688467057313.jpg";
import Latha from "./Dipsar Faculty/Dr. S. Latha/IMG_0615-scaled-e1688470066790.jpg";
import Sachin from "./Dipsar Faculty/Dr. Sachin Assistant Professor/IMG_0614-scaled-e1688471005508.jpg";
import Anoop from "./Dipsar Faculty/Dr. Anoop Kumar/IMG_0613-scaled-e1688470088509.jpg";
import DrGunjan from "./Dipsar Faculty/Dr. Gunjan Sharma/IMG_0627-scaled-e1688470177320.jpg";


//Department of Pharmaceutical Chemistry/ Quality Assurance
import Pathak from "./Dipsar Faculty/Dr. Dharam Pal Pathak/IMG_2043-scaled-e1688797346308.jpg";
import Sharad from "./Dipsar Faculty/Dr. Sharad Wakode/IMG_2040-scaled-e1688796530426.jpg";
import Saurabh from "./Dipsar Faculty/Dr. Saurabh Dahiya/sir-1-e1688122317839.jpg";
import AmritaParle from './Dipsar Faculty/Dr. Amrita Parle/IMG_2044-scaled-e1688797474139.jpg'
import Neetika from "./Dipsar Faculty/Dr. Neetika Sharma/IMG_0620-scaled-e1688470149347.jpg";
import Sahil from "./Dipsar Faculty/Dr. Sahil Kumar/Dr.-Sahil-Kumar-Dr.-Sahil-Kumar-150x150.jpg";
import archanaSharma from "./Dipsar Faculty/Dr. Archana Sharma/IMG_1827-scaled-e1688796178590.jpg";
import Ruhi from "./Dipsar Faculty/Dr. Ruhi Ali/IMG_0618-scaled-e1688470129183.jpg";
// import Amrita from "./DipsarFiles/Amrita.jpg";
import Bodla from "./DipsarFiles/Bodla.jpg";
import Himangini from "./Dipsar Faculty/Dr. Himangini/IMG_0617-scaled-e1688470111586.jpg";

// Department of Pharmacognosy and Phytochemistry
import ManjuV from "./Dipsar Faculty/Dr. Manju Vyas Singh/IMG_6141-scaled-e1684304999121.jpg";
import SakshiB from "./Dipsar Faculty/Dr. Sakshi Bajaj/IMG_6144-scaled-e1684304814941.jpg";

// Activities
import oldActivity01 from "./DipsarFiles/DIPSAR-–-The-Best-Pharmacy-College-in-India-2017.pdf";
import oldActivity02 from "./DipsarFiles/National-Pharmacy-Week-Celebrations-DIPSAR-at-Live-show-at-DD-National.pdf";
import oldActivity03 from "./DipsarFiles/DIPSAR-at-Edinburgh-Fringe-Festival.pdf";
import oldActivity04 from "./DipsarFiles/DIPSAR-AIR-1-GPAT-2017.pdf";
import oldActivity05 from "./DipsarFiles/PATENTS.pdf";
import oldActivity06 from "./DipsarFiles/61st-National-Pharmacy-Week-–-2022.pdf";
import oldActivity07 from "./DipsarFiles/Unity-Day-Oct-2022.docx.pdf";
import oldActivity08 from "./DipsarFiles/Expert-Talk-Indirect-Evidence-Synthesis-Using-Network-Approach-held-on-13.03.2023.pdf";
import oldActivity09 from "./DipsarFiles/5th-JanAushadhi-Diwas-2023-on-the-6th-of-March-2023.pdf";

//Academic-calendar
import AcademicCalendar from "./DipsarFiles/Academic-calendar-.pdf";
import DecisionLetter from "./DipsarFiles/Decision-Letter-2023-24-2.pdf";

function Dipsar() {
  return (
    <div className="dpsru-every-component-css">
      <div className="dipar-upper-navigation-menu-container">
        <NavLink
          to="/dipsar-time-table"
          className="dipsar-upper-navigation-navlink"
        >
          Time table
        </NavLink>
        <NavLink
          to="/dipsar-sessional-Examination"
          className="dipsar-upper-navigation-navlink"
        >
          Sessional Examination
        </NavLink>

        {/* <a href={AcademicCalendar} className="dipsar-upper-navigation-navlink">
          Academic calendar
        </a> */}
        <a href={DecisionLetter} className="dipsar-upper-navigation-navlink">
          Approval
        </a>
        <NavLink
          to="/dipsar-notice"
          className="dipsar-upper-navigation-navlink"
        >
          Notices
        </NavLink>
      </div>
      <h2 className="dpsru-primary-heading-for-every-page">
        Delhi Institute of Pharmaceutical Sciences And Research
      </h2>
      <h2 className="dipsar-sub-heading">Introduction</h2>
      <p className="dipsar-paragrauph">
        The Delhi Institute of Pharmaceutical Sciences and Research (DIPSAR),
        originally established as Department of pharmacy at Kashmiri Gate
        Polytechnic in 1964 is one of the departments of Directorate of Training
        and Technical Education. In 1972, the department was later on shifted to
        Pusa Institute and then in 1979, to present lush green of 10.5 acres
        campus at Pushp Vihar, New Delhi. From College of Pharmacy it was
        upgraded and now emerged as Delhi Institute of Pharmaceutical Sciences
        and Research (DIPSAR) vide gazette notification of July 2004, under
        Department of Training and Technical Education, GNCT of Delhi affiliated
        to University of Delhi.
        <br />
        <br />
        DIPSAR has become a constituent college under Delhi Pharmaceutical
        Sciences and Research University, as per the government legislation
        enacted on 11 September 2008.
      </p>
      <div className="program-container">
        <h2 className="dipsar-sub-heading">Programmes</h2>

        <h3 className="dpsru-secondary-heading-for-every-page">
          Undergraduate Programs
        </h3>
        <div className="undergradute-program-box">
          <p className="undergradute-program-box-main-heading-text">
            {" "}
            D Pharm (Diploma in Pharmacy) :<a href={dPharma}> Syllabus</a>
          </p>
          <ul>
            <li>Total No. of Seats: 120</li>
            <li>Duration of Course: 2 years + 500 hours internship</li>
          </ul>
          <p className="undergradute-program-box-main-heading-text">
            B Pharm (Bachelor of Pharmacy) :<a href={bPharma}> Syllabus</a>
          </p>
          <ul>
            <li>Total No. of Seats: 100</li>
            <li>Duration of Course: 8 Semesters</li>
          </ul>
          <p className="undergradute-program-box-main-heading-text">
            B. Pharm – LE (Bachelor of Pharmacy – Lateral Entry) :
            <a href={bPharma}> Syllabus</a>
          </p>
          <ul>
            <li>Total No. of Seats: 10</li>
            <li>Duration of Course: 6 Semesters</li>
          </ul>
        </div>

        <h3 className="dpsru-secondary-heading-for-every-page">
          Post Graduate Programs
        </h3>
        <div className="post-graduation-box">
          <ul>
            <li>
              M Pharm (Pharmacology)
              <ol>
                <li>Total No. of Seats: 8</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              M Pharm (Pharmaceutical Chemistry)
              <ol>
                <li>Total No. of Seats: 8</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              {" "}
              M Pharm (Quality Assurance)
              <ol>
                <li>Total No. of Seats: 12</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              M Pharm (Clinical Research)
              <ol>
                <li>Total No. of Seats: 8</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              M Pharm (Pharmaceutics)
              <ol>
                <li>Total No. of Seats: 8</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              M Pharm (Hospital and Clinical Pharmacy)
              <ol>
                <li>Total No. of Seats: 5</li>
                <li>Duration of Course: 4 Semesters</li>
              </ol>
            </li>
            <li>
              <a href={mPharma}>Consolidated Syllabus of M. Pharm.</a>
            </li>
          </ul>
        </div>
      </div>
      {/* Faculty details */}
      <div className="faculty-details-box">
        <h2 className="dipsar-sub-heading">Faculty Details</h2>
        <h2 className="dpsru-secondary-heading-for-every-page">
          Department of Pharmaceutics/ Hospital and Clinical Pharmacy
        </h2>
      </div>
      {/*  faculty box */}
      {/* each faculty Box */}
      <NavLink
        to="/Prof-pk-sahoo"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={pkSahoo} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Prof. PK Sahoo</h3>
            <h4 className="faculty-position">
            Director, DIPSAR 
            </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Deepti-Pandita"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={deeptiPandita} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Deepti Pandita</h3>
            <h4 className="faculty-position">Professor</h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Meenakshi-K-Chauhan"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Meenakshi} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Meenakshi K.Chauhan</h3>
            <h4 className="faculty-position"> Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Gaurav-Kumar-Jain"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Gaurav} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Gaurav Kumar Jain</h3>
            <h4 className="faculty-position">Associate Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Dalapathi-Gugulothu"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Dalpati} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Dalapathi Gugulothu</h3>
            <h4 className="faculty-position">Assistant Professor  </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Pooja-Mongia-Raj"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Pooja} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Pooja Mongia Raj</h3>
            <h4 className="faculty-position">Assistant Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink to="/Dr-Anshul" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Anshul} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Anshul</h3>
            <h4 className="faculty-position">Assistant Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Bhagchand} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Bhagchand</h3>
          <h4 className="faculty-position">Senior Lecturer </h4>
        </div>
      </div>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Beauty-Behera"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Beauty} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Beauty Behera</h3>
            <h4 className="faculty-position">Assistant Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      {/* <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Lalit} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Lalit Kumar</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      <hr /> */}
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Atul-jain"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={Atul} alt="faculty img" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Atul Jain</h3>
            <h4 className="faculty-position">Assistant Professor </h4>
          </div>
        </div>
      </NavLink>
      <hr />
      {/* Department of Pharmacology/ Clinical Research */}
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmacology/ Clinical Research
      </h2>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Rajni-mathur"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Rajni} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Rajani Mathur</h3>
          <h4 className="faculty-position">Associate Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-s-laha"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Latha} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. S. Latha</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Sachin-Assistant-Professor"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Sachin} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sachin</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Anoop-kumar-Assistant-Professor"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Anoop} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Anoop Kumar</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />

      {/* each faculty Box */}
      <NavLink
      to="/Dr-Gunjan-sharma-Assistant-Professor"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={DrGunjan} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">
Dr. Gunjan Sharma</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* Department of Pharmaceutical Chemistry/ Quality Assurance */}
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmaceutical Chemistry/ Quality Assurance
      </h2>{" "}
      <hr />
      {/* each faculty Box */}
      {/* <NavLink
      to="/Dr-Dharam-Pal-Pathak"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Pathak} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Dharam Pal Pathak</h3>
          <h4 className="faculty-position">Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr /> */}
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Sharad-Wakode"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Sharad} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sharad Wakode</h3>
          <h4 className="faculty-position">Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
      to="/Dr-saurabh-Dahiya"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Saurabh} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Saurabh Dahiya</h3>
          <h4 className="faculty-position">Associate Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Amrita-parle"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={AmritaParle} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">
Dr. Amrita Parle</h3>
          <h4 className="faculty-position">Assistant Professor  </h4>
        </div>
      </div>
      </NavLink>
      <hr />
            {/* each faculty Box */}
            <NavLink
      to="/Dr-Ramesh-bodla"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Bodla} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Ramesh Bodla</h3>
          <h4 className="faculty-position">Associate Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
           {/* each faculty Box */}
           <NavLink
      to="/Dr-Sahil-Kumar"
      className="each-faculty-box-navlink-container"
    >
           <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Sahil} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sahil kumar</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />

           {/* each faculty Box */}
           <NavLink
      to="/Dr-Archana-sharma"
      className="each-faculty-box-navlink-container"
    >
           <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={archanaSharma} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">
Dr. Archana Sharma</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
    
 
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Ruhi-ali"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Ruhi} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Ruhi Ali</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />

      {/* each faculty Box */}
      <NavLink
      to="/Dr-Himangini"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Himangini} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Himangini</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
        {/* each faculty Box */}
        <NavLink
      to="/Dr-Neetika-sharma"
      className="each-faculty-box-navlink-container"
    >
        <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={Neetika} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Neetika Sharma</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* Department of Pharmacognosy and Phytochemistry */}
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmacognosy and Phytochemistry
      </h2>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Manju-Vyas-Singh"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={ManjuV} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Manju Vyas Singh</h3>
          <h4 className="faculty-position">Associate Professor </h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
      to="/Dr-Sakshi-Bajaj"
      className="each-faculty-box-navlink-container"
    >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={SakshiB} alt="faculty img" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sakshi Bajaj</h3>
          <h4 className="faculty-position">Assistant Professor </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities</h2>

        <ul>
          <li>
            <a href={oldActivity01}>
              DIPSAR – The Best Pharmacy College in India 2017
            </a>
          </li>
          <li>
            <a href={oldActivity02}>
              National Pharmacy Week Celebrations DIPSAR at Live show at DD
              National
            </a>
          </li>
          <li>
            <a href={oldActivity03}>DIPSAR at Edinburgh Fringe Festival</a>
          </li>
          <li>
            <a href={oldActivity04}>DIPSAR-AIR-1 (GPAT 2017)</a>
          </li>
          <li>
            <a href={oldActivity05}>Patents</a>
          </li>
          <li>
            <a href={oldActivity06}>61st National Pharmacy Week – 2022</a>
          </li>
          <li>
            <a href={oldActivity07}>National Unity Day Oct 2022</a>
          </li>
          <li>
            <a href={oldActivity08}>
              Expert Talk “Indirect Evidence Synthesis Using Network Approach”
              held on 13.03.2023
            </a>
          </li>
          <li>
            <a href={oldActivity09}>
              5th JanAushadhi Diwas 2023 on the 6th of March 2023
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Dipsar;
