import React from "react";
import "./OfficeOrder.css";

import maintainanceofhteattandance from "../Office Order/Maintence of Attendance of the faculty and non-teaching Staff.jpeg";
import committeeforthephysicalverification from "../Office Order/Commitees for conducting physical verification.pdf";
import officeOrderRegardingTenderProcess from "../Office Order/Office Order regarding initiation of Tender Process .pdf";
import FormForMedicalReimubersmentofDPSRU from "../Office Order/Form for Medical reimbursement DPSRU.pdf";
import RTIOfficeOrdeer from "../Office Order/RTI cell Notice.pdf";
import officeOrderRegardingOfficeduringfy from "../Office Order/Office order for working days during FY Closing.jpeg";
import OfficeOrderForWeeklyReview from '../Office Order/Office Order for Weekly Review.pdf'
import transferorderFirst from '../Office Order/Transfer Order 16-01-24.pdf'

const OfficeOrder = () => {
  return (
    <div className="dpsru-every-component-css">
      <h3 className="dpsru-primary-heading-for-every-page">Office Order</h3>

      <div className="office-order-container">
        <ul>
          <li>
            <a href={OfficeOrderForWeeklyReview}>
            Office Order for Weekly Review
            </a>
          </li>
          <li>
            <a href={officeOrderRegardingOfficeduringfy}>
              Office order for working days during FY Closing
            </a>
          </li>
          <li>
            <a href={maintainanceofhteattandance}>
              Maintence of Attendance of the faculty and non-teaching Staff
            </a>
          </li>
          <li>
            <a href={committeeforthephysicalverification}>
              Commitees for conducting physical verification
            </a>
          </li>
          <li>
            <a href={officeOrderRegardingTenderProcess}>
              Office Order regarding initiation of Tender Process
            </a>
          </li>
          <li>
            <a href={FormForMedicalReimubersmentofDPSRU}>
              Form for Medical reimbursement DPSRU
            </a>
          </li>
          <li>
            <a href={RTIOfficeOrdeer}>RTI cell Notice</a>
          </li>
          <li>
            <a href={transferorderFirst}>Transfer Order</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OfficeOrder;
