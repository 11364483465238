import React from "react";
import "./Events.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import G20CycleRally from "./Events Data/G20-CYCLE-RALLY-EVENT.pdf";
import panelDiscussionToInvestInPeople from "./Events Data/Panel-Discussion-new-.pdf";
import G20IndoBrazilFusionFest from "./Events Data/G20 Indo Brazil Fusion Fest.pdf";
import REPORTonPUSHPPRADARSHNAM2023 from "./Events Data/REPORT on PUSHP PRADARSHNAM 2023.pdf";
import G20MahafoodfestivalofChinaFranceGermanyatDPSRU from "./Events Data/G20 Maha food festival of China, France & Germany at DPSRU.pdf";
import G20QuizCompetitionatDPSRU from "./Events Data/G20 Quiz Competition at DPSRU.pdf";
import Conference2020 from "./Events Data/Conference 2020.pdf";
import Conference2022 from "./Events Data/Conference 2022.pdf";
import WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023 from "./Events Data/Workshop on Adjunct Mechanism in On Field Injury Rehabilitation Kinesiotaping On 13th Feb 2023.pdf";
import NationalScienceDay28thFeb2023 from "./Events Data/National Science Day 28th Feb 2023.pdf";
import UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage from "./Events Data/Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy children of Anganwadi School, Deoli village.pdf";
import Vicechancellorfelicitetedataiims from "./Events Data/Honble Vice Chancellor Prof. V. Ravichandiran felicitated at AIIMS.pdf";
import internationConf24 from "./Events Data/Where innovation meets reflection.pdf";
import bbsReport from "./Events Data/BBS REPORT 1.pdf";
import FitIndiaReport from "./Events Data/Fit India Report.pdf";
import RepublicDay2025 from "./Event Pages/Republic Day 2025/26-01-2025_Republic Day.pdf";
import studentSectionInnogration2025 from "./Events Data/31-01-25_inauguration Student Section.pdf";
import InternationhansonWorkshoponsystematicreviewandMetaanalysis from "./Events Data/International Hands-on Workshop on Systematic Reviews and Meta Analysis by ISPOR- INDIA Chapter at DPSRU.pdf";
import ScientificSessionstDPSRU from "./Events Data/Scientific sessions at DPSRU.pdf";
import pannelDisussiononRoleofEvidencegenrationInproductlm from "./Events Data/Panel Discussion on Role of EvidenceGeneration in Product Lifecycle Management.pdf";
import SmartDelhiIdeation2025 from "./Events Data/Smart Delhi Ideathon 2025.pdf";
import AssessmentOfQualityofBIoTherapeutics from "./Events Data/Assessment of Quality of Bio-therapeutics.pdf";
import PrarikshaPeCharchaEventDpsru from "./Events Data/Pariksha Pe Charcha - A Motivational Event on 10th Feb. 2025 at DPSRU.pdf";
import SkillDevworkshoponBasicLifesupportandEmergenceynadcare from "./Events Data/DPSRU organises Skill development workshop on Basic Life Support and Emergency Care on 14th Feb 2025.pdf";
import HandsOnTraininginGenerativeAItransformingcontentinfoVideospptvisualsandMOre from "./Events Data/Hands-on Training in Generative AI – Transforming Content into Videos, PPTs, Visuals & More.pdf";
import TrainingonTheGodClinicalPracticeGCP from "./Events Data/Training on the Good Clinical Practice (GCP).pdf";
import ExperientalLearningatSkillLabSopDpsru from "./Events Data/20-02-2025_Experiental learning at skill Lab SoP DPSRU.pdf";
import HealthcamppwithNRF from "./Events Data/16-2-2025_Health Camp with NRF.pdf";
import aswagandhaFestAcelebrationbofWellnewssVitality from "./Events Data/ASHWAFEST - A celebration of wellness and vitality_21-02-2025.pdf";
import DpsruAtAppolloTyresDelhiMerathon2025atjnusta23Feb25 from "./Events Data/DPSRU at Apollo Tyres Delhi Marathon 2025 at Jawaharlal Nehru Stadium on 23rd Feb 2025.pdf";
import InternationalSeminaronIntegratedMedicine from "../Academics/School/SchoolofPhysiotherapy/SopFiles/International Seminar on Integrated Medicine.pdf";
import AnnualConvo2025ofDPSRU7th from "./Events Data/7th Annual Convocation 2025 of DPSRU.pdf";
import ReportonthisstrokeRehabilitationworkshopHeldon28feb25 from "../Academics/School/SchoolofPhysiotherapy/SopFiles/Report on the Stroke Rehabilitation Workshop Held on 28th February 2025.pdf";
import PreclinicalGLPExperimentalApprochesinAdvanceddra25 from "./Events Data/Preclinical GLP Experimental Approaches in Advanced Drug Discovery Systems.pdf";
import InternationWorkshoponMindtraningforatheles from "./Events Data/International workshop on Mind training for athletes.pdf";
import internationWomensDay2025 from "./Events Data/Brief Report - International Womens Day 2025.pdf";
import internationWomenDay2025Video from "./Events Data/International Womens Day 2025.mp4";
import ReportonExperttalkanVitroprasiteculturetoolkitforANtiMalariad from "./Events Data/Report on Expert Talk “An In Vitro Parasite Culture Toolkit for Anti-Malarial Drug.pdf";
import BrainstrommingSession2025 from "./Events Data/Brainstorming Session.pdf";
import reportOnJanAushdiSeminar6mar from './Events Data/Report on Jan Aushadhi Diwas Seminar.pdf'
import ReportonIMaPharmacistSeminar from './Events Data/Report on ‘I AM A PHARMACIST’ Seminar.pdf'
import eventReportonMedicineCostEffective from './Events Data/Making Medicines Cost Effective with CMOS.pdf'
import InternationalTalkonUnderstandfrailtyansAssessingfrailtyingeipopulation from './Events Data/International Talk on Understanding Frailty and Assessing Frailty in Geriatric Population.pdf'
import ExpertTalkUnderstandingMuscularDystrophyfromcoretoCurse from './Events Data/Expert Talk- Understanding Muscular Dystrophy From Core to Crust.pdf'
import MahadanSchoolofPhysiotherapyandRotaractclubofDPSRU from './Events Data/Mahadan School of Physiotherapy and Rotaract Club of DPSRU.pdf'
import dpsruAdaptedVillagesEducationDrive from './Events Data/DPSRU adapted villages Education Drive.pdf'

const Events = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">EVENTS</h2>

      <div className="nirf-data-container">
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="confrence"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink library-navlink"
          >
            Conferences
          </Link>
        </section>

        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="workshop"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink library-navlink"
          >
            Workshop
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each ">
          <Link
            to="g20event"
            smooth={true}
            duration={500}
            className="nirf-botton library-navlink"
          >
            G20 Events
          </Link>
        </section>
      </div>

      <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Event</h2>

        <ul className="evnet-page-ul-container">


        <li className="evnet-page-list">
            <a
              href={dpsruAdaptedVillagesEducationDrive}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
      DPSRU adapted villages Education Drive
            </a>
          </li>{" "}


        <li className="evnet-page-list">
            <a
              href={MahadanSchoolofPhysiotherapyandRotaractclubofDPSRU}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
        Mahadan School of Physiotherapy and Rotaract Club of DPSRU
            </a>
          </li>{" "}


        <li className="evnet-page-list">
            <a
              href={ExpertTalkUnderstandingMuscularDystrophyfromcoretoCurse}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
           Expert Talk- Understanding Muscular Dystrophy From Core to Crust
            </a>
          
          
          </li>{" "}

        <li className="evnet-page-list">
            <a
              href={InternationalTalkonUnderstandfrailtyansAssessingfrailtyingeipopulation}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
             International Talk on Understanding Frailty and Assessing Frailty in Geriatric Population
            </a>
            ||{" "}
            <a
              href="https://1drv.ms/f/c/aa335ed078854a9d/ElIVe-ZCkG9KrZqWzhYCPOkB4C1Y7fI7lLs0C4kwrU_elA"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>
          
          </li>{" "}





        <li className="evnet-page-list">
            <a
              href={eventReportonMedicineCostEffective}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
Making Medicines Cost Effective with CMO'S
            </a>
          </li>{" "}
        <li className="evnet-page-list">
            <a
              href={ReportonIMaPharmacistSeminar}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
      Report on ‘I AM A PHARMACIST’ Seminar
            </a>
          </li>{" "}
        <li className="evnet-page-list">
            <a
              href={reportOnJanAushdiSeminar6mar}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
          Report on Jan Aushadhi Diwas Seminar
            </a>
          </li>{" "}

        <li className="evnet-page-list">
            <a
              href={BrainstrommingSession2025}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
            Brainstorming Session
            </a>
          </li>{" "}
        <li className="evnet-page-list">
            <a
              href={ReportonExperttalkanVitroprasiteculturetoolkitforANtiMalariad}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
             Report on Expert Talk: “An In Vitro Parasite Culture Toolkit for Anti-Malarial Drug"
            </a>
          </li>{" "}

          <li className="evnet-page-list">
            <a
              href={internationWomensDay2025}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              International Women's Day 2025
            </a>
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/1-LvBFJ2dYtLwz0eA958yAKvrAK6rKw4l"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>{" "}
            ||{" "}
            <a
              href={internationWomenDay2025Video}
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Video
            </a>{" "}
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={InternationWorkshoponMindtraningforatheles}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              International workshop on Mind training for athletes
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={AnnualConvo2025ofDPSRU7th}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              7th Annual Convocation 2025 of DPSRU
            </a>
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/1SPQdNvQJaZAKeViQJ6ZasIGQZzBiouNO?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>{" "}
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={PreclinicalGLPExperimentalApprochesinAdvanceddra25}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Preclinical GLP Experimental Approaches in Advanced Drug Discovery
              Systems
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={ReportonthisstrokeRehabilitationworkshopHeldon28feb25}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Report on the Stroke Rehabilitation Workshop Held on 28th February
              2025
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={InternationalSeminaronIntegratedMedicine}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              International Seminar on Integrated Medicine
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={DpsruAtAppolloTyresDelhiMerathon2025atjnusta23Feb25}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              DPSRU at Apollo Tyres Delhi Marathon 2025 at Jawaharlal Nehru
              Stadium on 23rd Feb 2025
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={aswagandhaFestAcelebrationbofWellnewssVitality}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              ASHWAFEST - A celebration of wellness and vitality_21-02-2025
            </a>
          </li>{" "}
         
          <li className="evnet-page-list">
            <a
              href={HealthcamppwithNRF}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              16-2-2025_Health Camp with NRF
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={TrainingonTheGodClinicalPracticeGCP}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Training on the Good Clinical Practice (GCP)
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={
                HandsOnTraininginGenerativeAItransformingcontentinfoVideospptvisualsandMOre
              }
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Hands-on Training in Generative AI – Transforming Content into
              Videos, PPTs, Visuals & More!
            </a>
          </li>{" "}
          
          <li className="evnet-page-list">
            <a
              href={PrarikshaPeCharchaEventDpsru}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Pariksha Pe Charcha 2025: A Motivational Event on 10th Feb. 2025
              at DPSRU
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={AssessmentOfQualityofBIoTherapeutics}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Assessment of Quality of Bio-therapeutics
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={SmartDelhiIdeation2025}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Smart Delhi Ideathon 2025
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={pannelDisussiononRoleofEvidencegenrationInproductlm}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Panel Discussion on Role of Evidence Generation in Product
              Lifecycle Management
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={ScientificSessionstDPSRU}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Scientific sessions at DPSRU
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={InternationhansonWorkshoponsystematicreviewandMetaanalysis}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              International Hands-on Workshop on Systematic Reviews and Meta
              Analysis by ISPOR- INDIA Chapter at DPSRU
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={studentSectionInnogration2025}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              INAUGURATION OF THE STUDENTS’ SECTION AT DPSRU 31ST JANUARY 2025
            </a>{" "}
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/1bbNe7UdjKIr4B2SEb8AwcfnWotgCvdHm?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={RepublicDay2025}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Republic Day celebration at DPSRU on the 26th of January 2025
              Swarnim Bharat: Virasat aur Vikas
            </a>{" "}
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/1mg2K5lu7rE-2AMqnd0cy1VpgbUjfKNrb?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <NavLink
              to="/international-Conference-2024"
              className="event-a-click-here"
              style={{ color: "black", fontWeight: "bold" }}
            >
              International Conference 2024
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href="https://www.sdi2025.in"
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              Smart Delhi Ideathon 2025
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={FitIndiaReport} className="event-files-link">
              6th Edition of Fit India Week Scheduled to be Celebrated from 15th
              November to 31st December 2024
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <NavLink
              to="/clinical-Pharmacology-and-Translational-Research"
              className="event-a-click-here"
              style={{ color: "black", fontWeight: "bold" }}
            >
              Clinical Pharmacology and Translational Research
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={bbsReport} className="event-files-link">
              DPSRU Proudly Participated in Business Blaster Schemes (Seniors)
              launch event at IIIT -Delhi
            </a>{" "}
            <NavLink to="/business-blaster-2024" className="event-a-click-here">
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={Vicechancellorfelicitetedataiims}
              className="event-files-link"
            >
              Hon'ble Vice Chancellor Prof. V. Ravichandiran felicitated at
              AIIMS, New Delhi by the Director AIIMS, Prof. M. Srinivas.
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={NationalScienceDay28thFeb2023}
              className="event-files-link"
            >
              National Science Day 28th Feb 2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={
                UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage
              }
              className="event-files-link"
            >
              Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy
              children of Anganwadi School, Deoli village
            </a>{" "}
            <NavLink to="/unnatBharatAbhiyan" className="event-a-click-here">
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/Swachhta-Hi-seva" className="event-files-link">
              Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on
              01.10.2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/world-heart-day-29-sept" className="event-files-link">
              IACS India Section and DPSRU Celebrated World Heart Day
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/chintan-shivir" className="event-files-link">
              CHINTAN SHIVIR
            </a>
          </li>
          <li className="evnet-page-list">
            <a href="/pushpPradarshnam" className="event-files-link">
              PUSHP PRADARSHNAM 2023
            </a>
          </li>
        </ul>
      </div>

      {/* Conferences */}

      <div className="event-data-container" id="confrence">
        <h2 className="dpsru-secondary-heading-for-every-page">Conferences</h2>
        <ul className="evnet-page-ul-container">
          <li className="evnet-page-list">
            <a href={Conference2020} className="event-files-link">
              Conference 2020
            </a>{" "}
          </li>{" "}
          <li className="evnet-page-list">
            <a href={Conference2022} className="event-files-link">
              Conference 2022
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a href="/conference" className="event-files-link">
              More
            </a>
          </li>{" "}
        </ul>
      </div>

      {/* Workshops */}
      <div className="event-data-container" id="workshop">
        <h2 className="dpsru-secondary-heading-for-every-page">Workshop</h2>
        <ul className="evnet-page-ul-container">
        <li className="evnet-page-list">
            <a
              href={ExperientalLearningatSkillLabSopDpsru}   className="event-files-link"
            >
              20-02-2025_Experiental learning at skill Lab SoP DPSRU
            </a>
          </li>{" "}


        <li className="evnet-page-list">
            <a
              href={SkillDevworkshoponBasicLifesupportandEmergenceynadcare}
              target="_blank"
              rel="noreferrer"
              className="event-files-link"
            >
              DPSRU organises Skill development workshop on Basic Life Support
              and Emergency Care on 14th Feb 2025
            </a>{" "}
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/1Hk79h_naEySn-jrbGydtEXTJh51xwGEL?usp=drive_link"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Photo Gallery
            </a>{" "}
            ||{" "}
            <a
              href="https://drive.google.com/drive/folders/13Jksmels5txbRMZv6QME7uK6TB5cdRKf?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className="event-files-link-photo-and-video-gallery"
            >
              Video Gallery
            </a>
          </li>{" "}


       

          <li className="evnet-page-list">
            <a
              href={
                WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023
              }
              className="event-files-link"
            >
              Adjunct Mechanism in On Field Injury Rehabilitation: Kinesiotaping
            </a>{" "}
            <NavLink to="/workshop" className="event-a-click-here">
              Click here
            </NavLink>
          </li>
        </ul>
      </div>

      {/* G20 */}

      <div className="event-data-container" id="g20event">
        <h2 className="dpsru-secondary-heading-for-every-page">G20 Events</h2>

        <ul className="evnet-page-ul-container">
          <li className="evnet-page-list">
            <a href={internationConf24} className="event-files-link">
              Where innovation meets reflection—collaborating for a healthier
              tomorrow during ICIADR-24 held at DPSRU, December , 9-10, 2024
            </a>{" "}
            <NavLink to="/g20" className="event-a-click-here">
              Click here
            </NavLink>
          </li>
          <li className="evnet-page-list">
            <a href={G20CycleRally} className="event-files-link">
              G20 CYCLE RALLY at “Qutub Minar” on 25th May 2023
            </a>{" "}
            <NavLink to="/g20" className="event-a-click-here">
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={panelDiscussionToInvestInPeople}
              className="event-files-link"
            >
              Panel Discussion @DPSRU on “TO INVEST IN PEOPLE, PRIORITIZE
              EDUCATION” on 24.01.2023
            </a>{" "}
            <NavLink
              to="/g20PanelDiscussionReport"
              className="event-a-click-here"
            >
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={G20IndoBrazilFusionFest} className="event-files-link">
              G20 Indo Brazil Fusion Fest
            </a>{" "}
            <NavLink to="/indoBrazilFusionFest" className="event-a-click-here">
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={REPORTonPUSHPPRADARSHNAM2023} className="event-files-link">
              REPORT on PUSHP PRADARSHNAM 2023
            </a>
          </li>{" "}
          <li className="evnet-page-list">
            <a
              href={G20MahafoodfestivalofChinaFranceGermanyatDPSRU}
              className="event-files-link"
            >
              G20 Maha food festival of China, France & Germany at DPSRU
            </a>{" "}
            <NavLink to="/G20MahaFoodFestival" className="event-a-click-here">
              Click here
            </NavLink>
          </li>{" "}
          <li className="evnet-page-list">
            <a href={G20QuizCompetitionatDPSRU} className="event-files-link">
              G20 Quiz Competition at DPSRU
            </a>{" "}
            <NavLink to="/g20QuizCompetition" className="event-a-click-here">
              Click here
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Events;
