import React, { useState } from "react";
import "./Examination.css";
import { NavLink, Outlet } from "react-router-dom";
import { FaStream } from "react-icons/fa";

import newGif from "../NEW/new.gif";

import Carousel from "nuka-carousel";

import studentsAwards from "./Results/Awardees-Convocation-2018-2022.pdf";

import img1 from "./ExaminatonData/ExaminationCarousel/img1.jpg";
import img2 from "./ExaminatonData/ExaminationCarousel/img2.jpg";
import img3 from "./ExaminatonData/ExaminationCarousel/img3.jpg";
import img4 from "./ExaminatonData/ExaminationCarousel/img4.jpg";
import img5 from "./ExaminatonData/ExaminationCarousel/img5.jpg";
import img6 from "./ExaminatonData/ExaminationCarousel/img6.jpg";
import img7 from "./ExaminatonData/ExaminationCarousel/img7.jpg";
import img8 from "./ExaminatonData/ExaminationCarousel/img8.jpg";

// pdfFiles

import annualConvo6th from "./ExaminatonData/Convocation-Notice.pdf";

// new updated pdf files data on 7oct 2023
import newpdf01 from "./ExaminatonData/Ph.D-Viva-Voce-Notification-of-Ms.-Neha.pdf";
import newpdf02 from "./ExaminatonData/Ph.D-Viva-Voce-Notification-of-Mr.-Prateek-Sharma.pdf";
import newpdf03 from "./ExaminatonData/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";
import newpdf04 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Meenu-Grover-Sharma.pdf";
import newpdf05 from "./ExaminatonData/Supplementary-Examination-notice-for-WCSC-students.pdf";
import newpdf06 from "./ExaminatonData/Notice-ABC-ID.pdf";
import newpdf07 from "./ExaminatonData/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import newpdf08 from "./ExaminatonData/EVEN-SEMESTER-EXAMINATION-FORM-NOTICE-2023.pdf";
import newpdf09 from "./ExaminatonData/EVEN-SEMESTER-EXAMINATION-FORM-NOTICE-2023.pdf";
import newpdf10 from "./ExaminatonData/Unfair-Means-Notice-2023.pdf";
import newpdf11 from "./ExaminatonData/NOTICE-OF-EXAMINATION-JUNE-2023.pdf";
import newpdf12 from "./ExaminatonData/Examination-Fee-Notice-2023.pdf";
import newpdf13 from "./ExaminatonData/Corrigendum-1.pdf";
import newpdf14 from "./ExaminatonData/Corrigendum.pdf";
import newpdf15 from "./ExaminatonData/Even-Semester-Supple.-Examination-Fee-Notice-March-2023.pdf";
import newpdf16 from "./ExaminatonData/Exams-Notice-DMYS-2nd-3rd-Trimester-Batch-1-2_1.pdf";
import newpdf17 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D.-Degree-to-Ms.-Anita-kumari.pdf";
import newpdf18 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D-Degree-to-Mr.-Mitul-Srivastava_1-1.pdf";
import newpdf19 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Lovika-Mittal_1.pdf";
import newpdf20 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Rakhi-Ahuja_1.pdf";
import newpdf21 from "./ExaminatonData/Exam-Fees-Notice-1.pdf";
import newpdf22 from "./ExaminatonData/Notice-for-Viva-Voce-of-Mr.-Mitul-Srivastava_1-1.pdf";

import ex1 from "./ExaminatonData/Fee-Notice-for-1st-Semester-Examination-1.pdf";
import ex2 from "./ExaminatonData/B.Pharm-Course-Promotion-Rule-2.pdf";
import ex3 from "./ExaminatonData/Notice-for-Viva-Voce-of-Ms.-Anita-kumari.pdf";
import ex4 from "./ExaminatonData/Fees-Notice-for-Pre.-Ph.D.-Students.pdf";
import ex5 from "./ExaminatonData/Date-Sheet-of-D.-Pharm-1st-Year-Supplementary-Examination-Feb-2023.pdf";
import ex6 from "./ExaminatonData/Notice-for-Viva-Voce-of-Ms.-Lovika-Mittal-1.pdf";
import ex7 from "./ExaminatonData/Examination-fees-notice-for-Odd-Semester-students_1.pdf";
import ex8 from "./ExaminatonData/Supplementary-Fees-Notice-for-M.-Pharm-D.Pharm-Students.pdf";
import ex9 from "./ExaminatonData/Examination-fee-Notice.pdf";
import ex10 from "./ExaminatonData/Notice-for-Viva-Voce-of-Ms.-Shilpa-Jain.pdf";
import ex11 from "./ExaminatonData/Fees-Notice-for-WCSC-students.pdf";
import ex12 from "./ExaminatonData/UFM-Punishment-Rules-2022.pdf";
import ex13 from "./ExaminatonData/Last-Date-of-Thesis-Submission.pdf";
import ex14 from "./ExaminatonData/Unfair-Means-Notice.pdf";
import ex15 from "./ExaminatonData/Corrigendum-of-M.-Pharm-2nd-Semester-Examination-October-2022..pdf";
import ex16 from "./ExaminatonData/Notification-for-the-Award-of-Ph.-D-Degree-to-Mr.-Kushagra-Khanna-1.pdf";
import ex17 from "./ExaminatonData/Examination-Fee-Notice (1).pdf";
import ex18 from "./ExaminatonData/Notice-for-Ph.D-Viva-voce-of-Mr.-kushagra-Khanna.pdf";
import ex19 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D.-Degree-to-Mr.-Mrityunjay-Singh.pdf";
import ex20 from "./ExaminatonData/Date-Sheet-Of-D.-Pharm-1st-Year-Annual-ExaminationAugust-2022.pdf";

import ex43 from "./ExaminatonData/Date-sheet-of-BPT-8th-Semester-Theory-Examination-August-2022.pdf";
import ex44 from "./ExaminatonData/Date-sheet-of-BPT-8th-Semester-Practical-Examination-July-2022-1.pdf";
import ex45 from "./ExaminatonData/Fee-Notice-for-Supplementary-Examination-1.pdf";
import ex46 from "./ExaminatonData/Date-Sheet-of-B.-Sc.-Hons.-Sports-Sciences-6th-Semester-Examination-July-2022.pdf";
import ex47 from "./ExaminatonData/Date-Sheet-of-B.Pharm-8th-Semester-Theory-Examination-July-2022.pdf";
import ex48 from "./ExaminatonData/Extract-of-Minutes-of-Unfair-Means-1.pdf";
import ex49 from "./ExaminatonData/Date-Sheet-of-MPT-Sports-Medicine-1st-Semester-Practical-Examination-June-2022.pdf";
import ex50 from "./ExaminatonData/Examination-Fee-Notice (2).pdf";
import ex51 from "./ExaminatonData/Date-Sheet-of-DMYS-Trimester-III-batch-1-Practical-Examination-July-2022.pdf";
import ex52 from "./ExaminatonData/UFM-Notice.pdf";
import ex53 from "./ExaminatonData/Date-Sheet-of-DMYS-Trimester-III-Batch-1-Theory-Examination-June-2022.pdf";
import ex54 from "./ExaminatonData/Result-of-Diploma-in-Medical-Lab-Technology-DMLT-1st-Semester-Examination-April-2022.pdf";

import ex55 from "./ExaminatonData/Examination-Fees-Notice.pdf";
import ex56 from "./ExaminatonData/Date-sheet-of-M.Pharm-1st-Semester-SPS-June-2022.pdf";
import ex57 from "./ExaminatonData/Date-Sheet-of-M.Pharm-1st-Semester-Practical-Examination-June-2022.pdf";
import ex58 from "./ExaminatonData/Date-Sheet-DMYS-2nd-Trimester-batch-2-Practical-Examination-June-2022.pdf";
import ex59 from "./ExaminatonData/Date-Sheet-of-DMYS-1st-Trimester-batch-1-2-Practical-Supplementary-Examination-June-2022.pdf";
import ex60 from "./ExaminatonData/Date-Sheet-of-DMYS-2nd-Trimester-Batch-II-Theory-Examination-June-2022.pdf";
import ex61 from "./ExaminatonData/Date-Sheet-Of-DMYS-1st-Trimester-Batch-1-2-Theory-Supplementary-Examination-June-2022.pdf";
import ex62 from "./ExaminatonData/Date-Sheet-B.Pharm-Ayur-1st-Sem-Theory-Exam-June-2022.pdf";
import ex63 from "./ExaminatonData/CORRIGENDUM (1).pdf";
import ex64 from "./ExaminatonData/Date-Sheet-of-BPT-1st-Semester-Practical-Examination-June-2022-1.pdf";
import ex65 from "./ExaminatonData/Date-Sheet-of-M.-Sc.-Sports-Sciences-1st-Semester-Practical-Examination-May-2022.pdf";
import ex66 from "./ExaminatonData/Date-Sheet-of-M.Pharm-2nd-Semester-Supple.-Exam-DIPSAR-June-2022.pdf";
import ex67 from "./ExaminatonData/Date-Sheet-of-D.-Pharm-2nd-Year-Practical-Examination-May-2022.pdf";
import ex68 from "./ExaminatonData/Date-Sheet-of-M.Sc_.-Artificial-intelligence-Precision-Medicine-Practical-Examination-June-2022_1.pdf";
import ex69 from "./ExaminatonData/Date-Sheet-of-SAYA-MMC-1st-Semester-Examination-May-2022.pdf";
import ex70 from "./ExaminatonData/Date-Sheet-of-M.Sc_.-AIPM-1st-Semester-Theory-Examination-May-2022-pdf.pdf";
import ex71 from "./ExaminatonData/Date-Sheet-Of-BBA-CH-1st-Semester-Theory-Examination-May-2022.pdf";
import ex72 from "./ExaminatonData/Date-Sheet-of-SAYA-MMC-1st-Semester-Practical-Examination-May-2022.pdf";
import ex73 from "./ExaminatonData/Date-Sheet-of-MPT-Sports-Medicine-1st-Semester-Theory-Examination-May-2022.pdf";
import ex74 from "./ExaminatonData/Revised-Date-Sheet-of-M.Pharm-1st-Semester-Theory-Examination-May-2022.-1-DIPSAR.pdf";
import ex75 from "./ExaminatonData/Revised-Date-Sheet-of-M.Pharm-1st-Semester-Theory-Examination-May-2022..pdf";
import ex76 from "./ExaminatonData/Result-of-D.-Pharm-2nd-Year-Supplementary-Examination-April-2022.pdf";
import ex77 from "./ExaminatonData/Result-of-D.-Pharm-1st-Year-Supplementary-examination-April-2022.pdf";
import ex78 from "./ExaminatonData/Date-Sheet-of-B.Sc_.-H.-Sports-Science-1st-Semester-Practical-Examination-May-2022.pdf";
import ex79 from "./ExaminatonData/Date-Sheet-of-D.Pharm-2nd-Year-Examination-May-2022.pdf";

// import ex80 from './ExaminatonData/'

import ex81 from "./ExaminatonData/Revised-Date-Sheet-of-BPT-1st-Semester-Theory-Examination-May-2022.pdf";
import ex82 from "./ExaminatonData/Date-Sheet-of-DMLT-1st-Semester-Practice-Examination-May-2022.pdf";
import ex83 from "./ExaminatonData/Date-Sheet-of-BMLT-1st-Semester-Practical-Examination-May-2022-1.pdf";
import ex84 from "./ExaminatonData/Date-Sheet-of-BPT-1st-Semester-Theory-Examination-May-2022.pdf";
import ex85 from "./ExaminatonData/Date-Sheet-of-Practical-Exam-SPS-May-2022.pdf";
import ex86 from "./ExaminatonData/Date-Sheet-of-B.Pharm-1st-Semester-Practical-Exam-DIPSAR-May-2022.pdf";
import ex87 from "./ExaminatonData/Date-Sheet-of-B.Sc_.Hons_.-Sports-Sciences-1st-Semester-Theory-Examination-May-2022.pdf";
import ex88 from "./ExaminatonData/Date-Sheet-of-M.Sc_.-Sports-Sciences-1st-Semester-Theory-Examination-May-2022.pdf";
import ex89 from "./ExaminatonData/DMYS-Trimester-II-Batch-I-Result-March-2022_.pdf";
import ex90 from "./ExaminatonData/Date-Sheet-of-BMLT-1st-Semester-Theory-Examination-April-2022.pdf";
import ex91 from "./ExaminatonData/Date-Sheet-of-DMLT-1st-Semester-Theory-Examination-April-2022.pdf";
import ex92 from "./ExaminatonData/Azad-Saifi-D.Pharm-1st-Year-Supple.-Result-2021.pdf";
import ex93 from "./ExaminatonData/Date-Sheet-of-B.-Pharm-7th-Semester-Theory-Exam-Non-University-April-2022.pdf";
import ex94 from "./ExaminatonData/Datesheet-D.Pharm-Ist-year-Suppl.-Examination-1-1.pdf";
import ex95 from "./ExaminatonData/Fee-Notice-for-1st-Semester-Odd-Semester-Examination-May-2022.pdf";
import ex96 from "./ExaminatonData/Fee-Notice-for-D.Pharm-2nd-Year-Anuual-Examination-April-2022-2.pdf";
import ex97 from "./ExaminatonData/Date-Sheet-of-MBA-PET-MBA-PM-MHA-and-MPH-Examinations-April-2022-1.pdf";
import ex98 from "./ExaminatonData/Revised-Date-Sheet-of-B.Pharm-1st-Semester-Theory-Examination-April-2022.pdf";
import ex99 from "./ExaminatonData/Date-Sheet-of-D.Pharm-1st-Year-2nd-Year-Supple.-Exam.-April-2022.pdf";
import ex100 from "./ExaminatonData/Exam-Fee-Notice.pdf";

import ex101 from "./ExaminatonData/Revised-Date-Sheet-of-B.-Pharm-3rd-and-5th-Semester-Exam.pdf";
import ex102 from "./ExaminatonData/Supplementary-Exam-Fees-Notice.pdf";
import ex103 from "./ExaminatonData/Notification-for-the-Award-of-PhD-Degree-to-Mr.Nitin-Yadav.pdf";
import ex104 from "./ExaminatonData/Date-sheet-of-B.Pharm-3rd-5th-Semester-Theory-Examination-March-2022.pdf";
import ex105 from "./ExaminatonData/Date-Sheet-of-BPT-3rd-5th-7th-Semester-Examination-March-2022.pdf";
import ex106 from "./ExaminatonData/Date-Sheet-of-DMYS-2nd-Trimester-Batch-II-Practical-Examination-Match-2022_1-1.pdf";
import ex107 from "./ExaminatonData/Date-Sheet-of-DMYS-2nd-Trimester-Batch-II-Theory-Examination-March-2022_1.pdf";
import ex108 from "./ExaminatonData/Date-Sheet-of-BPT-3rd-5th-7th-Semester-Practical-Examination-March-2022.pdf";
import ex109 from "./ExaminatonData/Date-Sheet-of-B.Pharm-5th-Semester-Practical-Examination-DPSRU-March-2022-1.pdf";
import ex110 from "./ExaminatonData/Date-Sheet-of-B.Pharm-5th-Semester-Practical-ExaminationDIPSAR-March-2022-2.pdf";
import ex111 from "./ExaminatonData/Date-Sheet-of-B.Pharm-3rd-Semester-ExaminationDPSRU-March-2022-1.pdf";
import ex112 from "./ExaminatonData/Date-Sheet-of-B.Pharm-3rd-Semester-Examination-Practical-DIPSAR-March-2022-1.pdf";
import ex113 from "./ExaminatonData/Date-Sheet-of-B.Pharm-7th-Semester-Practical-Examination-March-2022-2.pdf";
import ex114 from "./ExaminatonData/Date-Sheet-of-B.Pharm-7th-Semester-Theory-Examination-March-2022-1.pdf";
import ex115 from "./ExaminatonData/Date-Sheet-of-B.Sc_.-Hons.-Sports-Sciences-3rd-5th-semester-practical-Examination-March-2022.pdf";
import ex116 from "./ExaminatonData/Date-Sheet-of-B.Sc_.Hons_.-Sports-Science-3rd-5th-Semester-Theory-Exam-March-2022-1.pdf";
import ex117 from "./ExaminatonData/Date-Sheet-of-D.-Pharm-1st-Year-Special-Practical-Exam-Feb-2022.pdf";
import ex118 from "./ExaminatonData/Notification-for-the-Award-of-PhD-Degree-to-Ms.-Anubhuti-Pasrija.pdf";
import ex119 from "./ExaminatonData/Instructions-for-Online-Examination-for-Allied-Health-Science-3rd-Semester-Students.pdf";
import ex120 from "./ExaminatonData/Document.pdf";
import ex121 from "./ExaminatonData/Notification-for-the-Award-of-Ph.D.-Degree-of-Mahendra-Yadav.pdf";
import ex122 from "./ExaminatonData/Date-Sheet-of-Allied-Health-Science-3rd-semester-Examination-February-2022.pdf";
import ex123 from "./ExaminatonData/Document-2.pdf";
import ex124 from "./ExaminatonData/Document-1.pdf";
import ex125 from "./ExaminatonData/PhD-Viva-Voce-Notification-of-Ms.-Anubhuti-Pasrija.pdf";
import ex126 from "./ExaminatonData/Date-Sheet-BPT-7th-Sem-Supple.-Practical-Examination.pdf";
import ex127 from "./ExaminatonData/PhD-Viva-voce-Notification-of-Mr.-Mahendra-Yadav-1.pdf";
import ex128 from "./ExaminatonData/Date-Sheet-of-M.-Pharm-1st-semester-Supple.-Exam-January-2022-2.pdf";
import ex129 from "./ExaminatonData/PhD-Viva-voce-notification-of-Ms.-Reena-Badhwar.pdf";
import ex130 from "./ExaminatonData/Instructions-for-Online-Examination-for-BPT-1st-3rd-5th-7th-Semester-Supplementary-Students.pdf";

import ex131 from "./ExaminatonData/Date-sheet-of-BPT-7th-Semester-Supplementary-Theory-Examination-January-2022.pdf";
import ex132 from "./ExaminatonData/Date-Sheet-of-BPT-1st-3rd-5th-Semester-Supplementary-Theory-Examination-January-2022.pdf";
import ex133 from "./ExaminatonData/Instructions-for-Online-Examination-for-B.-Pharm-1st-3rd-5th-Semester-Supplementary-Students.pdf";
import ex134 from "./ExaminatonData/Notice-for-Online-Supplementary-Exam.pdf";
import ex135 from "./ExaminatonData/Date-Sheet-of-B.Pharm-1st-3rd-5th-Sem-Supplementary-Exam.-January-2022-New.pdf";
import ex136 from "./ExaminatonData/Revised-Date-Sheet-of-B.-Pharm-7th-Semester-Supple.-Examination.pdf";
import ex137 from "./ExaminatonData/B.PHARM-7-SEMESTER-SUPPLEMENTARY-DATE-SHEET.pdf";
import ex138 from "./ExaminatonData/DECISION-OF-UFM-MEANS.pdf";
import ex139 from "./ExaminatonData/Notice-for-supplementary-exam-of-DMYS-1-TRIMESTER.pdf";
import ex140 from "./ExaminatonData/Notice-for-supplementary-exam-fee-notice.pdf";
import ex141 from "./ExaminatonData/DMYS-1-TRIMESTER-BATCH-II-PRACTICAL-DATE-SHEET.pdf";
import ex142 from "./ExaminatonData/DMYS-1ST-TRIMESTER-BATCH-II-DATE-SHEET.pdf";
import ex143 from "./ExaminatonData/UFM-Notice (1).pdf";
import ex144 from "./ExaminatonData/D.Pharm-1st-Year-Practical-Date-Sheet-October-2021-DIPSAR.pdf";
import ex145 from "./ExaminatonData/B.PHARM-2ND-SEMESTER-PRACTICAL-DATE-SHEET-OCTOBER-DPSRU.pdf";
import ex146 from "./ExaminatonData/B.PHARM-2ND-SEMESTER-PRACTICAL-DATE-SHEET-OCTOBER-DIPSAR.pdf";
import ex147 from "./ExaminatonData/M.PHARM-2ND-SEMESTER-PRACTICAL-DATE-SHEET-OCTOBER-DPSRU-2021.pdf";
import ex148 from "./ExaminatonData/M.PHARM-2ND-SEMESTER-PRACTICAL-DATE-SHEET-OCTOBER-DIPSAR-2021.pdf";
import ex149 from "./ExaminatonData/Date-Sheet-of-MBA-MPH-MHM-Practical-Examination-October-2021.pdf";
import ex150 from "./ExaminatonData/Date-Sheet-of-B.-Sc.-H-Sports-Science-2nd-Semester-Practical-Examination-October-2021.pdf";
import ex151 from "./ExaminatonData/Date-Sheet-of-BPT-2nd-Semester-Practical-Examination-October-2021.pdf";
import ex152 from "./ExaminatonData/Revised-Date-Sheet-of-Pre.-PhD-Practical-Examination-Sep-2021.pdf";
import ex153 from "./ExaminatonData/D.Pharm-1st-Year-Theory-Examination-September-2021-DIPSAR.pdf";
import ex154 from "./ExaminatonData/Date-Sheet-of-B.Pharm-2nd-Semester-Theory-Exam-DIPSAR-DPSRU-September-2021.pdf";
import ex155 from "./ExaminatonData/Date-Sheet-of-M.Pharm-2nd-Semester-Thoery-Exam-DPSRU-September-2021.pdf";
import ex156 from "./ExaminatonData/Date-Sheet-of-M.Pharm-2nd-Semester-Theory-Exam-DIPSAR-September-2021.pdf";
import ex157 from "./ExaminatonData/Instruction-for-Online-Examination-for-B.-Sc.-Sports-Science-2nd-Semester-Students.pdf";
import ex158 from "./ExaminatonData/Instruction-for-Online-Examination-for-MBAMHM-MPH-2nd-Semester-Students.pdf";
import ex159 from "./ExaminatonData/Instruction-for-Online-Examination-for-Pre.-PhD-Students.pdf";
import ex160 from "./ExaminatonData/Date-Sheet-of-BPT-2nd-Semester-Theory-Examination-September-2021.pdf";
import ex161 from "./ExaminatonData/Date-Sheet-of-BPT-4th-6th-8th-Semester-Practical-Examination-September-2021.pdf";
import ex162 from "./ExaminatonData/Date-Sheet-of-MBA-MHM-MPH-2nd-Semester-Theory-Examination-Sep-2021-2.pdf";
import ex163 from "./ExaminatonData/Date-Sheet-of-Pre.-PhD-Theory-Examination-Sep-2021-1.pdf";
import ex164 from "./ExaminatonData/Date-Sheet-of-B.-Sc.H-Sports-Sciences-2nd-Semester-Theory-Examination-Sep-2021.pdf";
import ex165 from "./ExaminatonData/Date-Sheet-DMYS-Practical-1st-Trimester.pdf";
import ex166 from "./ExaminatonData/Date-Sheet-DMYS-Theory-1st-Trimester.pdf";
import ex167 from "./ExaminatonData/Examination-Notification.pdf";
import ex168 from "./ExaminatonData/Examination-Notification-for-BPT-2nd-Semester.pdf";
import ex169 from "./ExaminatonData/DATE-SHEET-OF-B.PHARM-4TH-SEMESTER-LATERAL-ENTRY-NON-UNIVERSITY-EXAM-SEP-2021_1.pdf";
import ex170 from "./ExaminatonData/Date-Sheet-of-B.-Sc.-H-Sports-Science-4th-Semester-Practical-Exams-Aug-2021.pdf";

import ex171 from "./ExaminatonData/CORRIGENDUM-2.pdf";
import ex172 from "./ExaminatonData/CORRIGENDUM-1 (1).pdf";
import ex173 from "./ExaminatonData/Instruction-for-Online-Examination-for-B.-Sc.-Sports-Science-4th-Semester-Students.pdf";
import ex174 from "./ExaminatonData/Online-Instruction-for-B.Pharm-4th-and-6th-Semester-Exam.pdf";
import ex175 from "./ExaminatonData/Revised-Datesheet-of-BPT-4th-6th-8th-Semetser-Theory-Examination-August2021.pdf";
import ex176 from "./ExaminatonData/Date-Sheet-of-BPT-4th-6th-8th-Semester-ExaminationAugust-2021.pdf";
import ex177 from "./ExaminatonData/Date-Sheet-of-B.Pharm-2-and-6-Semester-August-2021.pdf";
import ex178 from "./ExaminatonData/Date-Sheet-of-B.Sc-Hons.-Sports-Science-4th-Semester-Theory-Exam-August-2021.pdf";
import ex179 from "./ExaminatonData/Exam-Fee-Notice-for-Even-Semester-Exam-2021.pdf";
import ex180 from "./ExaminatonData/Date-Sheet-of-B.Sc_.-H-Sports-Science-6th-Semester-Practical-Examination-July-2021.pdf";

// import ex181 from './ExaminatonData/'

import ex182 from "./ExaminatonData/Instruction-for-Online-Exam-for-B.Sc_.-H-Sports-Science-6th-Semester-Students.pdf";
import ex183 from "./ExaminatonData/B.Pharm-8th-Semester-Instructions-for-Online-Examination-1.pdf";
import ex184 from "./ExaminatonData/Revised-Date-Sheet-of-B.Pharm-8th-Semester-Theory-Exam-July-2021.pdf";
import ex185 from "./ExaminatonData/Date-Sheet-of-B.Sc_.-H-Sports-Science-6th-Semester-Examination-July-2021.pdf";
import ex186 from "./ExaminatonData/Date-Ssheet-of-D.Pharm-2nd-Year-Practical-Examination-May-2021.pdf";
import ex187 from "./ExaminatonData/Date-Sheet-of-B.Pharm-DIPSAR-1st-Semester-Practical-Examination-May-2021.pdf";
import ex188 from "./ExaminatonData/Date-Sheet-of-B.-Pharm-1st-Semester-Practical-Examination-May-2021.pdf";
import ex189 from "./ExaminatonData/Date-Sheet-of-M.Pharm-1st-Semester-Practical-Examination.pdf";
import ex190 from "./ExaminatonData/Date-Sheet-of-B.Sc_.-H.-Sports-Sciences-Practical-Examination-April-2021.pdf";
import ex191 from "./ExaminatonData/Instruction-for-Online-Examination-for-D.Pharm-2nd-Year-Students.pdf";
import ex192 from "./ExaminatonData/Date-Sheet-of-D.Pharm-2nd-year-Examination-April-2021.pdf";
import ex193 from "./ExaminatonData/Instruction-for-Online-Exam-for-BPT-1st-Semester-Students-1.pdf";
import ex194 from "./ExaminatonData/Process-Manual-Dipsar-Candidate.pdf";

// import ex195 from './ExaminatonData/'

import ex196 from "./ExaminatonData/Instruction-for-Online-Examination-for-B.Sc_.-H.-Sports-Science-1st-Semester-Students-1.pdf";
import ex197 from "./ExaminatonData/Instruction-for-Online-Examination-for-MBAMHM-MPH-1st-Semester-Students.pdf";
import ex198 from "./ExaminatonData/Instruction-for-Online-Examination-for-M.Pharm-1st-Semester-Students-1.pdf";
import ex199 from "./ExaminatonData/Instruction-for-Online-Examination-for-B.Pharm-1st-Semester-Students.pdf";
import ex200 from "./ExaminatonData/Date-Sheet-of-M.Pharm-1st-2nd-Semester-Supplementary-Practical-Exam-April-2021.pdf";

import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaArrowAltCircleUp } from "react-icons/fa";

// files after live
import NotificationPhdDegreeMsSweta from "./ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Shweta Paroha_rotated.pdf";
import NotificationPhdDegreeMsNeha from "./ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Neha_rotated.pdf";
import UFMNotice from "./ExaminatonData/UFM Notice.pdf";
import corrigendunBpharm from "./ExaminatonData/Corrigendum (B.Pharm Ayurveda).pdf";
import MpharmVivaVocaNotice from "./ExaminatonData/M.Pharm Viva-Voce Notice .pdf";
import phdVivaVocaNoticeBhuika from "./ExaminatonData/PhD Viva-Voce Notification of Ms. Bhumika Kumar.pdf";
import corigendurum22 from "./ExaminatonData/Corrigendum (1)-2.pdf";
import MptVivaVocaNotice from "./ExaminatonData/MPT Viva-Voce Notice.PDF";
import supplemetaryExamFeeNotice from "./ExaminatonData/Supplementary exam fee notice.pdf";
import DecisionOfUnfairMeansCommittee from "./ExaminatonData/Decision of unfair means Committee.pdf";
import notificationforawardofPhdDegreetoMrPrateek from "./ExaminatonData/Notification for the Award of Ph.D Degree to Mr. Prateek Sharma.pdf";
import noticeformptVivaVoca from "./ExaminatonData/Notice for MPT Viva-voce.PDF";
import notificationfortheawardofphdDegreeomsbhumik from "./ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Bhumika Kumar.pdf";
import mscsportsciencevivanotice from "./ExaminatonData/M. Sc. Sports Science Viva Notice.PDF";
import phdvivavovahimanshukushwaha from "./ExaminatonData/PhD Viva-Voce Notification of Ms. Himanshu Kushwah.pdf";
import mpharmvivavocaexam2023 from "./ExaminatonData/Notice for M.Pharm Viva-Voce Examination 2023.pdf";
import corigendremdec2023 from "./ExaminatonData/Corrigendum18dec2023.pdf";
import vivaVocaNoticeofMsRuchitriathi from "./ExaminatonData/PhD Viva-Voce Notification of Ms. Ruchi Tripathi (1).pdf";
import examinationfeenoticejan2024 from "./ExaminatonData/Examination Fees Notice.pdf";
import corigendrum12jan from "./ExaminatonData/Corrigendum (1) 12jan.pdf";
import admitcardnoticejan24 from "./ExaminatonData/Admit Card Notice.pdf";
import corigendrum05feb24 from "./ExaminatonData/Corrigendum 02-05-2024.pdf";
import corigendum8feb24 from './ExaminatonData/Corrigendum for MPT 1st Semester Students.pdf'
import corigendrmforbpt1stsem from './ExaminatonData/Corrigendum for BPT 1st Semester Students.pdf'
import corigendrum06mar24 from './ExaminatonData/CCY Corrigendum.pdf'
import examformnoticeforprephdstudents from './ExaminatonData/Examinations Form Notice for Pre. Ph.D. Students.pdf'
import unfairMeansNoticeofApr2024 from './ExaminatonData/Unfair Means Notice April 2024.pdf'
import examFeeNoticeAprmay24 from './ExaminatonData/Examination Fees Notice Even Semester Students.pdf'
import oddSemSuppFormnoticeexam from './ExaminatonData/Odd Semester Supple. Exam Form Notice 2024.pdf'
import Ufmmeetiondecision from './ExaminatonData/UFM Meeting Decision.pdf'
import feesNoticeForevensemexam from '../Notifications/Notification Files/Fees Notice for Even Semester Examination.pdf'
import oddsemexamfeenoticesuppjune24 from './ExaminatonData/Odd Semester Examination fee notice (Supplementary) June-2024.pdf'
import Vivavocanoticeforbbach6thsemstudent from './ExaminatonData/Viva-Voce Notice for BBA-CH 6th Semester Students.pdf'
import phdvicaVocaNotificationofMrAshwinikMishra from './ExaminatonData/Ph.D viva-voce Notification of Mr. Ashwini Kumar Mishra.pdf'
import noticeofDIssertationSubmissionforMBAPMITMHHMandmph4thsem from './ExaminatonData/Notice of Dissertation Submission for MBA (PM,ITM,HHM) & MPH 4th Semester Students.pdf'
import pdfvivavocanotifiofmrpankajmusyuni from './ExaminatonData/PhD Viva-Voce Notification of Mr. Pankaj Musyuni new.pdf'
import mptvivavocanew01 from './ExaminatonData/MPT Viva-Voce Notice (1).pdf'
import notificationfortheawardofphddegreetomrpankahmasyuni from './ExaminatonData/Notification for the Award of Ph.D Degree to Mr. Pankaj Musyuni.pdf'
import ufmnoticemayjunhul24 from './ExaminatonData/UFM Notice- May, June & July 2024.pdf'
import noticefortheAwardofPhddegreetomsruhitripathi from './ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi (1).pdf'
import mscsportssciencevicavocanotice from './ExaminatonData/M. Sc. Sports Science Viva-Voce Notice.pdf'
import noticeofDossertationsubmissionforMBApmitmhhtmandmph4thsem from './ExaminatonData/Notice of Dissertation Submission for MBA (PM,ITM,HHM) & MPH 4th Semester.pdf'
import noticefortheAwAardofPHDdegreetomrashwinikrmishra from './ExaminatonData/Notification for the Award of Ph.D Degree to Mr. Ashwini Kumar Mishra.pdf'
import decisionofExaminationDisciplinaryActioncommittee from './ExaminatonData/Decision of Examination Disciplinary Action Committee.pdf'
import PhdvivavocanotificationfMrPankajkr from './ExaminatonData/PhD Viva-Voce Notification of Mr. Pankaj Kumar.pdf'
import MPharmDRAthsemvivavocaexamoct2024 from './ExaminatonData/M.Pharm Viva-Voce (DRA) 4th Semester, October 2024-replaced.pdf'
import NoticeOfMpharm1stsemsuppexamformoct24 from './ExaminatonData/Notice of M. Pharm 1st Sem Supple. Exams form, October 2024.pdf'
import mPharmPharmaceuticalCHemistry4thsemoct24 from './ExaminatonData/Viva Voce Notice- M. Pharm (Pharmaceutical Chemistry) 4th Sem, October 2024.pdf'
import vivavocaNoticeofMharmcosmeceutics4thsemoct24 from './ExaminatonData/Viva Voce Notice of M. Pharm (Cosmeceutics) 4th Semester, October 2024.pdf'
import VivaVocaNoticeOfMpharmPA4thsemoct24 from './ExaminatonData/Viva-Voce Notice of M. Pharm (Pharmaceutical Analysis) 4th Semester, October 2024.pdf'
import vivaVocaNoticeofMBA4thsemexamoct24 from './ExaminatonData/Viva-Voce Notice  of MBA-PM 4th Semester Examination, October-2024.pdf'
import VivaVocaNotieofMBAHHM4thsemexamoct24 from './ExaminatonData/Viva-Voce Notice  of MBA-HHM 4th Semester Examination, October-2024.pdf'
import vivaVocanoticeofMBAITM4thsemexamoct24 from './ExaminatonData/Viva-Voce Notice of MBA-ITM 4th Semester Examination, October-2024.pdf'
import VivaVocanoticeofMBAHHMexamNov24 from './ExaminatonData/Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024.pdf'
import vivaVOcanoticempharmpharmacology4thsemoct24 from './ExaminatonData/Viva- Voce Notice- M. Pharm (Pharmacology) 4th Semester, October 2024.pdf'
import vivavocanoticempharmpharmaceutics4thsemoct24 from './ExaminatonData/Viva-Voce Notice- M. Pharm (Pharmaceutics) 4th Semester, October 2024.pdf'
import vivavocanoticeMpharmhospitalpharmcy4thsemoct24 from './ExaminatonData/Viva-Voce Notice- M. Pharm (Hospital Pharmacy) 4th Semester, October 2024.pdf'
import vivavocanoticeofMBAitm4thsemexamnov24 from './ExaminatonData/Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024.pdf'
import VivaVocanoticeofMBAPM4thsemexamnov24 from './ExaminatonData/Viva-Voce Notice  of MBA-PM 4th Semester Examination, November-2024.pdf'
import VivaVOcanoticeofMBAHHm4thsemexamnov24 from './ExaminatonData/Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024 .pdf'
import FeenoticeofDpharm1stsemand2ndyearsup from './ExaminatonData/Fee Notice for D.Pharm. 1st and 2nd Year Supplemenatry.pdf'
import Vivavocanoticempharmpharmaqualityassurance4thsemoct24 from './ExaminatonData/Viva-Voce Notice- M. Pharm (Pharmaceutical Quality Assurance) 4th Semester, October 2024.pdf'
import VivaVocaNticeForexcutiveDrugregsciencenov24 from './ExaminatonData/Viva-Voce Notice for Executive-Drug Regulatory Science , November 2024.pdf'
import vivavocofmbaitm4thsemexamnov242 from './ExaminatonData/Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024-2.pdf'
import vivavocanoticeofMPH4thsemexamnov24 from './ExaminatonData/Viva-Voce Notice  of MPH 4th Semester Examination, November-2024.pdf'
import vivavocanoticeofMBApm4thsemnov242 from './ExaminatonData/Viva-Voce Notice  of MBA-PM 4th Semester Examination, November-2024 (1).pdf'
// import vivavocanoticempharmpharmacognosy4thsemoct24 from './ExaminatonData/Viva-Voce Notice-M. Pharm (Pharmacognosy) 4th Semester, October 2024.pdf'
 import vivavocanoticempharmpharmacognosy4thsemoct24 from './ExaminatonData/Vica-Voce Notice- M. Pharm (Pharmacognosy) 4th Semester, November 2024 next.pdf'
 import VivaVOcacorrigendumforMBAITM4thsemreschedule from './ExaminatonData/Viva-Voce Corrigendum for MBA-ITM 4th Semester Students (Rescheduled).pdf'
 import vivavocanoticeofmpharmpb4thsem5thoct2024 from './ExaminatonData/Viva- Voce Notice of M.Pharm (Pharmaceutical Biotechnology) 4th Semester on 5th November 2024.pdf'
 import vivavocanoticeofMpharmpb4thsem8oct24 from './ExaminatonData/Viva-Voce Notice of M.Pharm (Pharmaceutical Biotechnology) 4th Semester on 8th November 2024.pdf'
 import vivavocaNoticeofMpharm4thsemclinicalResearchNov2024 from './ExaminatonData/Viva- Voce Notice of M.Pharm 4th Semester (Clinical Research), November 2024.pdf'
 import VivoVocanotieofMpharm4thsempharmacenov24 from './ExaminatonData/Viva-Voce Notice of M.Pharm 4th Semester (Pharmaceutics), November 2024.pdf'
 import vivavocanoticeofmbahh4thsemexamnov242 from './ExaminatonData/Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024 (1).pdf'
 import VivavocanoticeofMBAITM4thsemexamnov24 from './ExaminatonData/Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024 (2).pdf'
 import NoticeFortheAwardofPhddegreetoMepankajkr from './ExaminatonData/Notification for the Award of Ph.D Degree to Mr. Pankaj Kumar.pdf'
 import vivavocanoticeofMbaitm4thsemexamdec24 from './ExaminatonData/Viva-Voce Notice  of MBA-ITM 4th Semester Examination, December-2024.pdf'
 import VivavocanoticeofMBAPM4thsemexamdec24 from './ExaminatonData/Viva-Voce Notice  of MBA-PM 4th Semester Examination, December-2024.pdf'
 import PhdVivavocanotificationofMrGautamb from './ExaminatonData/Ph.D viva-voce Notification of Mr. Gautam Bhardwaj.pdf'
 import VivavocaNoticeofMBAHHM4thsemexamdec2024 from './ExaminatonData/Viva-Voce Notice  of MBA-HHM 4th Semester Examination, December-2024.pdf'
//  import vivovocanoticenoticeofMscaim4thsemexamdec2024 from './ExaminatonData/Viva-Voce Notice of M.Sc. AIM 4th Semester Examination, December-2024.pdf'
 import vivovocanoticenoticeofMscaim4thsemexamdec2024 from './ExaminatonData/Revised Viva-Voce Notice of M.Sc. AIM 4th Semester Examination, December-2024.pdf'
 import NotificationForTheawardofPhdawartofPhdtomrgaubhar from './ExaminatonData/Notification for the Award of Ph.D Degree to Mr. Gautam Bhardwaj.pdf'
//  import Oddsemexamformnoticedec24 from './ExaminatonData/Odd Semester Examination Form Notice, December 2024.pdf'
  import Oddsemexamformnoticedec24 from './ExaminatonData/Odd Semester Exams form Notice- December 2024.pdf'
  import PhdVivaVocaNotificationoVaishaliagr from './ExaminatonData/PhD Viva-Voce Notification of Ms. Vaishali Agrawal.pdf'
  import NotificationFotTheAwardofPhddegreemsvaishaliagarwal from './ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Vaishali Agrawal.pdf'
  import CorrigendumofMpharm4thsempharmacognosyvivavocajan25 from './ExaminatonData/Corrigendum of M. Pharm 4th sem Pharmacognosy viva-voce, January 2025.pdf'
  import NoticefortheAwardofPhdDegreetoMsRituTiwari from './ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Ritu Tiwari.pdf'
  import PhdVivaVocaNoticeofMSRenuBalaYadev from './ExaminatonData/PhD Viva-Voce Notification of Ms. Renu Bala Yadav.pdf'
  import corrigendrum2025 from './ExaminatonData/Corrigendum 31jan 2025.pdf'
  import VivavocanoticeofpiyaliMitraMpharmpb4thsemfeb25 from './ExaminatonData/Viva-Voce Notice of Piyali Mitra M. Pharm (Pharmaceutical Biotechnology)4th Sem, February 2025.pdf'
  import corrigeumVivaVocamspialiMitra from './ExaminatonData/Corrigendum for viva-voce of Ms. Piyali Mitra, February 2025.pdf'
  import corrigeumOf3rdsempracticalClinicalBioitfeb25 from './ExaminatonData/Corrigendum of BMLT 3rd sem Practical (Clinical Biochemistry- II), February 2025.pdf'
  import corrigeumForTheoryExamBPAbscbiomedbbahcandmbahhmfeb25 from './ExaminatonData/Corrigendum for Theory Exams (BPA, B.SC. Biomedical , BBA- HC and MBA-HHM), February 2025.pdf'
  import CorrigendumForPracticalBscsfeb2025 from './ExaminatonData/Corrigendum for Practical B. Sc. sports, February 2025.pdf'
  import phdVivaVocaforArzoo from './ExaminatonData/PhD Viva-Voce Notification of Ms. Arzoo.pdf'
  import VivaVocaNoticeofMSCAIM4thsemexamfeb25 from './ExaminatonData/Viva-Voce Notice of M.Sc. AIM 4th Semester Examination, Feb-2025.pdf'
  import NotificationFortheAwardofPhdDegreetoMSArzoo from './ExaminatonData/Notification for the Award of Ph.D. Degree to Ms. Arzoo.pdf'
  import NoticeFortheAwardofPhdDegreetoMeVivekKr from './ExaminatonData/Notification for the Award of Ph.D. Degree to Mr. Vivek Kumar.pdf'
  import NoticeFortheAwardofPhdDegreetomsEenuBalaYadav from './ExaminatonData/Notification for the Award of Ph.D. Degree to Ms. Renu Bala Yadav.pdf'


function Examination() {
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [isNavigationToggled, setIsNavigationToggled] = useState(false);

  return (
    <div className="dpsru-every-component-css">
      <h1 className="dpsru-primary-heading-for-every-page">
        WELCOME TO EXAMINATION WING
      </h1>

      {/* Navigation */}
      <div className="bottom-nav examinataion-navigation Examination-navigation-hide-on-small-screen">
        <nav className="navbar examination-navbar">
          <ul className="bottom-menu-ul examination-bottom-menu-ul">
            <li className="bottom-menu-first-li">
              <NavLink className="bottom-menu-navLink-first " to="/examination">
                Home
              </NavLink>
            </li>
            <li className="bottom-menu-navLink-first ">
              <NavLink
                to="/examination/examination-about-us"
                className="sub-navlink-first bottom-menu-navLink-first "
              >
                About
              </NavLink>
            </li>

            <li className="bottom-menu-first-li">
              <NavLink className="bottom-menu-navLink-first " to="/examination">
                Programmes
              </NavLink>

              <ul>
                <li>
                  <NavLink
                    to="/examination"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    PhD.
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/examination"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    PG Programmes
                  </NavLink>
                  <ul>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Quality Addurance
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Clinical Research
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmaceutical Chemistry
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmaceutics
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmacology
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Hospital and Clinical Pharmacy
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Cosmecutis
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Drug Regulatory Affairs
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Industrial Pharmacy
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmaceutical Analysis
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmaceutical Biotechnology
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy in Pharmacognosy and Phytochemistry
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Hospital Administration
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Public Health
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        MBA ( Pharmaceutical Management)
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        MBA International Trade Management
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        MBA (Pharma Economics and Trade)
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        M.Sc. (Artificial Intelligence and Precision Medicine)
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        M. Sc Artificial Intelligence in Medicine
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Physiotherapy in Sports Medicine
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Physiotherapy in Pediatrics
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Physiotherapy in Cardiovascular and Pulmonary
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        M.Sc. Sport Science
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Master of Pharmacy (Executive – Drug Regulatory Science)
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="/examination"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    PG Diploma Programmes
                  </NavLink>
                  <ul>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        PG Diploma in Plant Tissue Culture
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/examination"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    UG Programmes
                  </NavLink>
                  <ul>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Bachelor of Pharmacy
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Bachelor of Physiotherapy
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        B.Sc. (Hons.) Sports Science
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Bachelor of Pharmacy (Ayurveda)
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        BMLT
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        BBA (Community Healthcare)
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        B. Sc (Hons) Biomedical Science
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    Diploma Programmes
                  </NavLink>
                  <ul>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Diploma in Pharmacy
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        DMLT
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    Certificate Programmes (WCSC)
                  </NavLink>
                  <ul>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Medicine Management
                      </NavLink>
                    </li>

                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Beauty Wellness Consultant
                      </NavLink>
                    </li>
                    <li className="inner-li-of-school-inside-of-academics">
                      <NavLink
                        to="/examination"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Sports Assessment & Yoga Application
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/institutions"
              >
                Institutions
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/rules"
              >
                Rules
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink className="bottom-menu-navLink-first " to="/contactUs">
                Contact Us
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/eDownload"
              >
                Download
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/examinatin-syllabus"
              >
                Syllabus
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/questionPapers"
              >
                Question Papers
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/examination-result"
              >
                Results
              </NavLink>

              <ul>
                <li>
                  <NavLink>
                    <a
                      href={studentsAwards}
                      className="bottom-menu-navLink-first "
                    >
                      Students Awards
                    </a>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink
                className="bottom-menu-navLink-first "
                to="/examination/examination-datesheet"
              >
                DateSheet
              </NavLink>
            </li>
            <li className="bottom-menu-first-li">
              <NavLink className="bottom-menu-navLink-first ">Login</NavLink>

              <ul>
                <li>
                  <a
                    href="https://dpsruexam.ucanapply.com/ucanassess/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    QPDS
                  </a>
                </li>
                <li>
                  <a
                    href="https://dpsruse.ucanapply.com/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    Student Login
                  </a>
                </li>
                <li>
                  <a
                    href="https://dpsruse.ucanapply.com/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    Admin Login
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      {/* <Outlet/> */}
      {/* Navigation */}

      {/* Responsive Navigation */}
      <div className="Examination-navigation-hide-on-big-screen">
        <button
          onClick={() => setIsNavigationToggled(!isNavigationToggled)}
          className="responsive-navigation-examination-wing-button"
        >
          NAVIGATION <FaStream />
        </button>
        {isNavigationToggled ? (
          <>
            {/* Navigation */}
            <div className="bottom-nav examinataion-navigation Examination-navigation-hide-on-big-screen">
              <nav className="navbar examination-navbar">
                <ul className="bottom-menu-ul examination-bottom-menu-ul">
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="bottom-menu-navLink-first ">
                    <NavLink
                      to="/examination/examination-about-us"
                      className="sub-navlink-first bottom-menu-navLink-first "
                    >
                      About
                    </NavLink>
                  </li>

                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination"
                    >
                      Programmes
                    </NavLink>

                    <ul>
                      <li>
                        <NavLink
                          to="/examination"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          PhD.
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/examination"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          PG Programmes
                        </NavLink>
                        <ul>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Quality Addurance
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Clinical Research
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmaceutical Chemistry
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmaceutics
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmacology
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Hospital and Clinical
                              Pharmacy
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Cosmecutis
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Drug Regulatory Affairs
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Industrial Pharmacy
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmaceutical Analysis
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmaceutical Biotechnology
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy in Pharmacognosy and
                              Phytochemistry
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Hospital Administration
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Public Health
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              MBA ( Pharmaceutical Management)
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              MBA International Trade Management
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              MBA (Pharma Economics and Trade)
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              M.Sc. (Artificial Intelligence and Precision
                              Medicine)
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              M. Sc Artificial Intelligence in Medicine
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Physiotherapy in Sports Medicine
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Physiotherapy in Pediatrics
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Physiotherapy in Cardiovascular and
                              Pulmonary
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              M.Sc. Sport Science
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Master of Pharmacy (Executive – Drug Regulatory
                              Science)
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink
                          to="/examination"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          PG Diploma Programmes
                        </NavLink>
                        <ul>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              PG Diploma in Plant Tissue Culture
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          to="/examination"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          UG Programmes
                        </NavLink>
                        <ul>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Bachelor of Pharmacy
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Bachelor of Physiotherapy
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              B.Sc. (Hons.) Sports Science
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Bachelor of Pharmacy (Ayurveda)
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              BMLT
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              BBA (Community Healthcare)
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              B. Sc (Hons) Biomedical Science
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          to="/"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          Diploma Programmes
                        </NavLink>
                        <ul>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Diploma in Pharmacy
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              DMLT
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          to="/"
                          className="sub-navlink-first bottom-menu-navLink-first"
                        >
                          Certificate Programmes (WCSC)
                        </NavLink>
                        <ul>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Medicine Management
                            </NavLink>
                          </li>

                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Beauty Wellness Consultant
                            </NavLink>
                          </li>
                          <li className="inner-li-of-school-inside-of-academics">
                            <NavLink
                              to="/examination"
                              className="sub-navlink-first bottom-menu-navLink-first"
                            >
                              Sports Assessment & Yoga Application
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/institutions"
                    >
                      Institutions
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/rules"
                    >
                      Rules
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/contactUs"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/eDownload"
                    >
                      Download
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/examinatin-syllabus"
                    >
                      Syllabus
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/questionPapers"
                    >
                      Question Papers
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/examination-result"
                    >
                      Results
                    </NavLink>

                    <ul>
                      <li>
                        <NavLink>
                          <a
                            href={studentsAwards}
                            className="bottom-menu-navLink-first "
                          >
                            Students Awards
                          </a>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/examination/examination-datesheet"
                    >
                      DateSheet
                    </NavLink>
                  </li>
                  <li className="bottom-menu-first-li">
                    <NavLink
                      className="bottom-menu-navLink-first "
                      to="/admin/admin-login"
                    >
                      Login
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            {/* Navigation */}
          </>
        ) : null}
        {/* Responsive Navigation */}
      </div>
      <Outlet />
      <br />
      <br />
      <br />
      <div className="examination-carousel-container">
        <Carousel
          autoplay="true"
          autoplayReverse="true"
          animation="zoom"
          pauseOnHover="true"
          wrapAround={true}
          renderBottomCenterControls={false}
          autoplayInterval={3000}
        >
          <img src={img1} alt="logo" />
          <img src={img2} alt="logo" />
          <img src={img3} alt="logo" />
          <img src={img4} alt="logo" />
          <img src={img5} alt="logo" />
          <img src={img6} alt="logo" />
          <img src={img7} alt="logo" />
          <img src={img8} alt="logo" />
        </Carousel>
      </div>

      <div className="examination-notification-container">
        {isNotificationShown ? (
          <div className="Examination-notification-toggled-container">
            <button
              className="examination-notification-btn-close"
              onClick={() => setIsNotificationShown(!isNotificationShown)}
            >
              Recent Notification{" "}
              <FaArrowAltCircleUp
                style={{ margin: "-11px", marginLeft: "20px" }}
              />{" "}
            </button>
            <div className="Examination-notification-toggled-container-heading">
              <h1>Archive</h1>
            </div>

            <div className="examination-toggle-container-table-data">
              {/* table */}
              <table style={{ width: "95%", fontSize: "1.2rem" }}>
                <thead>
                  <tr
                    style={{
                      color: "white",
                      background: "#333",
                      fontWeight: "bold",
                    }}
                  >
                    <th>Subject</th>
                    <th>Updated Date</th>
                  </tr>
                </thead>
                <tbody>
                  {/* ************************************************* */}

                  <tr>
                    <td>
                      <a href={NoticeFortheAwardofPhdDegreetomsEenuBalaYadav}>Notification for the Award of Ph.D. Degree to Ms. Renu Bala Yadav</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>04-03-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NoticeFortheAwardofPhdDegreetoMeVivekKr}>Notification for the Award of Ph.D. Degree to Mr. Vivek Kumar</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>04-03-2025</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={NotificationFortheAwardofPhdDegreetoMSArzoo}>Notification for the Award of Ph.D. Degree to Ms. Arzoo</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>04-03-2025</td>
                  </tr>


                  <tr>
                    <td>
                      <a href={VivaVocaNoticeofMSCAIM4thsemexamfeb25}>Viva-Voce Notice of M.Sc. AIM 4th Semester Examination, Feb-2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>20-02-2025</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={phdVivaVocaforArzoo}>PhD Viva-Voce Notification of Ms. Arzoo</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-02-2025</td>
                  </tr>


                  {/* <tr>
                    <td>
                      <a href={PannelMembersofphdVivaVocaforArzoo}>Panel Members PhD Viva-Voce of Ms. Arzoo</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-02-2025</td>
                  </tr> */}

                  <tr>
                    <td>
                      <a href={CorrigendumForPracticalBscsfeb2025}>Corrigendum for Practical B. Sc. sports, February 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-02-2025</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={corrigeumForTheoryExamBPAbscbiomedbbahcandmbahhmfeb25}>Corrigendum for Theory Exams (BPA, B.SC. Biomedical , BBA- HC and MBA-HHM), February 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-02-2025</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={corrigeumOf3rdsempracticalClinicalBioitfeb25}>Corrigendum of BMLT 3rd sem Practical (Clinical Biochemistry- II), February 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-02-2025</td>
                  </tr>


                  <tr>
                    <td>
                      <a href={corrigeumVivaVocamspialiMitra}>Corrigendum for viva-voce of Ms. Piyali Mitra, February 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-02-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivavocanoticeofpiyaliMitraMpharmpb4thsemfeb25}>Viva-Voce Notice of Piyali Mitra M. Pharm (Pharmaceutical Biotechnology)4th Sem, February 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>07-02-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corrigendrum2025}>Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>31-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={PhdVivaVocaNoticeofMSRenuBalaYadev}>PhD Viva-Voce Notification of Ms. Renu Bala Yadav</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NoticefortheAwardofPhdDegreetoMsRituTiwari}>Notification for the Award of Ph.D Degree to Ms. Ritu Tiwari</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={CorrigendumofMpharm4thsempharmacognosyvivavocajan25}>Corrigendum of M. Pharm 4th sem Pharmacognosy viva-voce, January 2025</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>21-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NotificationFotTheAwardofPhddegreemsvaishaliagarwal}>Notification for the Award of Ph.D Degree to Ms. Vaishali Agrawal</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>14-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={PhdVivaVocaNotificationoVaishaliagr}>PhD Viva-Voce Notification of Ms. Vaishali Agrawal</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>07-01-2025</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={Oddsemexamformnoticedec24}>Odd Semester Examination Form Notice, December 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-12-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NotificationForTheawardofPhdawartofPhdtomrgaubhar}>Notification for the Award of Ph.D Degree to Mr. Gautam Bhardwaj</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>16-12-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivovocanoticenoticeofMscaim4thsemexamdec2024}>Viva-Voce Notice of M.Sc. AIM 4th Semester Examination, December-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>06-12-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivavocaNoticeofMBAHHM4thsemexamdec2024}>Viva-Voce Notice of MBA-HHM 4th Semester Examination, December-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={PhdVivavocanotificationofMrGautamb}>Ph.D viva-voce Notification of Mr. Gautam Bhardwaj</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>28-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivavocanoticeofMBAPM4thsemexamdec24}>Viva-Voce Notice  of MBA-PM 4th Semester Examination, December-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>25-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofMbaitm4thsemexamdec24}>Viva-Voce Notice  of MBA-ITM 4th Semester Examination, December-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>25-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NoticeFortheAwardofPhddegreetoMepankajkr}>Notification for the Award of Ph.D Degree to Mr. Pankaj Kumar</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>13-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivavocanoticeofMBAITM4thsemexamnov24}>Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>06-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofmbahh4thsemexamnov242}>Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>06-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivoVocanotieofMpharm4thsempharmacenov24}>Viva-Voce Notice of M.Pharm 4th Semester (Pharmaceutics), November 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>05-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocaNoticeofMpharm4thsemclinicalResearchNov2024}>Viva- Voce Notice of M.Pharm 4th Semester (Clinical Research), November 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>04-11-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofMpharmpb4thsem8oct24}>Viva-Voce Notice of M.Pharm (Pharmaceutical Biotechnology) 4th Semester on 8th November 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofmpharmpb4thsem5thoct2024}>Viva- Voce Notice of M.Pharm (Pharmaceutical Biotechnology) 4th Semester on 5th November 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVOcacorrigendumforMBAITM4thsemreschedule}>Viva-Voce Corrigendum for MBA-ITM 4th Semester Students (Rescheduled)</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticempharmpharmacognosy4thsemoct24}>Viva-Voce Notice-M. Pharm (Pharmacognosy) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofMBApm4thsemnov242}>Viva-Voce Notice  of MBA-PM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofMPH4thsemexamnov24}>Viva-Voce Notice  of MPH 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocofmbaitm4thsemexamnov242}>Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVocaNticeForexcutiveDrugregsciencenov24}>Viva-Voce Notice for Executive-Drug Regulatory Science , November 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={Vivavocanoticempharmpharmaqualityassurance4thsemoct24}>Viva-Voce Notice- M. Pharm (Pharmaceutical Quality Assurance) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={FeenoticeofDpharm1stsemand2ndyearsup}>Fee Notice for D.Pharm. 1st and 2nd Year Supplemenatry</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVocanoticeofMBAPM4thsemexamnov24}>Viva-Voce Notice  of MBA-PM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVOcanoticeofMBAHHm4thsemexamnov24}>Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeofMBAitm4thsemexamnov24}>Viva-Voce Notice  of MBA-ITM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticeMpharmhospitalpharmcy4thsemoct24}>Viva-Voce Notice- M. Pharm (Hospital Pharmacy) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivavocanoticempharmpharmaceutics4thsemoct24}>Viva-Voce Notice- M. Pharm (Pharmaceutics) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivaVOcanoticempharmpharmacology4thsemoct24}>Viva- Voce Notice- M. Pharm (Pharmacology) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-10-2024</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={VivaVocanoticeofMBAHHMexamNov24}>Viva-Voce Notice  of MBA-HHM 4th Semester Examination, November-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>28-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivaVocanoticeofMBAITM4thsemexamoct24}>Viva-Voce Notice of MBA-ITM 4th Semester Examination, October-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>25-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVocaNotieofMBAHHM4thsemexamoct24}>Viva-Voce Notice  of MBA-HHM 4th Semester Examination, October-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>24-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivaVocaNoticeofMBA4thsemexamoct24}>Viva-Voce Notice of MBA-PM 4th Semester Examination, October-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>24-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={VivaVocaNoticeOfMpharmPA4thsemoct24}>Viva-Voce Notice of M. Pharm (Pharmaceutical Analysis) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-10-2024</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={vivavocaNoticeofMharmcosmeceutics4thsemoct24}>Viva Voce Notice of M. Pharm (Cosmeceutics) 4th Semester, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>16-10-2024</td>
                  </tr>


                  <tr>
                    <td>
                      <a href={mPharmPharmaceuticalCHemistry4thsemoct24}>Viva Voce Notice- M. Pharm (Pharmaceutical Chemistry) 4th Sem, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>16-10-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NoticeOfMpharm1stsemsuppexamformoct24}>Notice of M. Pharm 1st Sem Supple. Exams form, October 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-10-2024</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={MPharmDRAthsemvivavocaexamoct2024}>PM. Pharm (Drug Regulatory Affairs) 4th Semester Viva-Voce Examination, October - 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-10-2024</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={PhdvivavocanotificationfMrPankajkr}>PhD Viva-Voce Notification of Mr. Pankaj Kumar</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>09-10-2024</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={decisionofExaminationDisciplinaryActioncommittee}>Decision of Examination Disciplinary Action Committee</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>27-09-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={noticeofDossertationsubmissionforMBApmitmhhtmandmph4thsem}>Notice of Dissertation Submission for MBA (PM,ITM,HHM) & MPH 4th Semester</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>24-09-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={noticefortheAwAardofPHDdegreetomrashwinikrmishra}>Notification for the Award of Ph.D Degree to Mr. Ashwini Kumar Mishra</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>24-09-2024</td>
                  </tr>


                  <tr>
                    <td>
                      <a href={mscsportssciencevicavocanotice}>M. Sc. Sports Science Viva-Voce Notice</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>23-09-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={noticefortheAwardofPhddegreetomsruhitripathi}>Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>19-09-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={ufmnoticemayjunhul24}>UFM Notice- May, June & July 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-08-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={notificationfortheawardofphddegreetomrpankahmasyuni}>Notification for the Award of Ph.D Degree to Mr. Pankaj Musyuni</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>11-08-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={mptvivavocanew01}>MPT Viva-Voce Notice</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>09-08-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={pdfvivavocanotifiofmrpankajmusyuni}>PhD Viva-Voce Notification of Mr. Pankaj Musyuni</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-07-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={noticeofDIssertationSubmissionforMBAPMITMHHMandmph4thsem}>Notice of Dissertation Submission for MBA (PM,ITM,HHM) & MPH 4th Semester Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>03-07-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={phdvicaVocaNotificationofMrAshwinikMishra}>Ph.D viva-voce Notification of Mr. Ashwini Kumar Mishra</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-06-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={Vivavocanoticeforbbach6thsemstudent}>Viva-Voce Notice for BBA-CH 6th Semester Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>14-06-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={oddsemexamfeenoticesuppjune24}>Odd Semester Examination fee notice (Supplementary) June-2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>04-06-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={feesNoticeForevensemexam}>Fees Notice for Even Semester Examination</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>29-05-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={Ufmmeetiondecision}>UFM Meeting Decision</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>13-05-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={oddSemSuppFormnoticeexam}>Odd Semester Supple. Exam Form Notice 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>03-05-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={examFeeNoticeAprmay24}>Examination Fees Notice Even Semester Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>02-05-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={unfairMeansNoticeofApr2024}>Unfair Means Notice April 2024</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>24-04-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={examformnoticeforprephdstudents}>Examinations Form Notice for Pre. Ph.D. Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-04-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corigendrum06mar24}>CCY Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>05-03-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corigendrmforbpt1stsem}> Corrigendum for BPT 1st Semester Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>23-02-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corigendum8feb24}> Corrigendum for MPT 1st Semester Students</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>08-02-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corigendrum05feb24}> Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>05-02-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={admitcardnoticejan24}> Admit Card Notice</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>19-01-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={corigendrum12jan}> Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>12-01-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={examinationfeenoticejan2024}>
                        {" "}
                        Examination Fees Notice
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>02-01-2024</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={vivaVocaNoticeofMsRuchitriathi}>
                        {" "}
                        PhD Viva-Voce Notification of Ms. Ruchi Tripathi
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>26-12-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={corigendremdec2023}> Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>18-12-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={mpharmvivavocaexam2023}>
                        {" "}
                        Notice for M.Pharm Viva-Voce Examination 2023
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>28-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={phdvivavovahimanshukushwaha}>
                        {" "}
                        PhD Viva-Voce Notification of Ms. Himanshu Kushwah
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>21-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={mscsportsciencevivanotice}>
                        {" "}
                        M. Sc. Sports Science Viva Notice
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>21-11-2023</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={notificationfortheawardofphdDegreeomsbhumik}>
                        {" "}
                        Notification for the Award of Ph.D Degree to Ms. Bhumika
                        Kumar
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>21-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={noticeformptVivaVoca}>
                        {" "}
                        Notice for MPT Viva-voce
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={notificationforawardofPhdDegreetoMrPrateek}>
                        {" "}
                        Notification for the Award of Ph.D Degree to Mr. Prateek
                        Sharma
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={DecisionOfUnfairMeansCommittee}>
                        {" "}
                        Decision of unfair means Committee
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>15-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={supplemetaryExamFeeNotice}>
                        {" "}
                        Supplementary exam fee notice
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>08-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={corigendurum22}> Corrigendum</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>07-11-2023</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={MptVivaVocaNotice}> MPT Viva-Voce Notice</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>07-11-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={phdVivaVocaNoticeBhuika}>
                        {" "}
                        PhD Viva-Voce Notification of Ms. Bhumika Kumar
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>06-11-2023</td>
                  </tr>

                  <tr>
                    {/* <td>→</td> */}
                    <td>
                      <a href={MpharmVivaVocaNotice}>
                        {" "}
                        M.Pharm Viva-Voce Notice
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>06-11-2023</td>
                  </tr>

                  <tr>
                    {/* <td>→</td> */}
                    <td>
                      <a href={corrigendunBpharm}>
                        {" "}
                        Corrigendum (B.Pharm Ayurveda)
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>30-10-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={UFMNotice}> UFM Notice</a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>26-10-2023</td>
                  </tr>
                  <tr>
                    <td>
                      <a href={NotificationPhdDegreeMsNeha}>
                        {" "}
                        Notification for the Award of Ph.D Degree to Ms. Neha
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>25-10-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={NotificationPhdDegreeMsSweta}>
                        {" "}
                        Notification for the Award of Ph.D Degree to Ms. Shweta
                        Paroha
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>25-10-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={annualConvo6th}>
                        {" "}
                        6th Annual Convocation Notice
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>14-10-2023</td>
                  </tr>

                  <tr>
                    {/* <td>1</td> */}
                    <td>
                      <a href={newpdf01}>
                        Ph.D Viva-Voce Notification of Ms. Neha
                      </a>{" "}
                      <img src={newGif} alt="new" />
                    </td>
                    <td>05-10-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf02}>
                        Ph.D Viva-Voce Notification of Mr. Prateek Sharma
                      </a>
                      <img src={newGif} alt="new" />
                    </td>
                    <td>05-10-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf03}>
                        Ph.D Viva-Voce Notification of Ms. Shweta Paroha
                      </a>
                    </td>
                    <td>27-09-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf04}>
                        Notification for the Award of Ph.D Degree to Ms. Meenu
                        Grover Sharma
                      </a>
                    </td>
                    <td>22-09-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf05}>
                        Supplementary Examination Form Notice for WCSC Students
                      </a>
                    </td>
                    <td>13-09-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf06}>Notice ABC ID</a>
                    </td>
                    <td>25-08-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf07}>
                        PhD Viva-Voce Notification of Ms. Meenu Grover Sharma
                      </a>
                    </td>
                    <td>09-08-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf08}>
                        Even Semester Examination Form Notice, 2023
                      </a>
                    </td>
                    <td>02-08-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf09}>Pre-Ph.D Supplementary Fee Notice</a>
                    </td>
                    <td>22-07-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf10}>Unfair Means Notice 2023</a>
                    </td>
                    <td>05-07-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf11}>NOTICE OF EXAMINATION, JUNE 2023</a>
                    </td>
                    <td>23-06-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf12}>Examination Fee Notice 2023</a>
                    </td>
                    <td>31-05-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf13}>Corrigendum of BP-402 T</a>
                    </td>
                    <td>25-04-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf14}>Corrigendum</a>
                    </td>
                    <td>13-04-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf15}>
                        Even Semester Supplementary Examinations Fees Notices
                      </a>
                    </td>
                    <td>23-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf16}>
                        Examination Notice for DMYS Batch 1st &amp; 2nd Students
                      </a>
                    </td>
                    <td>23-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf17}>
                        Notification for the Award of Ph.D. Degree to Ms. Anita
                        kumari
                      </a>
                    </td>
                    <td>22-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf18}>
                        Notification for the Award of Ph.D. Degree to Mr. Mitul
                        Srivastava
                      </a>
                    </td>
                    <td>17-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf19}>
                        Notification for the Award of Ph.D. Degree to Ms. Lovika
                        Mittal
                      </a>
                    </td>
                    <td>03-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf20}>
                        Notification for the Award of Ph.D. Degree to Ms. Rakhi
                        Ahuja
                      </a>
                    </td>
                    <td>03-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf21}>
                        Examination Fees Notice for MPT &amp; M. Sc. Sports
                        Science 3rd Semester Students
                      </a>
                    </td>
                    <td>01-03-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={newpdf22}>
                        Notice for Viva-Voce of Mr. Mitul Srivastava
                      </a>
                    </td>
                    <td>22-02-2023</td>
                  </tr>

                  {/* ************************************************* */}
                  <tr>
                    <td>
                      <a href={ex1}>
                        Examination Fees Notice for 1st Semester Students
                      </a>
                    </td>
                    <td>13-02-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex2}>B.Pharm Course Promotion Rule</a>
                    </td>
                    <td>09-02-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex3}>
                        Ph.D Viva-Voce Notification of Ms. Anita Kumari
                      </a>
                    </td>
                    <td>08-02-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex4}>
                        Examination Fees Notice for Pre. Ph.D. Students
                      </a>
                    </td>
                    <td>03-02-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex5}>
                        Date Sheet of D. Pharm 1st Year Supplementary
                        Examination, Feb-2023
                      </a>
                    </td>
                    <td>27-01-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex6} rel="noreferrer">
                        Ph.D Viva-Voce Notification of Ms. Lovika Mittal
                      </a>
                    </td>
                    <td>25-01-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex7} rel="noreferrer">
                        Examination Fees Notice for Odd Semester Students
                      </a>
                    </td>
                    <td>16-01-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex8} rel="noreferrer">
                        Examination Fees Notice for M. Pharm &amp; D. Pharm
                        Supplementary Students
                      </a>
                    </td>
                    <td>11-01-2023</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex9} rel="noreferrer">
                        Examination Fee Notice for BPT Students
                      </a>
                    </td>
                    <td>23-12-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex10} rel="noreferrer">
                        Ph.D Viva-Voce Notification of Ms. Shilpa Jain
                      </a>
                    </td>
                    <td>21-12-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex11} rel="noreferrer">
                        Examination Fee Notice for WCSC Students
                      </a>
                    </td>
                    <td>14-12-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex12} rel="noreferrer">
                        UFM-Punishment-Rules-2022
                      </a>
                    </td>
                    <td>24-11-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex13} rel="noreferrer">
                        Notice for Last Date of Thesis Submission
                      </a>
                    </td>
                    <td>18-11-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex14} rel="noreferrer">
                        Unfair Means Notice
                      </a>
                    </td>
                    <td>16-11-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex15} rel="noreferrer">
                        Corrigendum of M. Pharm 2nd Semester Theory Examination,
                        October 2022.
                      </a>
                    </td>
                    <td>20-10-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex16} rel="noreferrer">
                        Notification for the Award of Ph.D Degree to Mr.
                        Kushagra Khanna
                      </a>
                    </td>
                    <td>22-09-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex17} rel="noreferrer">
                        Examination Fee Notice, 2nd Semester-2022
                      </a>
                    </td>
                    <td>30-08-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex18} rel="noreferrer">
                        Notice for Ph.D Viva-voce of Mr. kushagra Khanna
                      </a>
                    </td>
                    <td>10-08-2022</td>
                  </tr>

                  <tr>
                    {/* <td>41</td> */}
                    <td>
                      <a href={ex19} rel="noreferrer">
                        Notification for the Award of Ph.D. Degree to Mr.
                        Mrityunjay Singh
                      </a>
                    </td>
                    <td>04-08-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex20} rel="noreferrer">
                        Date Sheet Of D. Pharm 1st Year Annual Examination,
                        August 2022
                      </a>
                    </td>
                    <td>15-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex43} rel="noreferrer">
                        Date Sheet of BPT 8th Semester Theory Examination,
                        August-2022
                      </a>
                    </td>
                    <td>14-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex44} rel="noreferrer">
                        Date Sheet of BPT 8th Semester Practical Examination,
                        July-2022
                      </a>
                    </td>
                    <td>14-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex45} rel="noreferrer">
                        Fee Notice for Supplementary Examination
                      </a>
                    </td>
                    <td>12-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex46} rel="noreferrer">
                        Date Sheet of B. Sc. (Hons.) Sports Sciences 6th
                        Semester Examination, July-2022
                      </a>
                    </td>
                    <td>12-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex47} rel="noreferrer">
                        Date Sheet of B.Pharm 8th Semester Theory Examination
                        July 2022
                      </a>
                    </td>
                    <td>11-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex48} rel="noreferrer">
                        Result of Unfair Means Meeting
                      </a>
                    </td>
                    <td>07-07-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex49} rel="noreferrer">
                        Date Sheet of MPT (Sports Medicine) 1st Semester
                        Practical Examination, June-2022
                      </a>
                    </td>
                    <td>27-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex50} rel="noreferrer">
                        Examination Fee Notice (D. Pharm)
                      </a>
                    </td>
                    <td>23-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex51} rel="noreferrer">
                        Date Sheet of DMYS Trimester III (batch-1) Practical
                        Examination , July 2022
                      </a>
                    </td>
                    <td>20-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex52} rel="noreferrer">
                        Unfair Means (UFM) Meeting Notice
                      </a>
                    </td>
                    <td>17-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex53} rel="noreferrer">
                        Date Sheet of DMYS Trimester III (Batch -1) Theory
                        Examination June 2022
                      </a>
                    </td>
                    <td>16-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex54} rel="noreferrer">
                        Result of Diploma in Medical Lab Technology (DMLT) 1st
                        Semester Examination, April 2022
                      </a>
                    </td>
                    <td>15-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex55} rel="noreferrer">
                        Examination Fees Notice (Even Semester)
                      </a>
                    </td>
                    <td>09-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex56} rel="noreferrer">
                        Date Sheet of M.Pharm 1st Semester Practical Examination
                        SPS, June 2022
                      </a>
                    </td>
                    <td>03-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex57} rel="noreferrer">
                        Date Sheet of M.Pharm 1st Semester Practical Examination
                        DIPSAR, June 2022
                      </a>
                    </td>
                    <td>02-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex58} rel="noreferrer">
                        Date Sheet DMYS 2nd Trimester (batch-2) Practical
                        Examination June 2022
                      </a>
                    </td>
                    <td>02-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex59} rel="noreferrer">
                        Date Sheet of DMYS 1st Trimester (batch 1 &amp; 2)
                        Practical (Supplementary) Examination June 2022
                      </a>
                    </td>
                    <td>02-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex60} rel="noreferrer">
                        Date Sheet of DMYS 2nd Trimester (Batch-II) Theory
                        Examination June – 2022
                      </a>
                    </td>
                    <td>02-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex61} rel="noreferrer">
                        Date Sheet Of DMYS 1st Trimester (Batch 1 &amp; 2)
                        Theory (Supplementary) Examination June 2022
                      </a>
                    </td>
                    <td>02-06-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex62} rel="noreferrer">
                        Date Sheet of B.Pharm (Ayurveda) 1st Semester Theory
                        Examination, June-2022
                      </a>
                    </td>
                    <td>31-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex63} rel="noreferrer">
                        Corrigendum
                      </a>
                    </td>
                    <td>27-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex64} rel="noreferrer">
                        Date Sheet of BPT 1st Semester Practical Examination,
                        June-2022
                      </a>
                    </td>
                    <td>27-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex65} rel="noreferrer">
                        Date Sheet of M. Sc. Sports Sciences 1st Semester
                        Practical Examination, May-2022
                      </a>
                    </td>
                    <td>24-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex66} rel="noreferrer">
                        Date Sheet of M.Pharm 2nd Semester Supplementary
                        Examination DIPSAR, June 2022
                      </a>
                    </td>
                    <td>20-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex67} rel="noreferrer">
                        Date Sheet of D. Pharm 2nd Year Practical Examination ,
                        May 2022
                      </a>
                    </td>
                    <td>20-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex68} rel="noreferrer">
                        Date Sheet of M.Sc. Artificial intelligence &amp;
                        Precision Medicine (Practical) Examination, June-2022
                      </a>
                    </td>
                    <td>20-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex69} rel="noreferrer">
                        Date Sheet of SAYA &amp; MMC 1st Semester Examination,
                        May-2022
                      </a>
                    </td>
                    <td>18-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex70} rel="noreferrer">
                        Date Sheet of M.Sc. AIPM 1st Semester Theory Examination
                        May-2022
                      </a>
                    </td>
                    <td>13-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex71} rel="noreferrer">
                        Date Sheet Of BBA-CH 1st Semester Theory Examination,
                        May-2022
                      </a>
                    </td>
                    <td>13-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex72} rel="noreferrer">
                        Date Sheet of SAYA &amp; MMC 1st Semester Practical
                        Examination, May-2022
                      </a>
                    </td>
                    <td>13-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex73} rel="noreferrer">
                        Date Sheet of MPT (Sports Medicine) 1st Semester Theory
                        Examination, May-2022
                      </a>
                    </td>
                    <td>10-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex74} rel="noreferrer">
                        Date Sheet of M.Pharm 1st Semester Theory Examination
                        DIPSAR, May 2022.
                      </a>
                    </td>
                    <td>06-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex75} rel="noreferrer">
                        Date Sheet of M.Pharm 1st Semester Theory Examination
                        SPS, May 2022.
                      </a>
                    </td>
                    <td>06-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex76} rel="noreferrer">
                        Result of D. Pharm 2nd Year Supplementary Examination
                        April 2022
                      </a>
                    </td>
                    <td>05-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex77} rel="noreferrer">
                        Result of D. Pharm 1st Year Supplementary Examination
                        April 2022
                      </a>
                    </td>
                    <td>05-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex78} rel="noreferrer">
                        Date Sheet of B. Sc. (H.) Sports Science 1st Semester
                        Practical Examination, May-2022
                      </a>
                    </td>
                    <td>04-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex79} rel="noreferrer">
                        Date Sheet of D.Pharm 2nd Year Examination, May 2022
                      </a>
                    </td>
                    <td>02-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a rel="noreferrer">Corrigendum</a>
                    </td>
                    <td>02-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex81} rel="noreferrer">
                        Revised Date Sheet of BPT 1st Semester Theory
                        Examination, May-2022
                      </a>
                    </td>
                    <td>02-05-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex82} rel="noreferrer">
                        Date Sheet of DMLT 1st Semester Practical Examination,
                        May-2022
                      </a>
                    </td>
                    <td>28-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex83} rel="noreferrer">
                        Date Sheet of BMLT 1st Semester Practical Examination,
                        May – 2022
                      </a>
                    </td>
                    <td>28-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex84} rel="noreferrer">
                        Date Sheet of BPT 1st Semester Theory Examination,
                        May-2022
                      </a>
                    </td>
                    <td>27-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex85} rel="noreferrer">
                        Date Sheet of B.Pharm 1st Semester Practical Examination
                        (SPS) May 2022.
                      </a>
                    </td>
                    <td>22-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex86} rel="noreferrer">
                        Date Sheet of B.Pharm 1st Semester Practical Examination
                        DIPSAR, May 2022
                      </a>
                    </td>
                    <td>22-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex87} rel="noreferrer">
                        Date Sheet of B.Sc. (Hons.) Sports Sciences 1st Semester
                        Theory Examination, May-2022
                      </a>
                    </td>
                    <td>22-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex88} rel="noreferrer">
                        Date Sheet of M.Sc. Sports Sciences 1st Semester Theory
                        Examination, May-2022
                      </a>
                    </td>
                    <td>22-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex89} rel="noreferrer">
                        DMYS Trimester -II (Batch -I) Result March 2022
                      </a>
                    </td>
                    <td>13-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex90} rel="noreferrer">
                        Date Sheet of BMLT 1st Semester, Theory Examination,
                        April 2022
                      </a>
                    </td>
                    <td>07-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex91} rel="noreferrer">
                        Date Sheet of DMLT 1st Semester Theory Examination,
                        April 2022
                      </a>
                    </td>
                    <td>07-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex92} rel="noreferrer">
                        Result of Mr. Azad Saifi D.Pharm 1st Year Supplementary
                        Examination, 2021
                      </a>
                    </td>
                    <td>07-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex93} rel="noreferrer">
                        Date Sheet of B. Pharm 7th Semester Theory Exam (Non-
                        University), April-2022
                      </a>
                    </td>
                    <td>05-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex94} rel="noreferrer">
                        Datesheet D.Pharm 1st year Suppl. Examination
                      </a>
                    </td>
                    <td>01-04-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex95} rel="noreferrer">
                        Fee Notice for 1st Semester Odd Semester Examination,
                        May 2022
                      </a>
                    </td>
                    <td>31-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex96} rel="noreferrer">
                        Fee Notice for D.Pharm 2nd Year Annual Examination,
                        April 2022
                      </a>
                    </td>
                    <td>31-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex97} rel="noreferrer">
                        Date Sheet of MBA PET, MBA PM, MHA and MPH Examinations
                        April 2022
                      </a>
                    </td>
                    <td>31-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex98} rel="noreferrer">
                        Revised Date Sheet of B. Pharm 1st Semester Theory
                        Examination, April 2022
                      </a>
                    </td>
                    <td>31-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex99} rel="noreferrer">
                        Date Sheet of D.Pharm 1st Year &amp; 2nd Year
                        Supplementary Examination, April- 2022
                      </a>
                    </td>
                    <td>28-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex100} rel="noreferrer">
                        Examination Fee Notice
                      </a>
                    </td>
                    <td>23-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex101} rel="noreferrer">
                        Revised Date Sheet of B. Pharm 3rd and 5th Semester
                        Theory Examination – March 2022
                      </a>
                    </td>
                    <td>17-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex102} rel="noreferrer">
                        Supplementary Exam Fee Notice for D. Pharm Students
                      </a>
                    </td>
                    <td>11-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex103} rel="noreferrer">
                        Notification for the Award of Ph.D. Degree to Mr. Nitin
                        Yadav
                      </a>
                    </td>
                    <td>10-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex104} rel="noreferrer">
                        Date sheet of B.Pharm 3rd &amp; 5th Semester Theory
                        Examination, March-2022
                      </a>
                    </td>
                    <td>02-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex105} rel="noreferrer">
                        Date Sheet of BPT 3rd, 5th &amp; 7th Semester Theory
                        Examination, March-2022
                      </a>
                    </td>
                    <td>02-03-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex106} rel="noreferrer">
                        Date Sheet of DMYS 2nd Trimester (Batch-I) Practical
                        Examination March – 2022
                      </a>
                    </td>
                    <td>28-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex107} rel="noreferrer">
                        Date Sheet of DMYS 2nd Trimester (Batch-I) Theory
                        Examination March – 2022
                      </a>
                    </td>
                    <td>28-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex108} rel="noreferrer">
                        Date Sheet of BPT 3rd, 5th &amp; 7th Semester Practical
                        Examination, March-2022
                      </a>
                    </td>
                    <td>28-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex109} rel="noreferrer">
                        Date Sheet of B.Pharm 5th Semester Practical Examination
                        DPSRU, March 2022
                      </a>
                    </td>
                    <td>24-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex110} rel="noreferrer">
                        Date Sheet of B.Pharm 5th Semester Practical Examination
                        DIPSAR, March 2022
                      </a>
                    </td>
                    <td>24-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex111} rel="noreferrer">
                        Date Sheet of B.Pharm 3rd Semester Practical Examination
                        DPSRU, March 2022
                      </a>
                    </td>
                    <td>24-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex112} rel="noreferrer">
                        Date Sheet of B.Pharm 3rd Semester Practical Examintion
                        DIPSAR, March 2022
                      </a>
                    </td>
                    <td>24-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex113} rel="noreferrer">
                        Date Sheet of B.Pharm 7th Semester Practical
                        Examination, March 2022
                      </a>
                    </td>
                    <td>23-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex114} rel="noreferrer">
                        Date Sheet of B.Pharm 7th Semester Theory Examination,
                        March 2022
                      </a>
                    </td>
                    <td>23-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex115} rel="noreferrer">
                        Date Sheet of B.Sc. (Hons.) Sports Sciences 3rd &amp;
                        5th Semester Practical Examination, March-2022
                      </a>
                    </td>
                    <td>23-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex116} rel="noreferrer">
                        Date Sheet of B.Sc.(Hons.) Sports Science 3rd &amp; 5th
                        Semester Theory Examination, March-2022
                      </a>
                    </td>
                    <td>21-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex117} rel="noreferrer">
                        Date Sheet of D. Pharm 1st Year Annual “Special
                        Practical Examination”, February-2022
                      </a>
                    </td>
                    <td>21-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex118} rel="noreferrer">
                        Notification for the Award of Ph.D Degree to Ms.
                        Anubhuti Pasrija
                      </a>
                    </td>
                    <td>21-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex119} rel="noreferrer">
                        Instructions for Online Examination for Allied Health
                        Science 3rd Semester Students
                      </a>
                    </td>
                    <td>17-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex120} rel="noreferrer">
                        Notification for the Award of Ph.D. Degree to Mr. Pankaj
                        Kumar Sharma
                      </a>
                    </td>
                    <td>14-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex121} rel="noreferrer">
                        Notification for the Award of Ph.D. Degree to Mr.
                        Mahendra Yadav
                      </a>
                    </td>
                    <td>14-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex122} rel="noreferrer">
                        Date Sheet of MBA/MHM/MPH 3rd Semester Theory
                        Examination, February-2022
                      </a>
                    </td>
                    <td>07-02-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex123} rel="noreferrer">
                        Notice for uploading Ph.D Thesis on “Shodh Ganga”
                      </a>
                    </td>
                    <td>28-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex124} rel="noreferrer">
                        Notice of Registration &amp; Fees for Odd Semester
                        Examination
                      </a>
                    </td>
                    <td>28-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex125} rel="noreferrer">
                        Ph.D Viva-Voce Notification of Ms. Anubhuti Pasrija
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex126} rel="noreferrer">
                        Date Sheet of BPT 7th Semester Supple. Practical Exam.
                        January 2022
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex127} rel="noreferrer">
                        Ph.D Viva-Voce Notification of Mr. Mahendra Yadav
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex128} rel="noreferrer">
                        Date Sheet of M.Pharm 1st Semester Supple. Exam. January
                        2022
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex129} rel="noreferrer">
                        Ph.D Viva-Voce Notification of Ms. Reena Badhwar
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex130} rel="noreferrer">
                        Instructions for Online Examination for BPT 1st, 3rd,
                        5th &amp; 7th Semester Supplementary Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex131} rel="noreferrer">
                        Date Sheet of BPT 7th Semester Supplementary Exams,
                        January-2022
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex132} rel="noreferrer">
                        Date Sheet of BPT 1st, 3rd &amp; 5th Semester
                        Supplementary Exams, January-2022
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex133} rel="noreferrer">
                        Instruction for Online Examination for B. Pharm 1st,
                        3rd, &amp; 5th Semester Supplementary Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex134} rel="noreferrer">
                        Notice for Online Supplementary Exam
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex135} rel="noreferrer">
                        Date Sheet of B.Pharm 1st, 3rd &amp; 5th Semester
                        Supplementary Exam. January 2022
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex136} rel="noreferrer">
                        Revised Date Sheet of B. Pharm 7th Semester
                        Supplementary Examination, November-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex137} rel="noreferrer">
                        B.Pharm 7th Semester Supplementary Date sheet November
                        2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex138} rel="noreferrer">
                        Decision of Unfair Means meeting held on 8th November
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex139} rel="noreferrer">
                        Notice for Supplementary Exam DMYS 1st Trimester
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex140} rel="noreferrer">
                        Fee Notice for supplementary Exam
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex141} rel="noreferrer">
                        Date Sheet of DMYS 1st Trimester ( Batch-II) Practical
                        Exam November 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex142} rel="noreferrer">
                        Date Sheet of DMYS 1st Trimester ( Batch – II) Theory
                        Exam November-2021 1
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex143} rel="noreferrer">
                        Unfair Means (UFM) Meeting Notice
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex144} rel="noreferrer">
                        Date Sheet D.Pharm 1st Year Practical October 2021
                        (DIPSAR)
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex145} rel="noreferrer">
                        Date Sheet of B. Pharm 2nd Semester Practical
                        Examination (DPSRU), October-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex146} rel="noreferrer">
                        Date Sheet of B. Pharm 2nd Semester Practical
                        Examination (DIPSAR), October-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex147} rel="noreferrer">
                        Date Sheet of M.PHARM 2nd Semester Practical Examination
                        October DPSRU 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex148} rel="noreferrer">
                        Date Sheet of M.PHARM 2nd Semester Practical Examination
                        October DIPSAR 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex149} rel="noreferrer">
                        Date Sheet of MBA MPH MHM Practical Examination October
                        2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex150} rel="noreferrer">
                        Date Sheet of B. Sc. (H) Sports Science 2nd Semester
                        Practical Examination, October-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex151} rel="noreferrer">
                        Date Sheet of BPT 2nd Semester Practical Examination,
                        October-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex152} rel="noreferrer">
                        Revised Date Sheet of Pre. PhD Practical Examination
                        Sep-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex153} rel="noreferrer">
                        Date Sheet of D.Pharm 1st Semester Theory Examination
                        DIPSAR September, 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex154} rel="noreferrer">
                        Date Sheet of B.Pharm 2nd Semester Theory Exam DIPSAR
                        &amp; DPSRU September 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex155} rel="noreferrer">
                        Date Sheet of M.Pharm 2nd Semester Theory Exam DPSRU
                        September 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex156} rel="noreferrer">
                        Date Sheet of M.Pharm 2nd Semester Theory Exam DIPSAR
                        September 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex157} rel="noreferrer">
                        Instruction for Online Examination for B. Sc. Sports
                        Science 2nd Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex158} rel="noreferrer">
                        Instruction for Online Examination for MBA/MHM &amp; MPH
                        2nd Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex159} rel="noreferrer">
                        Instruction for Online Examination for Pre. PhD Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex160} rel="noreferrer">
                        Date Sheet of BPT 2nd Semester Theory Examination,
                        September-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex161} rel="noreferrer">
                        Date Sheet of BPT 4th, 6th &amp; 8th Semester Practical
                        Examination, September-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex162} rel="noreferrer">
                        Date Sheet of MBA/MHM &amp; MPH 2nd Semester Theory
                        Examination, September-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex163} rel="noreferrer">
                        Date Sheet of Pre. PhD Theory Examination,
                        Spetember-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex164} rel="noreferrer">
                        Date Sheet of B. Sc.(Hons.) Sports Science 2nd Semester
                        Theory Examination, September-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex165} rel="noreferrer">
                        Date Sheet of DMYS 1st Trimester Practical Examination
                        September-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex166} rel="noreferrer">
                        Date Sheet of DMYS 1st Trimester Theory Examination
                        September – 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex167} rel="noreferrer">
                        Exam Notification for D. Pharm 1st Year, UG/PG 2nd
                        Semester &amp; Pre. Ph.D.
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex168} rel="noreferrer">
                        Exam Notification for BPT 2nd Semester
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex169} rel="noreferrer">
                        Date Sheet of B.Pharm 4th Semester Lateral Entry
                        (Non-University Exam) Sep-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex170} rel="noreferrer">
                        Date Sheet of B. Sc. (H) Sports Science 4th Semester
                        Practical Examination, Aug-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex171} rel="noreferrer">
                        Corrigendum
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex172} rel="noreferrer">
                        Corrigendum
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex173} rel="noreferrer">
                        Instruction for Online Examination for B. Sc. Sports
                        Science 4th Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex174} rel="noreferrer">
                        Instruction for Online Examination for B.Pharm 4th &amp;
                        6th Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex175} rel="noreferrer">
                        Revised Date Sheet of BPT 4th, 6th &amp; 8th Semester
                        Theory Examination, August 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex176} rel="noreferrer">
                        Date Sheet of BPT 4th, 6th &amp; 8th Semester Theory
                        Examination, August 2021 `
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex177} rel="noreferrer">
                        Date Sheet of B.Pharm 4th &amp; 6th Semester Theory
                        Examination, August 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex178} rel="noreferrer">
                        Date Sheet of B.Sc (Hons.) Sport Science 4th Semester
                        Theory Exam, August 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex179} rel="noreferrer">
                        Exam Fee Notice Even Semester Exam August 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex180} rel="noreferrer">
                        Date Sheet of B.Sc. (H) Sports Science 6th Semester
                        Practical Examination, July 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a
                        href="https://assessment.examonline.in/MockTest/Exam/"
                        rel="noreferrer"
                      >
                        Mock drill for 6th Semester(B.Sc. (H) Sports Science)
                        &amp; 8th Semester (B. Pharm) Online Examination
                        (Candidate credentials: 1000001 / 55FPHDG8)
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex182} rel="noreferrer">
                        B.Sc (Hons.) Sports Science 6th Semester Instructions
                        for Online Exam July 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex183} rel="noreferrer">
                        B.Pharm 8th Semester Instructions for Online Exam July
                        2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex184} rel="noreferrer">
                        Revised Date Sheet of B.Pharm 8th Semester Theory
                        Examination July-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex185} rel="noreferrer">
                        Date Sheet of B.Sc (H) Sports Science 6th Semester
                        Theory Examination July-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex186} rel="noreferrer">
                        Date Sheet of D.Pharm 2nd Year Practical Examination
                        May-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex187} rel="noreferrer">
                        Date Sheet of B. Pharm (DIPSAR) 1st Semester Practical
                        Examination May-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex188} rel="noreferrer">
                        Date Sheet of B. Pharm (DPSRU) 1st Semester Practical
                        Examination May-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex189} rel="noreferrer">
                        Date Sheet of M. Pharm (DIPSAR) 1st Semester Practical
                        Examination May-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex190} rel="noreferrer">
                        Date Sheet of B.Sc.(Hons.) Sports Science 1st Semester
                        Practical Examination April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex191} rel="noreferrer">
                        Instruction for Online Examination for D. Pharm 2nd Year
                        Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex192} rel="noreferrer">
                        Date Sheet of D. Pharm 2nd Year Examination April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex193} rel="noreferrer">
                        Instruction for Online Examination for BPT 1st Semester
                        Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex194} rel="noreferrer">
                        Process Manual for Online Examination
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a
                        href="https://assessment.examonline.in/MockTest/Exam/"
                        rel="noreferrer"
                      >
                        Mock drill for Online Examination ( Candidate
                        Credentials: 1000001 / 55FPHDG8)
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex196} rel="noreferrer">
                        Instruction for Online Examination for B.Sc. (H.) Sports
                        Sciences 1st Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex197} rel="noreferrer">
                        Instruction for Online Examination for MBA/MHM/MPH 1st
                        Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex198} rel="noreferrer">
                        Instruction for Online Examination for M.Pharm 1st
                        Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>
                      <a href={ex199} rel="noreferrer">
                        Instruction for Online Examination for B.Pharm 1st
                        Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    {/* <td>200</td> */}
                    <td>
                      <a href={ex200} rel="noreferrer">
                        Date Sheet of M.Pharm 1st &amp; 2nd Semester
                        Supplementary Practical Exam April 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  {/* <tr>
                    <td>179</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-BPT-1st-Semester-Theory-Exam-April-2021-1.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of BPT 1st Semester Theory Exam April 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>180</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Corrigendum-for-Odd-Semester-Main-Exams-April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Corrigendum for Odd Semeser Main Exams April 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>181</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Notice-for-Marksheet-Degree-Correction-Fee.pdf"
                        
                        rel="noreferrer"
                      >
                        Notice for Marksheet &amp; Degree Correction Fee
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>182</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Sc_.Hons_.-Sports-Science-1st-Semester-Examination-April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Sc.(Hons.) Sports Science 1st Semester
                        Examination April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>183</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Revised-Date-Sheet-of-B.Pharm-2nd-4th-6th-Semester-Supple.-Exam-March-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Revised Date Sheet of B.Pharm 2nd, 4th &amp; 6th
                        Semester Supple. Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>184</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-M.PharmDPSRU-1st-Semester-Examination-April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of M.Pharm(DPSRU) 1st Semester Examination
                        April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>185</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-M.PharmDIPSAR-1st-Semester-April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of M.Pharm(DIPSAR) 1st Semester Examination
                        April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>186</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date_Sheet_of_MBA_MHM__MPH_1st_Semester_April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of MBA/MHM &amp; MPH 1st Semester Examination
                        April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>187</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Pharm-1st-Semester-April-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Pharm 1st Semester Examination
                        April-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>188</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-M.Pharm-2nd-Semester-Theory-Supple.-Exam-March-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of M.Pharm 2nd Semester Supple. Exam March
                        2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>189</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-BPT-2nd-Semester-Supple.-Exam-March-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of BPT 2nd Semester Supple. Examination
                        March-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>190</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Pharm-2nd4th-6th-Semester-Supple.-Exam-March-2021-1.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Pharm 2nd, 4th &amp; 6th Semester
                        Supple. Examination March-2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>191</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/UFM-Meeting.pdf"
                        
                        rel="noreferrer"
                      >
                        Unfair Means (UFM) Meeting Notice
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>192</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Fees-Notice-for-D.Phrm-2nd-Year-Students.pdf"
                        
                        rel="noreferrer"
                      >
                        Exam Fees Notice for D.Pharm 2nd Year Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>193</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Pharm-DIPSAR-DPSRU-7th-Semester-Practicl-Examination.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Pharm (DIPSAR &amp; DPSRU) 7th Semester
                        Practical Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>194</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Pharm-DIPSAR-DPSRU-5th-Semester-Practical-Examination.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Pharm (DIPSAR &amp; DPSRU) 5th Semester
                        Practical Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>195</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Pharm-DIPSAR-DPSRU-3rd-Semester-Practical-Examination.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Pharm (DIPSAR &amp; DPSRU) 3rd Semester
                        Practical Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>196</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Sc-Hons.-Sports-Science-3rd-5th-Semester-Practical-Examination-March-2021-1.pdf"
                        
                        rel="noreferrer"
                      >
                        Exam Fees Notice for 1st Semester Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>197</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-B.Sc-Hons.-Sports-Science-3rd-5th-Semester-Practical-Examination-March-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of B.Sc. (Hons.) Sports Science 3rd &amp; 5th
                        Semester Practical Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>198</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Date-Sheet-of-BPT-3rd5th-7th-Semester-Practical-Examination-March-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Date Sheet of BPT 3rd, 5th, &amp; 7th Semester Practical
                        Examination March 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>199</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2022/01/Pre-PhD-Admission-Notice-2021.pdf"
                        
                        rel="noreferrer"
                      >
                        Pre-PhD Admission Notice 2021
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr>

                  <tr>
                    <td>200</td>
                    <td>
                      <a
                        href="https://dpsru.edu.in/wp-content/uploads/2021/03/Fee-Notice-For-Even-Semester-Supplementery-Students.pdf"
                        
                        rel="noreferrer"
                      >
                        Exam Fee Notice for Even Semester Supplementary Students
                      </a>
                    </td>
                    <td>21-01-2022</td>
                  </tr> */}
                </tbody>
              </table>
              {/* table */}
            </div>
          </div>
        ) : (
          <button
            className="examination-notification-btn-open"
            onClick={() => setIsNotificationShown(!isNotificationShown)}
          >
            Recent Notification{" "}
            <FaArrowAltCircleDown
              style={{ margin: "-11px", marginLeft: "20px" }}
            />
          </button>
        )}
      </div>
    </div>
  );
}
export default Examination;
