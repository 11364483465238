import React from "react";
import "./Nss.css";

import newGif from "../NEW/new.gif";

import nss01 from "./NSS files/Report.pdf";
import nss05 from "./NSS files/Report-Unity-Day-2022.pdf";
import nss06 from "./NSS files/Report-NSS-ACTIVITY23-6-2022-1.pdf";
import nss07 from "./NSS files/World-Malaria-Day-2022.pdf";
import nss08 from "./NSS files/World-Malaria-Day-2022.pdf";
import nss09 from "./NSS files/NSS-Application-Form-DPSRU.pdf";
import NSSEventreport from "./NSS files/NSS Event report - Copy.pdf";
import awarenessProgramOnDrugAbouse from "./NSS files/Awareness Program on Prevention of Drug Abuse.pdf";
import { Link } from "react-router-dom";

import pressCutting01 from './NSS files/press cutting 1.png'
import pressCutting02 from './NSS files/press cutting 2.png'
import pressCutting03 from './NSS files/press cutting 3.png'

function Nss() {
  return (
    <div className="dpsru-every-component-css">
      <h4 className="dpsru-primary-heading-for-every-page">NSS</h4>
      <div className=" inner-container">
        <ul>
          <li>
            सुप्रसिद्ध समाजसेवी के एल बग्गा एवं सुप्रसिद्ध हिंदी सेवी अमर नाथ
            शास्त्री रनिंग ट्रॉफी जीत कर एन एल यू भोपाल एवं दीपसरु से दिव्या
            सैमुअल एवं पारुल शर्मा ने रचा इतिहास {" "}
            <a href="https://samacharvarta.com/%E0%A4%B8%E0%A5%81%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%B8%E0%A4%BF%E0%A4%A6%E0%A5%8D%E0%A4%A7-%E0%A4%B8%E0%A4%AE%E0%A4%BE%E0%A4%9C%E0%A4%B8%E0%A5%87%E0%A4%B5%E0%A5%80-%E0%A4%95%E0%A5%87-%E0%A4%8F/">
              Link 1
            </a> 
            
            {" "}
            <a href="https://tinyurl.com/2fa43jck">
              Link 2
            </a>

            {" "}
            <a href={pressCutting01}>
              Link 3
            </a>

            {" "}
            <a href={pressCutting02}>
              Link 4
            </a>

            {" "}
            <a href={pressCutting03}>
              Link 5
            </a>


            
            <img src={newGif} alt="new" />
          </li>

          <li>
            <Link to="/sayNoToDrugs"> NSS Event - SAY NO TO DRUGS</Link>
            <img src={newGif} alt="new" />
          </li>

          <li>
            <a href={awarenessProgramOnDrugAbouse}>
              {" "}
              Awareness Program on Prevention of Drug Abuse
            </a>
            <img src={newGif} alt="new" />
          </li>
          <li>
            <a href={NSSEventreport}> NSS Event report</a>
            <img src={newGif} alt="new" />
          </li>
          <li>
            <a href={nss01}> PUSHP PRADARSHNAM 2023 </a>
            <img src={newGif} alt="new" />
          </li>
          <li>
            <a href={nss01}> HAR DIN HAR GHAR AYURVED</a>
          </li>
          <li>
            <a href={nss01}> NSS Registration Drive for DPSRU Students</a>
          </li>

          <li>
            <a href={nss01}> NSS Awareness Camp Report – November 2022 </a>
          </li>
          <li>
            <a href={nss05}>National Unity Day 2022 Report</a>
          </li>
          <li>
            <a href={nss06}>World Malaria Day 2022 Report</a>
          </li>
          <li>
            <a href={nss07}>
              {" "}
              World Malaria Day 2022 – Relevant Information and Schedule{" "}
            </a>
          </li>
          <li>
            <a href={nss08}>
              {" "}
              Notice: NSS Registration Drive for DPSRU Students{" "}
            </a>
          </li>
          <li>
            <a href={nss09}>
              Application form for Registration of NSS Volunteers
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Nss;
