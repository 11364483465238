import React, { useState } from "react";

import profimg from "./sir-1-e1688122317839.jpg";

const DrSaurabhDahiya = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Saurabh Dahiya</h2>
      <h3 className="facult-designation-for-each-container">
        Associate Professor | Department of Pharmaceutical Chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              saurabhdahiya [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.com/citations?hl=en&user=5jZfmesAAAAJ&view_op=list_works&sortby=pubdate">
                Google Scholar{" "}
              </a>
            </li>
            <li>Scopus</li>
            <li>ORCID URL</li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M.Pharm, Ph.D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Analytical assays, validation, synthesis and development of target
        specific dosage forms including radiopharmaceuticals, analytical
        profiling of pharmaceutical formulations, and clinical research on
        life-style diseases.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>DrSaurabhDahiyaProjectDrSaurabhDahiya</p>
                </td>
                <td>
                  <p>DrSaurabhDahiyaGrantDrSaurabhDahiya</p>
                </td>
                <td>
                  <p>DrSaurabhDahiyaStatusDrSaurabhDahiya</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    DrSaurabhDahiyaAICTE ATAL FDP on DRUG
                    ENGINEERINGDrSaurabhDahiya
                  </p>
                </td>
                <td>
                  <p>
                    DrSaurabhDahiyaF. No. 01/AICTE/ATAL- HQ/2021- 22/1614458191
                    dated 13.05.2021 (Application No. 1614458191) Saurabh
                    Dahiya,DrSaurabhDahiya
                  </p>
                  <p>DrSaurabhDahiyaDIPSARDrSaurabhDahiya</p>
                </td>
                <td>
                  <p>DrSaurabhDahiyaCOMPLETEDDrSaurabhDahiya</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <div className="inside-container-text-of-faculty">
            {" "}
            Chair- Person, of Scientific lecture session at 4th Indo Swiss
            Virtual International Conference on Current Scenario and Challenges
            in Pharmacy Practice & Pharmaceutical Sciences organized at Vijaya
            Institute of Pharmaceutical Sciences for Women, Vijayawada, Krishna,
            Andhra Pradesh in the commemoration of World Hypertension Day 2022
            during 20th to 21st May, 2022. <br />
            <br />
            Invited Talk, on Designing Education System in India with New
            Education Policy 2020 at Shree Ram Murti Samarak College of
            Engineering and Technology (Pharmacy), Bareilly, U.P. on 23rd April,
            2022.
            <br />
            <br />
            Invited Speaker, at Indian Pharmaceutical Association Delhi State
            Branch-SRMS MoU ceremony to deliver a talk on Career Avenues in
            Pharmacy: How to stand out in a Competitive Environment at Shree Ram
            Murti Samarak College of Engineering and Technology (Pharmacy),
            Bareilly, U.P. on 26th February, 2022.
            <br />
            <br />
            Chair, Scientific Oral Session during Indo- Caribbean Virtual
            International Symposium organized by APP Hyderabad and Trichy in
            commemoration of International Day of Women & Girls in Science on
            12th February, 2022.
            <br />
            <br />
            Resource Person, at 15th APP Indo-US virtual conference organized at
            Arihant School of Pharmacy and Bioresearch, Gandhinagar, Gujarat on
            the theme Global Advances in Pharmaceutical and Allied Science on
            the occasion of World AIDS Day during 1st to 2nd December, 2021.
            <br />
            <br />
            Eminent Speaker, at Maharaja Agrasen University, District Solan,
            Himachal Pradesh in four weeks online Short-Term Training Programme
            on Drug Store Management from 26th July to 20th August, 2021.
            <br />
            <br />
            Member, Organizing Committee XXXII QIP, AICTE Quality Improvement
            Program (QIP) conducted by DIPSAR during September 14-21, 2020.
            <br />
            <br />
            Member, Organizing Committee XXXIV QIP, AICTE Quality Improvement
            Program (QIP) conducted by DIPSAR during September 30- October 08,
            2020.
            <br />
            <br />
            Chairperson, Lecture Session XXXV QIP, AICTE Quality Improvement
            Program (QIP) conducted by DIPSAR during October 09-16, 2020.
            <br />
            <br />
            Member, Organizing Committee XXXV QIP, AICTE Quality Improvement
            Program (QIP) conducted by DIPSAR during October 09-16, 2020.
            <br />
            <br />
            Convener, 2nd Faculty Induction and Development Program (FIDP)
            conducted by DPSRU for newly inducted faculty members during August
            21-27, 2020.
            <br />
            <br />
            Coordinator, Scientific Oral Presentations, International Conference
            of Cardiovascular Sciences 2020 (ICCS20200) at DPSRU during February
            21-23, 2020.
            <br />
            <br />
            Member, Scientific Committee, International Conference of
            Cardiovascular Sciences 2020 (ICCS20200) at DPSRU during February
            21-23, 2020.
            <br />
            <br />
          </div>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      {/* <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button> */}

      {/* {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>APTI President, Delhi State Branch of APTI 2008-11,2022-27</li>
            <li>
              As APTI President conducted Northern Zone conference for
              Development of Herbal Monograph.in collaboration with NMPB 2009.
            </li>
            <li>Best Professor award 2019 by Debang Meheta Foundation</li>
            <li>Executive committee Member IPA Delhi</li>
            <li>Member BOG DPSRU and MemberGeneral Council DPSRU</li>
            <li>Member BOG Sanskar Group Inst, Member BoG ABESIT</li>
          </ul>
        </div>
      ) : null} */}
    </div>
  );
};

export default DrSaurabhDahiya;
