import React from 'react'
import './RtiAct.css'

import { Link } from 'react-router-dom'

import rti1 from './Rti Act data/rti1.pdf'
import rti2 from './Rti Act data/rti2.pdf'
import rti4 from './Rti Act data/rti4.pdf'
import rti5 from './Rti Act data/rti5.pdf'
import listofEmplyeesofDPSRUpaystructure from './Rti Act data/List of employees of DPSRU pay structure.pdf'
import rti6 from './Rti Act data/rti6.pdf'
// import rti9 from './Rti Act data/rti9.pdf' old annual account
import annualReport2324 from './Rti Act data/Annual report 2023-24-compressed.pdf'
// import rti10 from './Rti Act data/rti10.pdf'
import purchaseOrder2324 from './Rti Act data/Purchase Order 2023-24.pdf'
import timelimitDecisions from './Rti Act data/Timelimitdecisions.pdf'
import transferOrOfficeOrder from '../../Nav/Transfer Order.pdf'
import programmestoadvanceUnderstandingofRTI from './Rti Act data/PROGRAMMES TO ADVANCE UNDERSTANDING OF RTI.pdf'
import listofEmployeesofDpsrujan2024 from './Rti Act data/List of employees of DPSRU January 2024.pdf'
import partiamentary6 from './Rti Act data/parliamentary.pdf'
import rti2324data from './Rti Act data/RTI 2023-24.pdf'
import annualdetailsoffirstapealofDpsrufrom2324 from './Rti Act data/Annual details of first appeal of DPSRU from 2023–24.pdf'
import rtiOfficeOrderNew from './Rti Act data/Rti Act office order New.jpeg'
function RtiAct() {
    return (
        <div className='dpsru-every-component-css'>

            <h2 className="dpsru-primary-heading-for-every-page">
                Right To Information Act
            </h2>

            <div className="rti-act-data-container">
                <ul>
                    <li>
                        <a href={rti1}>RTI Act in English</a>
                    </li>
                    <li>
                        <a href={rti2}>RTI Act in Hindi</a>
                    </li>
                    <li>
                        <a href="https://cic.gov.in/rti-notifications">RTI Notification</a>
                    </li>
                    {/* <li>
                        <a href={transferOrOfficeOrder}>Office Order</a>
                    </li> */}
                    <li>
                        <a href={rtiOfficeOrderNew}>Office Order</a>
                    </li>
                    <li>
                        <a href={listofEmployeesofDpsrujan2024}>Pay Structure of Faculty January 2024</a>
                    </li>
                    <li>
                        <a href={partiamentary6}>
                            Replies to questions asked in the parliament</a>
                    </li>
                    <li>
                        <a href={purchaseOrder2324}>Purchase Report</a>
                    </li>
                    <li>
                        <Link to='/domesticVisit'>Domestic Visit Details</Link>
                    </li>
                    <li>
                        <a href={annualReport2324}>Annual Account</a>
                    </li>
                    {/* <li>
                        <a href={rti10}>Office Order – Transparency Officer</a>
                    </li> */}
                    <li>
                        <a href={timelimitDecisions}>Time Limit</a>
                    </li>
                    <li>
                        <a href={programmestoadvanceUnderstandingofRTI}>PROGRAMMES TO ADVANCE UNDERSTANDING OF RTI</a>
                    </li>
                    <li>
                        <a href={rti2324data}>Annual data of RTI from 2023–24</a>
                    </li>
                    <li>
                        <a href={annualdetailsoffirstapealofDpsrufrom2324}>Annual details of first appeal of DPSRU from 2023–24</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default RtiAct