import React from 'react'
import './TakeATour.css'
import './ResponsiveTakeATour.css'
import Iframe from 'react-iframe'
import Carousel from 'nuka-carousel';

import pic1 from './Home Gallery/pic1.jpg'
// import pic2 from './Home Gallery/pic2.jpg'
import pic3 from './Home Gallery/pic3.jpg'
// import pic4 from './Home Gallery/pic4.jpg'
import pic5 from './Home Gallery/pic5.jpg'
// import pic6 from './Home Gallery/pic6.jpg'
import pic7 from './Home Gallery/pic7.jpg'
import pic8 from './Home Gallery/pic8.jpeg'
import pic9 from './Home Gallery/pic9.jpeg'
import pic10 from './Home Gallery/pic10.jpeg'
import pic11 from './Home Gallery/pic11.jpeg'
// import pic12 from './Home Gallery/pic12.jpg'
import profvRavisir1 from './Home Gallery/prof v ravichadiran 1 edited.jpeg'
import profvRavisir2 from './Home Gallery/prof v ravichadiran 2 edited.jpeg'

// import internationConf1 from './Home Gallery/internation conf img 2024 1.jpeg'
import internationConf2 from './Home Gallery/internation conf img 2024 1.jpg'

import republicday1 from './Home Gallery/IMG_1762.JPG'
import republicday2 from './Home Gallery/IMG_1805.JPG'
import republicday3 from './Home Gallery/IMG_1814.JPG'
import republicday4 from './Home Gallery/IMG_2065.JPG'

import aswaFest01 from './Home Gallery/AswaFest01.png'
import aswaFest02 from './Home Gallery/AswaFest02.jpg'
import aswaFest03 from './Home Gallery/AswaFest03.jpg'
import aswaFest04 from './Home Gallery/AswaFest04.jpg'

import convo01 from './Home Gallery/7th Convocation/convo 01.jpg'
import convo02 from './Home Gallery/7th Convocation/convo 02.jpg'
import convo002 from './Home Gallery/7th Convocation/convo 002.jpg'
import convo03 from './Home Gallery/7th Convocation/convo 03.jpg'
import convo04 from './Home Gallery/7th Convocation/convo 04.jpg'
import convo05 from './Home Gallery/7th Convocation/convo 05.jpg'
import convo06 from './Home Gallery/7th Convocation/convo 06.jpg'
import convo07 from './Home Gallery/7th Convocation/convo 07.jpg'
import convo08 from './Home Gallery/7th Convocation/convo 08.jpg'
import convo09 from './Home Gallery/7th Convocation/convo 09.jpg'
import convo10 from './Home Gallery/7th Convocation/convo 10.jpg'

import DSC111111 from './Home Gallery/DSC111111.JPG'
import DSC111112 from './Home Gallery/DSC111112.JPG'
import DSC111113 from './Home Gallery/DSC111113.JPG'
import DSC111114 from './Home Gallery/DSC111114.JPG'

function TakeATour() {
    return (
        <div className='takeatour-component'>


            <div className="takeatour-container">

                <div className="video-container">
                    <h2 className="tat-heading-text">
                       DPSRU Kulgeet
                    </h2>
                    <Iframe url="https://www.youtube.com/embed/12hwSQFTk1w"
                        width="100%"
                        height="100%"
                        id=""
                        className=""
                        display="block" />
                </div>

                <div className="photos-highlight-container">
                    <h2 className="tat-heading-text">
                        Photos Highlights
                    </h2>
                    <Carousel autoplay='true' autoplayReverse='true' animation='zoom' pauseOnHover='true' wrapAround={true} withoutControls={true} autoplayInterval={5000} className="nuca-crousel-classNas">

                       
                        <img src={pic1} alt="logo" />
                        {/* <img src={pic2} alt="logo" /> */}
                        <img src={pic3} alt="logo" />
                        {/* <img src={pic4} alt="logo" /> */}
                        <img src={pic5} alt="logo" />
                        {/* <img src={pic6} alt="logo" /> */}
                        <img src={pic7} alt="logo" />
                        <img src={pic8} alt="logo" />
                        <img src={pic9} alt="logo" />
                        <img src={pic10} alt="logo" />
                        <img src={pic11} alt="logo" />
                        {/* <img src={pic12} alt="logo" /> */}
                        <img src={profvRavisir1} alt="logo" />
                        <img src={profvRavisir2} alt="logo" />
                        <img src={internationConf2} alt="logo" />

                        <img src={republicday4} alt="logo" />
                        <img src={republicday2} alt="logo" />
                        <img src={republicday3} alt="logo" />
                        <img src={republicday1} alt="logo" />


                        <img src={aswaFest02} alt="logo" />
                        <img src={aswaFest03} alt="logo" />
                        <img src={aswaFest04} alt="logo" />
                        <img src={aswaFest01} alt="logo" />


                        <img src={convo10} alt="logo" />
                        <img src={convo09} alt="logo" />
                        <img src={convo08} alt="logo" />
                        <img src={convo07} alt="logo" />
                        <img src={convo06} alt="logo" />
                        <img src={convo05} alt="logo" />
                        <img src={convo04} alt="logo" />
                        <img src={convo03} alt="logo" />
                        <img src={convo002} alt="logo" />
                        <img src={convo02} alt="logo" />
                        <img src={convo01} alt="logo" />


                        <img src={DSC111114} alt="logo" />
                        <img src={DSC111113} alt="logo" />
                        <img src={DSC111112} alt="logo" />
                        <img src={DSC111111} alt="logo" />
              
                    </Carousel>
                </div>

            </div>

        </div>
    )
}

export default TakeATour