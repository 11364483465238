import React from 'react'
import '../DIPSAR/Dipsar.css'

// Importing documents
import BMLT from './SahsmFiles/BMLT.pdf'
import DMLT from './SahsmFiles/DMLT.pdf'
import BBA from './SahsmFiles/BBA-CH.pdf'
import BBAch from './SahsmFiles/BBA-Ch-SyllABUS-1.pdf'
import Bscbs from './SahsmFiles/SYLLABUS-1-SEM-Bsc-Bio-Med..pdf'
//PG
import MPHPG from './SahsmFiles/MPH-PG.pdf'
import MBAPG from './SahsmFiles/MBA-PG.pdf'
// import MBAITM from './SahsmFiles/MBA-ITM.pdf'
 import MBAITM from './SahsmFiles/MBA-ITM Syllabus 1-4semesters-24.pdf'
import MHMSPG from './SahsmFiles/MHM-S-PG.pdf'
import MBAPET from './SahsmFiles/MBA-PET.pdf'

//Faculty
import DrJaseela from './Alied Faculty/01-Dr. Jaseela Majeed/Dr-jaseela-Majeed-scaled-e1686903182214.jpg'
import PuneetaA from './Alied Faculty/02-Dr. Puneeta Ajmera/Puneets-Mam-Pic-150x150.jpeg'
import JSwaminathan from './Alied Faculty/03-Dr. J. Swaminathan/IMG_6319-1-scaled-e1685448142376.jpg'
import AbhishekD from './Alied Faculty/04-Dr. Abhishek Dadhich/IMG_0087-scaled-e1686892790333.jpg'
import SheetalYadavS from './Alied Faculty/05-Dr. Sheetal Yadav/Sheetal-Mam-Pic-150x150.jpeg'
import SimmiV from './Alied Faculty/06-Dr Simmi Vashishtha/WhatsApp-Image-2023-09-12-at-5.04.37-PM.jpeg'
import AkashMidha from './Alied Faculty/07-Dr. Akash Midha/IMG_0083-scaled-e1686892866591.jpg'
import DrSuresh from './Alied Faculty/08-Dr. Suresh/IMG_0085-scaled-e1686894595766.jpg'
import SaurabhCh from './Alied Faculty/09-Dr. Saurabh Chaturvedi/IMG_0071-scaled-e1686894990434.jpg'
import DrSubodh from './Alied Faculty/10-Dr. Subodh Kumar/IMG_0080-scaled-e1686895037100.jpg'
import SatishM from './Alied Faculty/11-Dr. Satish Manchanda/IMG_0077-scaled-e1686895089769.jpg'
import RashiGupta from './Alied Faculty/12- Dr. Rashi Gupta/IMG_0092-scaled-e1686895544495.jpg'
import SachinKumar from './Alied Faculty/13-Dr. Sachin Kumar/IMG_0069-scaled-e1686743998434.jpg'
import RakhiAhuja from './Alied Faculty/14-Dr. Rakhi Ahuja/WhatsApp-Image-2023-06-28-at-11.21.57-AM-e1687936746864.jpeg'
import SSivakalai from './Alied Faculty/15-Dr. S.Sivakalai/IMG_0634-scaled-e1688467103880.jpg'
import SarikaSingh from './Alied Faculty/16-Dr. Sarika Singh/IMG_0097-scaled-e1686895860538.jpg'
import Ramsha from './Alied Faculty/17-Dr. Ramsha Iqbal/IMG_0098-scaled-e1686895911106.jpg'

// Time table
import tt1 from './SahsmFiles/MBA-PM-2nd-Sem.-Time-Table-_rotated.pdf'
import tt2 from './SahsmFiles/MBA-ITM-Time-Table-2nd-sem.-_rotated.pdf'
import tt3 from './SahsmFiles/MBA-HHM-2nd-Sem.-Time-Table-_rotated.pdf'
import tt4 from './SahsmFiles/BBA-Healthcare-2nd-Sem.-Time-Table_rotated.pdf'
import tt5 from './SahsmFiles/MPH-2nd-Sem.-Time-Table-_rotated.pdf'
import tt6 from './SahsmFiles/BMLT-5th-Sem.-Time-Table-.pdf'
import tt7 from './SahsmFiles/BMLT-3rd-Sem.-Time-Table-.pdf'
import tt8 from './SahsmFiles/BMLT-1st-Sem.-Time-Table-.pdf'
import tt9 from './SahsmFiles/DMLT-3rd-Sem-Time-Table-.pdf'
import tt10 from './SahsmFiles/BSc-Hons.-BMS-1st-Sem.-Time-Table-.pdf'
import tt11 from './SahsmFiles/B.Sc-BMS-3rd-Sem.-Time-Table-.pdf'
import tt12 from './SahsmFiles/DMLT-1st-Sem.-Time-Table-.pdf'
import { NavLink } from 'react-router-dom'

// new Time table after Live
import mbahhm3rdSem from './SahsmFiles/MBA(HHM)- Hospital Mang. 3rd Sem..pdf'
import mbahhmHealthcare3rdSem from './SahsmFiles/MBA-HHM (Healthcare) -3rd Sem.pdf'
import mbapm3rdSem from './SahsmFiles/MBA-PM Time Table 3rd Sem.pdf'
import BbaHealthcare3rdSem from './SahsmFiles/BBA-Healthcare 3rd Sem..pdf'
import Mph3rdSem from './SahsmFiles/MPH-3rd Sem Time Table .pdf'
import bbaCommunityHealthcare5thSem from './SahsmFiles/Bba-communtiy Healthcare 5th Sem..pdf'
import MbaItm3rdSem from './SahsmFiles/MBA-ITM 3rd Sem..pdf'




// old activities
import oldActivity01 from './SahsmFiles/List-of-Community-Extenstion-Activities.pdf'
import oldActivity02 from './SahsmFiles/Community-Extension-Activity-2022-BY-SAHSM.pdf'
import oldActivity03 from './SahsmFiles/Community-Extension-Activtiy-2021-BY-SAHSM.pdf'
import oldActivity04 from './SahsmFiles/Community-Extenstion-Activity-2020-BY-SAHSM.pdf'
import oldActivity05 from './SahsmFiles/List-of-Guest-Lectures-Seminars-Workshops-by-SAHS-Department.pdf'
import oldActivity06 from './SahsmFiles/Guest-Lecture-Conducted-by-SAHSM-Department-In-2022.pdf'
import oldActivity07 from './SahsmFiles/Guest-Lecture-Conducted-by-SAHSM-Department-in-2021.pdf'
import oldActivity08 from './SahsmFiles/Guest-Lecture-Conducted-By-SAHSM-Department-in-2020.pdf'
import oldActivity09 from './SahsmFiles/Guest-Lecture-Conducted-By-SAHSM-Department-in-2019.pdf'

import bba1stsemtimetable24 from './SahsmFiles/BBA 1st semestre 2024.pdf'
import bba3rdsemtimetabel24 from './SahsmFiles/BBA 3rd semester Time table.pdf'
import bba5thsemtimetable24 from './SahsmFiles/BBA 5th semester Time table.pdf'
import bbahealthcareslabusnaw24 from './SahsmFiles/BBA  HEALTHCARE SYLLABUS.pdf'

import pghhmsyllabus24 from './SahsmFiles/MBA-HHM curriculum.pdf'

import MBAHHM2ndsemtt2025 from './SahsmFiles/MBA-HHM Time Table 2nd Sem  2025.pdf'
import BBA2ndsemtt2025 from './SahsmFiles/BBA Time Table 2nd Sem..pdf'
import BBA6thsemtt2025 from './SahsmFiles/BBA Time Table 6th Sem..pdf'
import BBA4thsemtt2025 from './SahsmFiles/BBA Time Table 4th Sem..pdf'
import MBAITM2ndsem2025 from './SahsmFiles/MBA-ITM Time Table 2nd Sem..pdf'
import MPH2ndsem2025 from './SahsmFiles/MPH Time Table 2nd Sem..pdf'
import mbaPM2ndsem2025 from './SahsmFiles/MBA-PM Time Table 2nd Sem..pdf'

function Sahsm() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                School of Allied Health Sciences And Management
            </h2>

            <h2 className="dpsru-secondary-heading-for-every-page">Introduction</h2>

        <p className="intro-of-alied">The School of Allied Health Sciences is dedicated to become a pioneer in research and education in pharmaceutical, hospital management and public health. The school was established in 2016 under Delhi Pharmaceutical Sciences and Research University, Govt of NCT of Delhi.</p>

            <h2 className="dpsru-secondary-heading-for-every-page">Mission</h2>

        <p className="intro-of-alied"><ul>
            <li>
            Capacity building of healthcare professionals through comprehensive teaching and training</li>
            
            <li>To support the personal and professional development of young minds through effective management principles and preparing professionals for the healthcare</li>
            <li>Connecting and collaborating with health care professionals, organizations and professional bodies to improve health care management and enable to get the best of collective expertise and resources for fulfilling the quality management</li></ul></p>



            <h2 className="dpsru-secondary-heading-for-every-page">Vision</h2>

        <p className="intro-of-alied"><ul>
            <li>
            To transform the health care education to overcome the limitations of Indian health care systems.</li>
            
            <li>Improvement in the standards of health care systems through excellent teaching, training and</li>
            </ul></p>


            <h2 className="dipsar-sub-heading">Programmes</h2>
            <h3 className="dpsru-secondary-heading-for-every-page">Undergraduate Programs</h3>

            <div className='undergradute-program-box'>
                <ul>
                    <li>BMLT (Bachelor of Medical Laboratory Technology) : <a href={BMLT}>Syllabus</a></li>
                    <li>DMLT (Diploma in Medical Laboratory Technology ) : <a href={DMLT}>Syllabus</a></li>
                    <li>BBA (Healthcare) : <a href={bbahealthcareslabusnaw24}>Syllabus</a></li>
                    <li>BBA (Community Healthcare) : <a href={BBAch}>Syllabus</a></li>
                    <li>BSc (Biomedical Sciences): <a href={Bscbs}>Syllabus</a></li>
                </ul>

            </div>
            {/* POST GRADUATE PROGRAM */}

            <h3 className="dpsru-secondary-heading-for-every-page">Post Graduate Programs</h3>

            <div className='undergradute-program-box'>
                <ul>
                    <li>MPH (Public Health) : <a href={MPHPG}>Syllabus</a></li>
                    <li>MBA (Healthcare and Hospital Management) : <a href={pghhmsyllabus24}>Syllabus</a> </li>
                    <li>MBA (Pharmaceutical Management) : <a href={MBAPG}>Syllabus</a></li>
                    <li>MBA (International Trade Management) : <a href={MBAITM}>Syllabus</a></li>
                    <li>MSc (Artificial Intelligence in Medicine) </li>
                    <li>MHM(Master of Hospital Management) : <a href={MHMSPG}>Syllabus</a></li>
                    <li>MBA (Pharma Economics & Trade) : <a href={MBAPET}>Syllabus</a></li>
                </ul>

            </div>

{/* Time table */}
            <h3 className="dpsru-secondary-heading-for-every-page">Time table</h3>

            <div className='undergradute-program-box'>
                <ul>


                    <li>BBA 1st Semester  : <a href={bba1stsemtimetable24}>Time-table</a></li>
                    <li>BBA 2nd Semester  : <a href={BBA2ndsemtt2025}>Time-table</a></li>
                    <li>BBA 3rd Semester  : <a href={bba3rdsemtimetabel24}>Time-table</a></li>
                    <li>BBA 4th Semester  : <a href={BBA4thsemtt2025}>Time-table</a></li>
                    <li>BBA 5th Semester  : <a href={bba5thsemtimetable24}>Time-table</a></li>
                    <li>BBA 6th Semester  : <a href={BBA6thsemtt2025}>Time-table</a></li>
<br />


                    <li>MBA-PM 2nd Semester  : <a href={mbaPM2ndsem2025}>Time-table</a></li>
                    {/* <li>MBA-PM 2nd Semester  : <a href={tt1}>Time-table</a></li> */}
                    {/* <li>MBA-ITM 2nd Semester  : <a href={tt2}>Time-table</a></li> */}
                    <li>MBA-ITM 2nd Semester  : <a href={MBAITM2ndsem2025}>Time-table</a></li>
                    {/* <li>MBA-HHM 2nd Semester   : <a href={tt3}>Time-table</a></li> */}
                    <li>MBA-HHM 2nd Semester   : <a href={MBAHHM2ndsemtt2025}>Time-table</a></li>
                    <li>BBA-Healthcare 2nd Semester   : <a href={tt4}>Time-table</a></li>
                    <li>MPH 2nd Semester   : <a href={tt5}>Time-table</a></li>
                    <li>BMLT 5th Semester   : <a href={tt6}>Time-table</a></li>
                    <li>BMLT 3rd Semester   : <a href={tt7}>Time-table</a></li>
                    <li>BMLT 1st Semester   : <a href={tt8}>Time-table</a></li>
                    <li>DMLT 3rd Semester   : <a href={tt9}>Time-table</a></li>
                    <li>B. Sc (Hons.) BMS 1st Semester   : <a href={tt10}>Time-table</a></li>
                    <li>B. Sc (BMS) 3rd Semester   : <a href={tt11}>Time-table</a></li>
                    <li>DMLT 1st Semester   : <a href={tt12}>Time-table</a></li>
                   
                </ul>


              <br />
                <ul>
                    <li>MBA(HHM)- Hospital Mang. 3rd Sem : <a href={mbahhm3rdSem}>Time-table</a></li>

                    <li>MBA-HHM (Healthcare) -3rd Sem : <a href={mbahhmHealthcare3rdSem}>Time-table</a></li>

                    <li>MBA-PM Time Table 3rd Sem : <a href={mbapm3rdSem}>Time-table</a></li>
                    <li>BBA-Healthcare 3rd Sem : <a href={BbaHealthcare3rdSem}>Time-table</a></li>
                    <li>MPH-2nd Sem Time Table : <a href={MPH2ndsem2025}>Time-table</a></li>
                    <li>MPH-3rd Sem Time Table : <a href={Mph3rdSem}>Time-table</a></li>
                    <li>Bba-communtiy Healthcare 5th Sem : <a href={bbaCommunityHealthcare5thSem}>Time-table</a></li>
                    <li>MBA-ITM 3rd Sem : <a href={MbaItm3rdSem}>Time-table</a></li>
                </ul>

            </div>

            {/* Faculty details */}
            <div className="faculty-details-box">
                <h2 className="dipsar-sub-heading">Faculty Details</h2>

            </div>

            {/* each faculty Box */}
            <NavLink
      to="/Dr-jaseela-majeed"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={DrJaseela} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Jaseela Majeed</h3>
                    <h4 className="faculty-position">Associate Professor <br />
                        Head, School of Allied Health Sciences And Management</h4>
                </div>
            </div>
            </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-punita-ajmera"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={PuneetaA} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Puneeta Ajmera</h3>
                    <h4 className="faculty-position">Associate Professor</h4>
                </div>
            </div>
            </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-j-swaminathan"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={JSwaminathan} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr J. Swaminathan</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink><hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-abhishek-dadhich"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={AbhishekD} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Abhishek Dadhich</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div></NavLink><hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-sheetal-yadav"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SheetalYadavS} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Sheetal Yadav</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div></NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-simmi-vashishtha"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SimmiV} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Simmi Vashishtha</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div>
            </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-akash-midha"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={AkashMidha} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Akash Midha</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-suresh-Assistant-Professor"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={DrSuresh} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Suresh</h3>
                    <h4 className="faculty-position">Assistant Professor (BMLT) </h4>
                </div>
            </div></NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-saurabh-chaturvedi"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SaurabhCh} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Saurabh Chaturvedi</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-subodh-kumar"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={DrSubodh} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Subodh Kumar</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div></NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-satish-namchanda"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SatishM} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Satish Manchanda</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div></NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-rashi-gupta"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={RashiGupta} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">
Dr. Rashi Gupta</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink><hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-sachin-kumar"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SachinKumar} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Sachin Kumar</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-rakhi-ahuja"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={RakhiAhuja} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Rakhi Ahuja</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div>
            </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-s-sivakalai"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SSivakalai} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. S.Sivakalai</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div>
            </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-sarika-singh"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={SarikaSingh} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Sarika Singh</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink> <hr />

            {/* each faculty Box */}
            <NavLink
      to="/Dr-ramsha-iqbal"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={Ramsha} alt="faculty" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Ramsha Iqbal</h3>
                    <h4 className="faculty-position">Assistant Professor</h4>
                </div>
            </div> </NavLink><hr />

            <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities</h2>

        <ul>
          <li>
            <a href={oldActivity01}>
            List of Community Extenstion Activities
            </a>
          </li>
          <li>
            <a href={oldActivity02}>
            Community Extension Activities Conducted In 2022
            </a>
          </li>
          <li>
            <a href={oldActivity03}>Community Extension Activities Conducted In 2021</a>
          </li>
          <li>
            <a href={oldActivity04}>Community Extension Activities Conducted In 2020</a>
          </li>
          <li>
            <a href={oldActivity05}>List of Guest Lectures, Seminars & Workshops</a>
          </li>
          <li>
            <a href={oldActivity06}>Guest Lectures Conducted In 2022</a>
          </li>
          <li>
            <a href={oldActivity07}>Guest Lectures Conducted in 2021</a>
          </li>
          <li>
            <a href={oldActivity08}>Guest Lectures Conducted in 2020
            </a>
          </li>
          <li>
            <a href={oldActivity09}>Guest Lectures Conduct in 2019
            </a>
          </li>
        </ul>
      </div>

        </div>
    )
}

export default Sahsm