import React from "react";
import "./GenralCounsil.css";
import "../Administration.css";

// import first from "./GoverningBoardImg/Vinai-Kumar-Saxena.webp";
// import first from "./GoverningBoardImg/Vinai-Kumar-Saxena copy.jpg";
import first from "./GoverningBoardImg/delhi_governor-Image_0.jpg";


import second from "./GoverningBoardImg/second.png";
import third from "./GoverningBoardImg/third-rkg.png";
import fourth from "./GoverningBoardImg/fourth.png";
import fifth from "./GoverningBoardImg/fifth.png";
import sixth from "./GoverningBoardImg/sixth.png";
import seventh from "./GoverningBoardImg/popliMadam.jpg";

import bg3 from "./GoverningBoardImg/bg3.png";
import bg4 from "./GoverningBoardImg/bg4.png";
import bg5 from "./GoverningBoardImg/bg5.png";
import bg6 from "./GoverningBoardImg/bg6.png";
import bg9 from "./GoverningBoardImg/bg9.png";
import bg10 from "./GoverningBoardImg/bg10.png";
import bg11 from "./GoverningBoardImg/bg11.png";

import ac2 from "./GoverningBoardImg/ac2.png";
import ac3 from "./GoverningBoardImg/ac3.png";
import ac4 from "./GoverningBoardImg/ac4.png";
import ac5 from "./GoverningBoardImg/ac5.png";
import ac6 from "./GoverningBoardImg/ac6.png";
import ac7 from "./GoverningBoardImg/ac7.png";
import ac8 from "./GoverningBoardImg/ac8.png";
import ac9 from "./GoverningBoardImg/ac9.png";
import ac10 from "./GoverningBoardImg/ac10.png";
import ac11 from "./GoverningBoardImg/ac11.png";
import ac12 from "./GoverningBoardImg/ac12.png";
import ac13 from "./GoverningBoardImg/ac13.png";
import ac14 from "./GoverningBoardImg/ac14.png";
import ac15 from "./GoverningBoardImg/khatriSir.jpg";
import ac16 from "./GoverningBoardImg/ac16.png";

import fc2 from "./GoverningBoardImg/fc2.png";
import fc3 from "./GoverningBoardImg/fc3.png";
import fc4 from "./GoverningBoardImg/fc4.png";
import fc5 from "./GoverningBoardImg/fc5.png";
import fc6 from "./GoverningBoardImg/fc6.png";
import fc7 from "./GoverningBoardImg/fc7.jpg";

// import jitenderNVerma from "./GoverningBoardImg/Jitendra N Verma.jpg";
import jitenderNVerma from "./GoverningBoardImg/Jitendra N Verma sir.jpeg";

import AbhimanyuSinghRana from "./GoverningBoardImg/Mr. Abhimanyu Singh Rana.jpg";
import urvashiBSingh from "./GoverningBoardImg/Dr. Urvashi B Singh.jpg";
import MayankMathur from "./GoverningBoardImg/Dr. Mayank Mathur.jpg";
import deptiPandita from "./GoverningBoardImg/Prof. Deepti Pandita.jpg";
import navinkPatel from "./GoverningBoardImg/Dr. Navin K Patel.jpg";
import pkShaoo from "../../Home/HomePageData/profPKSahooDirector.jpeg";
// import fffff from '../../Home/HomePageData/profPKSahooDirector.jpeg'
import crbabusir from "./GoverningBoardImg/ProfCRBabu.jpg";
import rajivsinghraghuvanshi from "./GoverningBoardImg/RajeevSinghRaghvanshi.jpg";
import pankajrambahipatel from "./GoverningBoardImg/SHpankajRambhaiPatel.png";

// import vcVRavichandiran from './GoverningBoardImg/vc-sir-Mahesh-verma.png'
import vcVRavichandiran from '../../Home/HomePageData/prof v ravichandiran.jpeg'
import tanyabundela from './GoverningBoardImg/TanyaBundela.jpg'
// import MSNandaniPaliwal from './GoverningBoardImg/Ms. Nandini Paliwal.JPG'
// import MSNandaniPaliwal from './GoverningBoardImg/msNandani paliwal.jpg'
import MSNandaniPaliwal from './GoverningBoardImg/Iasnandinipalwal.jpg'
import DRsheetalKalra from './GoverningBoardImg/Dr Sheetal Kalra.jpg'
import ProfRichaRai from './GoverningBoardImg/Prof. (Dr.) Richa H Rai.jpg'
import DrPrabodhCSharma from './GoverningBoardImg/Dr Prabodh Sharma.jpeg'
import DrAbhishekDadich from './GoverningBoardImg/Dr Abhishek Dadhich.jpeg'

import noImg from './GoverningBoardImg/blank image.webp'
function GenralCounsil() {
  return (
    <div className="dpsru-every-component-css">
      <h3 className="dpsru-primary-heading-for-every-page">Governing Board</h3>
      <h3 className="dpsru-secondary-heading-for-every-page">
        GENERAL COUNCIL
      </h3>

      <div className="governingBoard-img-container">
        <div className="governingBoard-box">
          <img src={first} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Chairman, Shri. Vinai K. Saxena, <br /> Hon’ble Lt. Governor of
            Delhi & Chancellor of DPSRU, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="governingBoard-box">
                    <img src={second} alt="img" className='governingBoard-box-img' />
                    <p className="governingBoard-box-para">
                        Member, Prof. Shridhar Dwivedi, Senior Consultant, Cardiologist, National Heart Institute, New Delhi
                    </p> <hr /> <br />
                </div> */}

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={jitenderNVerma}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Dr. Jitendra N. Verma,
            <br /> Chief Scientific Officer & Managing Director <br />
            Lifecare Innovations Pvt. Ltd.
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box">
          <img src={vcVRavichandiran} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Prof. Ravichandiran V. ,
            <br /> Vice Chancellor,
            <br /> DPSRU, New
            Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box">
          <img src={pkShaoo} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Non Member Secretary & VC Nominee, Prof. P.K. Sahoo, Director, Delhi Institute of Pharmaceutical Sciences And Research
           
            
          </p>{" "}
          <hr /> <br />
        </div> 

        <div className="governingBoard-box">
          <img src={ac15} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Non Member Secretary & VC Nominee, Dr. Ramchander Khatri, Offg. Registrar (DPSRU) & Asst. Controller of Examination 
           
            
          </p>{" "}
          <hr /> <br />
        </div>  
        
        {/* <div className="governingBoard-box">
          <img src={fifth} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Ms. Alice Vaz,
            <br /> IAS, Secretary,
            <br /> Directorate of Training and Technical Education, GNCTD
          </p>{" "}
          <hr /> <br />
        </div> */}
        
        <div className="governingBoard-box">
          <img src={MSNandaniPaliwal} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Ms. Nandini Paliwal,
            <br /> IAS, Secretary,
            <br /> Directorate of Training and Technical Education, GNCTD
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box">
          <img src={sixth} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Dr. A.C. Verma,
            <br /> IAS, Principal Secretary, <br />
            Finance, GNCTD
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box">
          <img src={crbabusir} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            PROF. C R BABU, <br /> Prof. Emeritus & Former Pro-VC, <br /> Delhi
            University
          </p>{" "}
          <hr /> <br />
        </div>


        <div className="governingBoard-box">
          <img
            src={rajivsinghraghuvanshi}
            alt="img"
            className="governingBoard-box-img"
          />
          <p className="governingBoard-box-para">
            SH. RAJEEV SINGH RAGHVANSHI, <br />
            Secretory cum Scientific Director, IPC
          </p>{" "}
          <hr /> <br />
        </div>


        <div className="governingBoard-box">
          <img
            src={pankajrambahipatel}
            alt="img"
            className="governingBoard-box-img"
          />
          <p className="governingBoard-box-para">
          SH. PANKAJ RAMABHAI PATEL <br />
CMD, Cadila Healthcare Ltd.

          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="governingBoard-box">
          <img
            src={seventh}
            alt="img"
            className="governingBoard-box-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para">
            Prof. Harvinder Popli,
            <br />
            Offg. Registrar, <br />
            Dean R & I, International Affairs
          </p>{" "}
          <hr /> <br />
        </div> */}
      </div>

      {/* Board of Governors */}

      <h2 className="dpsru-secondary-heading-for-every-page">
        BOARD OF GOVERNORS
      </h2>
      <div className="governingBoard-img-container boardOfGovernors-container">
        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={jitenderNVerma}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
           
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Chairman, Dr. Jitendra N. Verma, <br /> Founder & Managing Director{" "}
            <br />
            Lifecare innovations
          </p>{" "}
          <hr /> <br />
        </div>


        <div className="governingBoard-box">
          <img src={vcVRavichandiran} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Prof. Ravichandiran V. ,
            <br /> Vice Chancellor,
            <br /> DPSRU, New
            Delhi
          </p>{" "}
          <hr /> <br />
        </div>



        <div className="governingBoard-box">
          <img src={sixth} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Dr. A.C. Verma,
            <br /> IAS, Principal Secretary, Finance,
            <br /> GNCTD
          </p>{" "}
          <hr /> <br />
        </div>
        <div className="governingBoard-box">
          <img src={MSNandaniPaliwal} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Member, Ms. Nandini Paliwal,
            <br /> IAS, Secretary, <br />
            Directorate of Training and Technical Education, GNCTD
          </p>{" "}
          <hr /> <br />
        </div>
        <div className="governingBoard-box">
          <img src={navinkPatel} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
            Dr. Navin K Patel <br />
            Senior ENT & Head and Neck surgeon,
            <br />
            Lifecare Hospital
          </p>{" "}
          <hr /> <br />
        </div>

        {/*    <div className="governingBoard-box boardOfGovernors-box">
                    <img src={third} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Ex officio Member, Prof. Ramesh K. Goyal, Vice Chancellor, Delhi Pharmaceutical Sciences and Research University, New Delhi                    </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg3} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Member, Prof. Vishwa Mohan Katoch, Former Director General, Indian Council of Medical Research, Govt. of India                  </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg4} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Member, Dr. Arun Kumar Agarwal, Former Dean, Maulana Azad Medical College, New Delhi                </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg5} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Member, Dr. Rajesh Jain, Managing Director, Panacea Biotech, New Delhi          </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg6} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Member, Prof. K. C. Upadhyay, Former Vice Chancellor, MS University (Baroda), and Emeritus Prof. JNU, New Delhi  </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={sixth} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Ex-officio Member, Dr. A. C. Verma, IAS, Principal Secretary, Finance, GNCTD </p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={fifth} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        Ex-officio Member, Ms. Alice Vaz, IAS, Secretary, Directorate of Training and Technical Education, GNCTD</p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg9} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        VC Nominee, Prof. Geeta Aggarwal, Dean (Academics), Delhi Pharmaceutical Sciences and Research University, New Delhi</p> <hr /> <br />
                </div>

                <div className="governingBoard-box boardOfGovernors-box">
                    <img src={bg10} alt="img" className='governingBoard-box-img boardOfGovernors-img' />
                    <p className="governingBoard-box-para boardOfGovernors-para">
                        VC Nominee, Prof. P. K. Sahoo, Director, Delhi Institute of Pharmaceutical Sciences and Research, DPSRU, New Delhi</p> <hr /> <br />
                </div> */}

        <div className="governingBoard-box">
          <img
            src={AbhimanyuSinghRana}
            alt="img"
            className="governingBoard-box-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para">
            Mr. Abhimanyu Singh Rana,
            <br />
            Founder Big Band Food Tech.
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box">
          <img
            src={urvashiBSingh}
            alt="img"
            className="governingBoard-box-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para">
            Dr. Urvashi B Singh <br />
            Professor, Microbiology A.I.I.M.S,
            <br /> New Delhi
          </p>{" "}
          <hr /> <br />
        </div>
        <div className="governingBoard-box">
          <img
            src={MayankMathur}
            alt="img"
            className="governingBoard-box-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para">
            Dr. Mayank Mathur
            <br />
            Senior Principal Scientist, <br />
            CSIR Hqrs. New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={seventh}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Prof. Harvinder Popli,
            <br />
            Offg. Registrar, <br />
            Dean R & I, International Affairs DPSRU
          </p>{" "}
          <hr /> <br />
        </div> */}
        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={pkShaoo}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Prof. P.K. Sahoo, Director, Delhi Institute of Pharmaceutical Sciences And Research
          </p>{" "}
          <hr /> <br />
        </div>


        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac15}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Dr. Ramchander Khatri, Offg. Registrar (DPSRU) & Asst. Controller of Examination
          </p>{" "}
          <hr /> <br />
        </div>
        <div className="governingBoard-box">
          <img
            src={deptiPandita}
            alt="img"
            className="governingBoard-box-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para">
            Prof. Deepti Pandita, <br />
            Prof. Pharmaceutics <br />
            DISPAR, VC nominee
          </p>{" "}
          <hr /> <br />
        </div>
      </div>

      {/* ACADEMIC COUNCIL */}

      <h3 className="dpsru-secondary-heading-for-every-page">
        ACADEMIC COUNCIL
      </h3>
      <div className="governingBoard-img-container boardOfGovernors-container">
        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={vcVRavichandiran}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Chairman, Prof. Ravichandiran V. , Vice Chancellor, Delhi
            Pharmaceutical Science and Research University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac2}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Prof. Surender Singh, Former Vice Chancellor, Jagadguru Sri
            Shivarathreeshwara University, UP
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac3}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Prof. N. Udupa, Professor, Manipal College of Pharmaceutical
            Sciences, Karnataka
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac4}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Dr. Saroj Chooramani Gopal, Former President, National
            Academy of Medical Sciences, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac5}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Dr. Rajiv Gulati, Independent Non-Executive Director, Vazir
            Sultan Tobacco Company Limited, Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={pkShaoo}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Non Member Secretary & VC Nominee, Prof. P.K. Sahoo, Director, DIPSAR, DPSRU, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac7}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Ex-officio Member, Prof. Harvinder Popli, Director, School of
            Pharmaceutical Sciences, Delhi Pharmaceutical Sciences and Research
            University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div> */}

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={noImg}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Ex-officio Member, Dean Student Welfare, Delhi
            Pharmaceutical Sciences and Research University, <br/> New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={DrPrabodhCSharma}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Ex Officio Member, Dr Prabodh Chander, Associate Dean Academics, 
          Delhi Pharmaceutical Sciences and Research University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={DRsheetalKalra}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Academic Council Ex Officio Member ,Dr Sheetal Kalra, Officiating Head, Physiotherapy,  Delhi Pharmaceutical Sciences and Research University,  New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={DrAbhishekDadich}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Ex Officio Member, Dr Abhishek Dadhich, Incharge, School of Allied Health Sciences and Management, Delhi Pharmaceutical Sciences and Research
            University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac12}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member (VC Nominee),Prof. S. R. Wakode, Professor, Department of
            Pharmaceutical Chemistry, Delhi Pharmaceutical Sciences and Research
            University
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ProfRichaRai}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Member (VC Nominee), Prof. (Dr.) Richa H Rai
          Professor, School of Physiotherapy,  Delhi Pharmaceutical Sciences and Research University
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={noImg}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member (VC Nominee), IQAC Head & Professor
            Pharmacognosy, Delhi Pharmaceutical Sciences and Research
            University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={ac15}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Ex-officio Member, Dr. Ramchander Khatri, Offg. Registrar & Assistant Controller of
            Examination, Delhi Pharmaceutical Sciences and Research University,
           
          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={seventh}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Non-member Secretary, Dr. Harvinder Popli, Registrar, Delhi
            Pharmaceutical Sciences and Research University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div> */}
      </div>

      {/* FINANCE COMMITTEE */}

      <h3 className="dpsru-secondary-heading-for-every-page">
        FINANCE COMMITTEE
      </h3>
      <div className="governingBoard-img-container boardOfGovernors-container">
        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={vcVRavichandiran}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Chairman, Prof. Ravichandiran V. , Vice Chancellor, Delhi
            Pharmaceutical Science and Research University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={noImg}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Sh. Rakesh Kumar Verma, Indian Audit and Account Service (Retd.)
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={tanyabundela}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Ms. Tanya Bundela, Social Worker
          </p>{" "}
          <hr /> <br />
        </div>

        
        {/* <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={seventh}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img governing-board-harvinder-popli"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member Secretary, Dr. Harvinder Popli, Registrar, Delhi
            Pharmaceutical Sciences and Research University, New Delhi
          </p>{" "}
          <hr /> <br />
        </div> */}



<div className="governingBoard-box">
          <img src={noImg} alt="img" className="governingBoard-box-img" />
          <p className="governingBoard-box-para">
          -Ex-Officio-Member-  The Principal Secretary, Finance, GNCTD
          </p>{" "}
          <hr /> <br />
        </div>
        

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={noImg}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          -Ex-Officio-Member- The Principal Secretary / Secretary Technical Education , GNCTD
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={AbhimanyuSinghRana}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Mr. Abhimanyu Singh Rana
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={deptiPandita}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
            Member, Prof. Deepti Pandita, DPSRU
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="governingBoard-box boardOfGovernors-box">
          <img
            src={noImg}
            alt="img"
            className="governingBoard-box-img boardOfGovernors-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          - Ex-Officio-Member-Secretary - Controller of Finance
          </p>{" "}
          <hr /> <br />
        </div>
      </div>
    </div>
  );
}

export default GenralCounsil;
