import React from "react";
import "./Tenders.css";
import newgif from ".././NEW/new.gif";

import tander01 from "./Tender FIles/GeM-Bidding-5288573-1.pdf";
import tander02 from "./Tender FIles/GeM-Bidding-5198744-1.pdf";
import tander03 from "./Tender FIles/General-Repair-whitewash-tender-Document.pdf";
import tander04 from "./Tender FIles/Tender-Document-GYM.pdf";
import tander05 from "./Tender FIles/Tender-Document-OPD.pdf";
import tander06 from "./Tender FIles/Boys-Hostel-tender-Document-.pdf";
import tander07 from "./Tender FIles/GeM-Bidding-2689690.pdf";
import tander08 from "./Tender FIles/GeM-Bidding-2689690.pdf";
import tander09 from "./Tender FIles/GeM-Bidding-4977613-1.pdf";
import tander10 from "./Tender FIles/Renovation-ofLab-No.-406.pdf";
import tander11 from "./Tender FIles/Tender-Documents.pdf";
import tander12 from "./Tender FIles/GeM-Bidding-4958985-2.pdf";
import tander13 from "./Tender FIles/Tender.pdf";
import tander14 from "./Tender FIles/GeM-Bidding-4930425-1.pdf";
import tander15 from "./Tender FIles/GeM-Bidding-4934540-1.pdf";
import tander16 from "./Tender FIles/Tender-documents (1).pdf";
import tander17 from "./Tender FIles/GeM-Bidding-4854700.pdf";
import tander18 from "./Tender FIles/Tender-Notice-documents.pdf";
import tander19 from "./Tender FIles/Tendernotice_11.pdf";
import tander20 from "./Tender FIles/tender-documents-of-mess-and-canteen.pdf";
import tander21 from "./Tender FIles/GeM-Bidding-4664379-1-1.pdf";
import tander22 from "./Tender FIles/DIPSAR-COMPUTER-CENTRE.pdf";
import tander23 from "./Tender FIles/GMP-Lab.pdf";
import tander24 from "./Tender FIles/Electro-Spinning-Machine.pdf";
import tander25 from "./Tender FIles/Classroom-Items-Dual-Desk-etc..pdf";
import tander26 from "./Tender FIles/Multimode-Plate-Reader.pdf";
import tander27 from "./Tender FIles/RTPCR-.pdf";
import tander28 from "./Tender FIles/High-Performance-Liquid-Chromatography.pdf";
import tander29 from "./Tender FIles/AUTOMATED-MICROTOME-SECTING-CUTTING-MACHINE.pdf";
import tander30 from "./Tender FIles/DISSOLUTION-APPARATUS.pdf";
import tander31 from "./Tender FIles/IASTAMCatering.pdf";
import tander32 from "./Tender FIles/EOI-1.pdf";
import tander33 from "./Tender FIles/Ultra-High-Performance-Liquid-Chromatography.pdf";
import tander34 from "./Tender FIles/Sports.pdf";
import tander35 from "./Tender FIles/Security-Manpower-Service.pdf";
import tander36 from "./Tender FIles/Document-141122.pdf";
import tander37 from "./Tender FIles/IUMS.pdf";
import tander38 from "./Tender FIles/Corrigendum-3.pdf";
import tander39 from "./Tender FIles/Tendernotice_1-1.pdf";
import tander40 from "./Tender FIles/GeM-Bidding-3802717-1.pdf";
import tander41 from "./Tender FIles/Purchase-of-4D-SpinePostureGait-Motion-Analysis-System.pdf";
import tander42 from "./Tender FIles/Purchase-o9f-Manikins-for-B.Sc-Nursing-Program.pdf";
import tander43 from "./Tender FIles/Notice-employee-insurance-1.pdf";
import tander44 from "./Tender FIles/GeM-Bidding-Computer-IFPs.pdf";
import tander45 from "./Tender FIles/ANIMALIMAGING-SYSTEM.pdf";
import tander46 from "./Tender FIles/Hot-Melt-Extrusion-System.pdf";
import tander47 from "./Tender FIles/Ultra-High-Performance-Liquid-Chromatography-UPLC.pdf";
import tander48 from "./Tender FIles/Corrigendum-student-insurance.pdf";
import tander49 from "./Tender FIles/GeM-Bidding-3443453-1.pdf";
import tander50 from "./Tender FIles/INSURANCE-EMPLOYEES.pdf";
import tander51 from "./Tender FIles/INSURANCE-STUDENTS.pdf";
import tander52 from "./Tender FIles/ONLINEADMISSION.pdf";
import tander53 from "./Tender FIles/Student-Health-insurance-Tender-document.pdf";
import tander54 from "./Tender FIles/Employee-Insurance.pdf";
import tander55 from "./Tender FIles/DocScanner-29-Apr-2022-10-35-AM.pdf";
import tander56 from "./Tender FIles/Employee-Insurance-bid.pdf";
import tander57 from "./Tender FIles/IUMS.pdf";
import tander58 from "./Tender FIles/library-tender-1.pdf";
import tander59 from "./Tender FIles/GeM-Bidding-Animalo-Imaging-System.pdf";
import tander60 from "./Tender FIles/StudentHealthinsurance-1.pdf";
import tander61 from "./Tender FIles/Manpower.pdf";
import tander62 from "./Tender FIles/StudentHealthinsurance.pdf";
import tander63 from "./Tender FIles/GeM-Bidding-3275392_Prep-HPLC.pdf";
import tander64 from "./Tender FIles/GeM-Bidding-3275122-HPLC.pdf";
import tander65 from "./Tender FIles/Adobe-Scan-07-Mar-2022.pdf";
import tander66 from "./Tender FIles/quotation-ampes.pdf";
import tander67 from "./Tender FIles/LibraryBookContract.pdf";
import tander68 from "./Tender FIles/drug-regulatory-tender.pdf";
import tander69 from "./Tender FIles/library-tender.pdf";
import tander70 from "./Tender FIles/GeM-Bid-document-for-Security.pdf";
import tander71 from "./Tender FIles/GeM-Bid-document-for-Sanitation.pdf";
import tander72 from "./Tender FIles/anti-bodies-1.pdf";
import tander73 from "./Tender FIles/kits-icmr-1.pdf";
import tander74 from "./Tender FIles/re-agents-1.pdf";
import tander75 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-FLASH-CHROMATOGRAPHY-SYSTEM.pdf";
import tander76 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-_ROTORY-EVAPORATOR.pdf";
import tander77 from "./Tender FIles/TENDER-FOR-CLINICALRESEARCHCENTRE-DPSRU.pdf";
import tander78 from "./Tender FIles/TENDER-FOR-REPAIR-RENOVATION-OF-GUESTHOUSEGROUNDFLOOR.pdf";
import tander79 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-ROTAORY-EVAPORATOR.pdf";
import tander80 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-NEUROBEHAVIORATESTINGSYSTEMSETUPFORSMALLANIMALS.pdf";

import tander81 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-STUDENT-TWO-SEATER-UNIT.pdf";
import tander82 from "./Tender FIles/TENDER-FOR-SUPPLY-OF-SUPER-INDUCTIVE-SYSTEM-THERAPY-DEVICE.pdf";
import tander83 from "./Tender FIles/biraclab.pdf";
import tander84 from "./Tender FIles/multicolour-fluorescence-imaging-system-Tender-1.pdf";
import tander85 from "./Tender FIles/Notebloc.pdf";
import tander86 from "./Tender FIles/GUESTHOUSEGROUNDFLOORRENOVATION.pdf";
import tander87 from "./Tender FIles/NEUROBEHAVIORAL-TESTING-SYSTEM-SETUP-FOR-SMALL-ANIMALS.pdf";
import tander88 from "./Tender FIles/ECTD-Manager-Software.pdf";
import tander89 from "./Tender FIles/Glassplastic.pdf";
import tander90 from "./Tender FIles/Disintegration-tender.pdf";
import tander91 from "./Tender FIles/Adobe-Scan-Aug-13-2021.pdf";
import tander92 from "./Tender FIles/NEXTGENERATIONDNASEQUENCINGSYSTEMTENDER.pdf";
import tander93 from "./Tender FIles/Multicolour-Fluorescence-Imaging-System.pdf";
import tander94 from "./Tender FIles/CREATION-OF-DUST-PROOF-COMPUTER-LAB-IN-ROOM-NO.-312-1.pdf";
import tander95 from "./Tender FIles/MinisprayTender.pdf";
import tander96 from "./Tender FIles/RHEOMETER.pdf";
import tander97 from "./Tender FIles/LYOPHILIZER.pdf";
import tander98 from "./Tender FIles/Notebloc_FB43C7F6-136E-4D85-AD53-EEE0D01DD0F7-3858-000002BAACE86992.pdf";
import tander99 from "./Tender FIles/ELISAKits.pdf";
import tander100 from "./Tender FIles/Notebloc_71D4C657-69EB-4C7F-BDCF-96E7DE354352-5195-000003CA73D37AA7.pdf";
import tander101 from "./Tender FIles/Google-suite-tender-doc.pdf";
import tander102 from "./Tender FIles/Kaagaz_20210108_160437911.pdf";
import tander103 from "./Tender FIles/DataR.pdf";
import tander104 from "./Tender FIles/Pediatric-Equipment-Tender.pdf";
import tander105 from "./Tender FIles/Qutation.jpg";
import tander106 from "./Tender FIles/CamScanner-10-12-2020-13.54.49.pdf";
import tander107 from "./Tender FIles/CamScanner-09-22-2020-14.45.23.pdf";
import tander108 from "./Tender FIles/repair-of-Lab.pdf";
import tander109 from "./Tender FIles/CamScanner-08-25-2020-15.32.03.pdf";
import tander110 from "./Tender FIles/CamScanner-08-20-2020-13.05.03.pdf";
import tander111 from "./Tender FIles/CamScanner-06-08-2020-15.59.53.pdf";
import tander112 from "./Tender FIles/CamScanner-05-29-2020-15.33.36.pdf";
import tander113 from "./Tender FIles/CamScanner-06-03-2020-15.13.05.pdf";
import tander114 from "./Tender FIles/tender-peds.docx";
import tander115 from "./Tender FIles/Tender-for-Transport-Service.pdf";
import tander116 from "./Tender FIles/TENDER-FOR-TENT.pdf";
import tander117 from "./Tender FIles/New-Doc-2020-01-17-13.43.24.pdf";
import tander118 from "./Tender FIles/Sifdc-1.pdf";
import tander119 from "./Tender FIles/RFP-Hostel-Mess-1.pdf";
import tander120 from "./Tender FIles/tender-annual-report-2019.pdf";

// there is 2 data pushed after live

// data after live
import newdTenderData001 from "./Tender FIles/HPLC-tender.pdf";
import newdTenderData002 from "./Tender FIles/Hostel Mess tender.pdf";
import canteenService from "./Tender FIles/Canteen.pdf";

import groupPersonalAccidentalInsuranceforstudents from "./Tender FIles/Group Personal Accidental Insurance for Students.pdf";
import CateringandHospitalityforConvocation from "./Tender FIles/Catering and Hospitality for Convocation.pdf";
import tentageandlightingserviesforconvo from "./Tender FIles/Tentage and Lighting Services for Convocation.pdf";
import audivisuaarrangementsforconvo from "./Tender FIles/Audio visual arrangements  for Convocation.pdf";
import corrigenregaestimedcost from "./Tender FIles/Corrigendum regarding estimated cost.pdf";
import callForQuotaitontodetermineestimed from "./Tender FIles/Call for Quotation to determine estimated cost for Procurement of LCMS-MS.pdf";
import watercollertender from "./Tender FIles/Repair and CAMC of water Coolers in DPSRU.pdf";
import TenderforratecontractforsuppofAnimalfeedinDPSRU from "./Tender FIles/Tender for Rate Contract for Supply of Animal Feed in DPSRU.pdf";
import VendingMachineCoQ from "./Tender FIles/vending machine CoQ.pdf";
import tenderforCanteenserviceondpsru from "./Tender FIles/Tender for Canteen Services in DPSRU.pdf";
import TemderforProcurmentof01nosofhpcl from "./Tender FIles/Tender for procurement of 01 no. of HPLC.pdf";
import securitymanpaowrservicesindpsru from "./Tender FIles/Short-term-tender for hiring of 44 nos. of Security Manpower Services in DPSRU.pdf";
import hostelMessserviceinDPSRU from "./Tender FIles/Hostel Mess Services in DPSRU.pdf";
import tenderOutsourceingOfficeStaff from './Tender FIles/Manpower Outsourcing Office Staff.pdf'
import tenderForHouseKeepingService from './Tender FIles/Tender for procurement of Housekeeping Manpower Services in DPSRU.pdf'
import ETenderingSystemgovofNCTofDelhi from './Tender FIles/eTendering System Government of NCT of Delhi.pdf'
import TenderForProcurementofCanteenServiceinDPSRU from './Tender FIles/Tender for procurement of Canteen Services in DPSRU.pdf'
import TenderForProcurmentOfHplcinDPSRU from './Tender FIles/Tender for procurement of HPLC in DPSRU.pdf'
import TenderForProcurmentofParticalSizeANalyzerinDPSRU from './Tender FIles/Tender for procurement of Particle Size Analyzer in DPSRU.pdf'
import TenderofProcOFLCMSMSinDpsru from './Tender FIles/Tender for procurement of LCMS-MS in DPSRU.pdf'
import ScanningElectronMicroscopeSEMinDPSRU from './Tender FIles/Scanning Electron Microscope (SEM) in DPSRU.pdf'
import xrayDifrector from './Tender FIles/X-Ray Diffractor.pdf'
import tenderforProcurementof3nosofHPCLinDPSRU from './Tender FIles/Tender for procurement of 3 nos. of HPLC in DPSRU.pdf'
import corrigendumRegardinPurchaseofSEM from './Tender FIles/Corrigendum Regarding Tender for Purchase of Scanning Electron Microscope (SEM).pdf'
import securityManPowerTender from './Tender FIles/Security Manpower Services in DPSRU.pdf'
import physiotheraphyEquipmentproposel from './Tender FIles/Proposal for Purchase of Equipments in School of Physiotherapy.pdf'
import TenderForProcuofLCMSMSindpsrunew from './Tender FIles/Tender for procurement of LCMS-MS in DPSRU New.pdf'
import Corrigendumregardingprebidmeetingorpurchaselcmssindpsru from './Tender FIles/Corrigendum regarding pre-bid meeting for purchase of LCMS-MS in DPSRU at 3 PM on 24.03.2025.pdf'
import TenderRegardingRateContractforSupplyofBOoksinDPSRU from './Tender FIles/Tender regarding Rate Contract for Supply of books in DPSRU.pdf'


function Tenders() {
  return (
    <div className="dpsru-every-component-css">
      <div class="tender-data-container">
        <h2 className="dpsru-primary-heading-for-every-page">TENDERS</h2> <hr />
        <div className="inner-container">
          <ol>
            <li>
              <a href={TenderRegardingRateContractforSupplyofBOoksinDPSRU}>
              Tender regarding Rate Contract for Supply of books in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={Corrigendumregardingprebidmeetingorpurchaselcmssindpsru}>
              Corrigendum regarding pre-bid meeting for purchase of LCMS-MS in DPSRU at 3 PM on 24.03.2025
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderForProcuofLCMSMSindpsrunew}>
              Tender for procurement of LCMS-MS in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={physiotheraphyEquipmentproposel}>
              Proposal for Purchase of Equipments in School of Physiotherapy
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={securityManPowerTender}>
              Tender for Security Manpower Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={corrigendumRegardinPurchaseofSEM}>
              Corrigendum Regarding Tender for Purchase of Scanning Electron Microscope (SEM)
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={tenderforProcurementof3nosofHPCLinDPSRU}>
              Tender for procurement of 3 nos. of HPLC in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={xrayDifrector}>
              X-Ray Diffractor
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={ScanningElectronMicroscopeSEMinDPSRU}>
              Scanning Electron Microscope (SEM) in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderofProcOFLCMSMSinDpsru}>
              Tender for procurement of LCMS-MS in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderForProcurmentofParticalSizeANalyzerinDPSRU}>
              Tender for procurement of Particle Size Analyzer in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderForProcurmentOfHplcinDPSRU}>
              Tender for procurement of HPLC in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderForProcurementofCanteenServiceinDPSRU}>
              Tender for procurement of Canteen Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={ETenderingSystemgovofNCTofDelhi}>
              eTendering System Government of NCT of Delhi
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={tenderOutsourceingOfficeStaff}>
              Tender for procurement of Manpower Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={tenderForHouseKeepingService}>
              Tender for procurement of Housekeeping Manpower Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={hostelMessserviceinDPSRU}>
              Hostel Mess Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={securitymanpaowrservicesindpsru}>
                Short-term-tender for hiring of 44 nos. of Security Manpower
                Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TemderforProcurmentof01nosofhpcl}>
                Tender for procurement of 01 no. of HPLC' in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>

            <li>
              <a href={tenderforCanteenserviceondpsru}>
                Tender for Canteen Services in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={VendingMachineCoQ}>
                Call for Quotations to provide 3 no’s. of ready to eat/beverages
                vending machines
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={TenderforratecontractforsuppofAnimalfeedinDPSRU}>
                Tender for Rate Contract for Supply of Animal Feed in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={watercollertender}>
                Repair and CAMC of water Coolers in DPSRU
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={callForQuotaitontodetermineestimed}>
                Call for Quotation to determine estimated cost for Procurement
                of LCMS-MS
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={corrigenregaestimedcost}>
                'Corrigendum' regarding estimated cost
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={audivisuaarrangementsforconvo}>
                Audio visual arrangements for Convocation
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={tentageandlightingserviesforconvo}>
                Tentage and Lighting Services for Convocation
              </a>
              <img src={newgif} alt="new" />
            </li>

            <li>
              <a href={CateringandHospitalityforConvocation}>
                Catering and Hospitality for Convocation
              </a>
              <img src={newgif} alt="new" />
            </li>

            <li>
              <a href={groupPersonalAccidentalInsuranceforstudents}>
                Group Personal Accidental Insurance for Students
              </a>
              <img src={newgif} alt="new" />
            </li>
            <li>
              <a href={canteenService}>Canteen Services</a>
              <img src={newgif} alt="new" />
            </li>

            <li>
              <a href={newdTenderData002}>
                Tender regarding Hostel Mess Services in DPSRU.
              </a>
              <img src={newgif} alt="new" />
            </li>

            <li>
              <a href={newdTenderData001}>Tender regarding Purchase of HPLC.</a>
            </li>

            <li>
              <a href={tander01}>Tender regarding Purchase of Elisa Kits</a>
            </li>
            <li>
              <a href={tander02}>Purchase of Spectrophotometer</a>
            </li>
            <li>
              <a href={tander03}>
                General repair, Whitewash, and Maintenance of DIPSAR and DPSRU
                New Building at DPSRU Campus{" "}
              </a>
            </li>
            <li>
              <a href={tander04}>Renovation of the Gymnasium, Boys Hostel</a>
            </li>
            <li>
              <a href={tander05}>Renovation of the OPD</a>
            </li>
            <li>
              <a href={tander06}>
                Renovation of Rooms, Galleries &amp; Toilets in Boys Hostel i.e.
                Plaster, Whitewash, Plumbing &amp; Electric Work at DPSRU Campus
              </a>
            </li>
            <li>
              <a href={tander07}>Two Seater Unit’ for SoAHS, DPSRU</a>
            </li>
            <li>
              <a href={tander08}>
                ‘Interactive Flat Panel and Layer 2 access Switch’
              </a>
            </li>
            <li>
              <a href={tander09}>Purchase of Microwave Extraction System</a>
            </li>
            <li>
              <a href={tander10}>Renovation of laboratory -406</a>
            </li>
            <li>
              <a href={tander11}>
                Renovation work of Male &amp; Female Toilet 3rd Floor at DIPSAR
                Building
              </a>{" "}
            </li>
            <li>
              <a href={tander12}>Purchase of E-Tourist Vehicle</a>
            </li>
            <li>
              <a href={tander13}>
                Repairing of Audio Video Systems in Lecture Theaters in
                DIPSAR/DPSRU Building
              </a>
            </li>
            <li>
              <a href={tander14}>
                Purchase of High Performance Liquid Chromatography
              </a>
            </li>
            <li>
              <a href={tander15}>Purchase of Fluorescence Microscope</a>
            </li>
            <li>
              <a href={tander16}>
                Providing and fixing of shed at the Main Entrance of DPSU Campus
              </a>
            </li>
            <li>
              <a href={tander17}>Purchase of Spectrophotometer</a>
            </li>
            <li>
              <a href={tander18}>
                Installation of compactors for keeping old records in record
                room at DPSRU
              </a>
            </li>
            <li>
              <a href={tander19}>
                Redesigning, Development and Maintenance of DPSRU Website
              </a>
            </li>
            <li>
              <a href={tander20}>
                Hostel Mess and Canteen Services in DPSR University
              </a>
            </li>
            <li>
              <a href={tander21}>
                Purchase of High Performance Liquid Chromatography
              </a>
            </li>
            <li>
              <a href={tander22}>
                Creation of DIPSAR Computer- Centre- Purchase of different IT
                Products
              </a>
            </li>
            <li>
              <a href={tander23}>Creation/Renovation of GMP Lab (2nd Phase)</a>
            </li>
            <li>
              <div>
                <a href={tander24}>
                  Purchase of Electro Spinning Machine in Department of
                  Pharmaceutics, SPS, DPSRU.
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href={tander25}>
                  Preparation of Classrooms at 2nd Floor Exam. Branch- purchase
                  of Dual Desk etc.
                </a>
              </div>
            </li>
            <li>
              <a href={tander26}>
                “Multi-mode Plate Reader” in Deptt. of Pharmacognosy &amp;
                Phytochemistry, DPSRU
              </a>
            </li>
            <li>
              <a href={tander27}>
                “RTPCR” in Dept. of Pharmacology, DIPSAR (DPSRU)
              </a>
            </li>
            <li>
              <a href={tander28}>
                “HPLC” in Dept. of Pharmaceutics, DIPSAR(DPSRU)
              </a>
            </li>
            <li>
              <div>
                <a href={tander29}>
                  Purchase of “Automated Microtome Section Cutting Machine” in
                  Deptt. of B.Pharma (Ayurveda), DPSRU.
                </a>
              </div>
            </li>
            <li>
              <a href={tander30}>
                Purchase of “Dissolution Apparatus” in Deptt. of B.Pharma
                (Ayurveda), DPSRU
              </a>
            </li>
            <li>
              <a href={tander31}>
                Tender for Hospitality and Catering Services for Celebration of
                IASTAM Oration and Awards-2023 and International Conclave in
                DPSRU on 21-22 January,2023
              </a>
            </li>
            <li>
              <a href={tander32}>
                Tender/Expression of Interest (EOI) for Empanelment of Insurance
                Companies or their Authorized Service Providers for “Providing
                Group Insurance Policies for Students of DPSR University”
              </a>
            </li>
            <li>
              <a href={tander33}>
                Purchase of “Ultra High Performance Liquid Chromatography” .
              </a>
            </li>
            <li>
              <a href={tander34}>
                Hiring of agency for
                <b>
                  “Consultancy Support Services for Implementation of Support
                  Service for Sports Science Department Programs and Support
                  Management Services for Delhi Pharmaceutical Sciences and
                  Research University (DPSRU)”
                </b>
                .
              </a>
            </li>
            <li>
              <a href={tander35}>
                Hiring of agency for providing “Security Manpower” in DPSRU.
              </a>
            </li>
            <li>
              <a href={tander36}>
                Sealed quotations invitedfor the supply of undermentioned items
                to DPSRU, New Delhi
              </a>
            </li>
            <li>
              <a href={tander37}>
                RFP for “Selection of Service Provider for Implementation of
                Integrated University Management System (IUMS)” in DPSRU
              </a>
            </li>
            <li>
              <a href={tander38}>
                Tender Document for “Selection of Agency for Consultancy Support
                Services for Implementation of Support Service for Sports
                Science Department Programs and Support Management Services for
                Delhi Pharmaceutical Sciences and Research University (DPSRU)”.
                – Corrigendum for postponement of Pre-Bid Meeting to 20/10/2022
                at 11.00AM in DPSRU
              </a>
            </li>
            <li>
              <a href={tander39}>
                Selection of Agency for Consultancy Support Services for
                Implementation of Support Service for Sports Science Department
                Programs and Support Management Services for Delhi
                Pharmaceutical Sciences and Research University (DPSRU)
              </a>
            </li>
            <li>
              <a href={tander40}>
                Providing Group Health Insurance Policies for Students of DPSRU
              </a>
            </li>
            <li>
              <div>
                <a href={tander41}>
                  Purchase of 4D Spine, Posture, Gait and Motion Analysis
                  System” in School of Physiotherapy, DPSRU.
                </a>
              </div>
            </li>
            <li>
              <div>
                <a href={tander42}>
                  Purchase of “Manikins etc” for B.Sc. Nursing Program
                </a>
              </div>
            </li>
            <li>
              <a href={tander43}>
                Notice regarding inviting quotations for ‘Providing Group
                Accidental Insurance to Employees in DPSR University’
              </a>
            </li>
            <li>
              <a href={tander44}>
                Purchase of Desktop Computers and Interactive Flat Panels in
                DPSR University
              </a>
            </li>
            <li>
              <a href={tander45}>Purchase of “Animal Imaging System”</a>
            </li>
            <li>
              <a href={tander46}>Purchase of “Hot Melt Extrusion System”</a>
            </li>
            <li>
              <a href={tander47}>
                Purchase of Ultra High Performance Liquid Chromatography”
              </a>
            </li>
            <li>
              <a href={tander48}>
                Corrigendum dated 02.06.2022 regarding “Group Health Insurance
                Policies for Students of DPSRU.”
              </a>
            </li>
            <li>
              <a href={tander49}>
                Tender regarding “Purchase of Animal Imaging System”.
              </a>
            </li>
            <li>
              <a href={tander50}>
                Tender regarding Group Insurance of DPSRU Employees –
                Corrigendum notice uploaded on May 23, 2022
              </a>
            </li>
            <li>
              <a href={tander51}>
                Tender inviting group health insurance policies for students of
                DPSRU – Corrigendum to tender dated 17/05/2022
              </a>
            </li>
            <li>
              <a href={tander52}>
                Tender inviting Online Admission Form Processing and Online
                Counseling Work-2022-23
              </a>
            </li>
            <li>
              <a href={tander53}>
                Tender inviting group health insurance policies for students of
                DPSRU
              </a>
            </li>
            <li>
              <a href={tander54}>
                Inviting sealed quotations for providing Group Accidental
                Insurance to DPSR University Employees
              </a>
            </li>
            <li>
              <a href={tander55}>
                Extension of data for invitation of quotation for Providing
                group accidental insurance to DPSRU University Employees
              </a>
            </li>
            <li>
              <a href={tander56}>
                Quotations invited for providing “Group Accidental Insurance”to
                DPSR University employees
              </a>
            </li>
            <li>
              <a href={tander57}>
                Tender for “Implementation of Integrated University Management
                System”
              </a>
            </li>
            <li>
              <a href={tander58}>
                Quotations invited for supply of Newspapers and Magazines for
                the period May 2022-March 2023 for DPSRU Library.
              </a>
            </li>
            <li>
              <div>
                <a href={tander59}>Purchase of ‘Animal Imaging System”</a>
              </div>
            </li>
            <li>
              <a href={tander60}>
                Tender for ‘Providing Group Insurance Policies for Students of
                DPSRU”
              </a>
            </li>
            <li>
              <a href={tander61}>Providing Manpower in DPSRU</a>
            </li>
            <li>
              <a href={tander62}>
                Tender for ‘Providing Group Insurance Policies for Students of
                DPSRU.
              </a>
            </li>
            <li>
              <a href={tander63}>Purchase of ‘Prep-HPLC’ for DIIF</a>{" "}
            </li>
            <li>
              <a href={tander64}>Purchase of ‘HPLC’ for DIIF</a>
            </li>
            <li>
              <a href={tander65}>
                Quotations invited for Supply of Newspapers and Magazines for
                DPSRU Library April 2022- March 2023
              </a>
            </li>
            <li>
              <a href={tander66}>
                Quotations invited for “Automatic Mobile Phone Exposure System”
                in Department of Pharmacology, DIPSAR, New Delhi
              </a>
            </li>
            <li>
              <a href={tander67}>
                Annual Supply Contract of Books etc…. in DPSRU Library
              </a>
            </li>
            <li>
              <a href={tander68}>
                “Electronic Common Technical Document (eCTD) Software For Drug
                Regulatory Affairs Department, DPSR University
              </a>
            </li>
            <li>
              <a href={tander69}>
                E-Tender for “Annual Supply Contract of Books (Printed) and
                other Reading Material (Printed) in DPSRU Library”
              </a>
            </li>
            <li>
              <a href={tander70}>
                Bid Document for hiring of Security Manpower Services
              </a>
            </li>
            <li>
              <a href={tander71}>
                Bid Document for hiring of Housekeeping Staff
              </a>
            </li>
            <li>
              <a href={tander72}>
                Quotations invited for “Antibodies for ICMR Project, DPSR
                University, New Delhi”
              </a>
            </li>
            <li>
              <a href={tander73}>
                Quotations invited for “Kits for ICMR Project, DPSR University,
                New Delhi”
              </a>
            </li>
            <li>
              <a href={tander74}>
                Quotations invited for “Reagents for ICMR Project, DPSR
                University, New Delhi”
              </a>
            </li>
            <li>
              <a href={tander75}>
                TENDER FOR SUPPLY OF FLASH CHROMATOGRAPHY SYSTEM
              </a>
            </li>
            <li>
              <a href={tander76}>TENDER FOR SUPPLY OF ROTORY EVAPORATOR</a>
            </li>
            <li>
              <a href={tander77}>TENDER FOR CLINICAL RESEARCH CENTRE, DPSRU</a>
            </li>
            <li>
              <a href={tander78}>
                TENDER FOR REPAIR &amp; RENOVATION OF GUEST HOUSE GROUND FLOOR
              </a>
            </li>
            <li>
              <a href={tander79}>TENDER FOR SUPPLY OF ROTAORY EVAPORATOR</a>
            </li>
            <li>
              <a href={tander80}>
                TENDER FOR SUPPLY OF NEURO-BEHAVIORAL TESTING SYSTEM SETUP FOR
                SMALL ANIMALS
              </a>
            </li>
            <li>
              <a href={tander81}>
                TENDER FOR SUPPLY OF STUDENT TWO SEATER UNIT{" "}
              </a>
            </li>
            <li>
              <a href={tander82}>
                TENDER FOR SUPPLY OF SUPER INDUCTIVE SYSTEM THERAPY DEVICE
              </a>
            </li>
            <li>
              <a href={tander83}>
                Tender document for Refurbishing of Laboratory Space &amp;
                Supply of Laboratory Furniture for DIIF
              </a>
              <strong></strong>
            </li>
            <li>
              <a href={tander84}>
                Tender document for supply of ““Multi-Colour Fluorescence
                Imaging System” in Pharmaceutics Department, DPSRU
              </a>
            </li>
            <li>
              <a href={tander85}>
                Quotations invited for supply of “Disintegration Apparatus” in
                Department of Pharmaceutics, DPSRU
              </a>
            </li>
            <li>
              <a href={tander86}>
                Tender Document for “Renovation Of Rooms And Outer Area At Guest
                House, Ground Floor, DPSRU
              </a>
            </li>
            <li>
              <a href={tander87}>
                Tender Document for the Supply of “Neurobehavioral Testing
                System Setup For Small Animals (Rat And Mice)” In Department Of
                Pharmacology, DPSRU
              </a>
            </li>
            <li>
              <a href={tander88}>
                Tender document for supply of eCTD Software for Drug Regulatory
                Affairs Department, DPSRU
              </a>
            </li>
            <li>
              <a href={tander89}>
                Tender Document for Annual Rate Contract for Laboratory
                Glassware and Plasticware{" "}
              </a>
            </li>
            <li>
              <a href={tander90}>
                Invited – Sealed quotations for supply of Disintegration
                Apparatus for the Department of Pharmaceutics, DPSRU
              </a>
            </li>
            <li>
              <a href={tander91}>Quotations invited for Patent Attorney</a>
            </li>
            <li>
              <a
                href={tander92}
                aria-label="“Tender document for Lyophilizer for DPSRU Innovation and Incubation Foundation” (Edit)"
              >
                Tender Document for Supply of Next Generation DNA Sequencing
                System
              </a>
            </li>
            <li>
              <a href={tander93}>
                Tender/Bid document: Supply of Multi-colour Fluorescence Imaging
                System{" "}
              </a>
            </li>
            <li>
              <a href={tander94}>
                Tender/ Bid document: Creation of Dust-Proof Computer Lab{" "}
              </a>
            </li>
            <li>
              <a
                href={tander95}
                aria-label="“Tender document: Mini Spray Dryer for DPSRU Innovation and Incubation Foundation” (Edit)"
              >
                Tender document: Mini Spray Dryer for DPSRU Innovation and
                Incubation Foundation{" "}
              </a>
            </li>
            <li>
              <a
                href={tander96}
                aria-label="“Tender document: Rheometer for DPSRU Innovation and Incubation Foundation” (Edit)"
              >
                Tender document: Rheometer for DPSRU Innovation and Incubation
                Foundation
              </a>
            </li>
            <li>
              <a
                href={tander97}
                aria-label="“Tender document for Lyophilizer for DPSRU Innovation and Incubation Foundation” (Edit)"
              >
                Tender document for Lyophilizer for DPSRU Innovation and
                Incubation Foundation
              </a>
            </li>
            <li>
              <a href={tander98}>
                Calling Quotations for Bubble Inclinometer, Digital Inclinometer
                and others
              </a>
            </li>
            <li>
              <a href={tander99}>
                Calling Quotations for ELISA Kits and Accessories
              </a>
            </li>
            <li>
              <a href={tander100}>
                Calling Quotations for Bottle Top Dispenser
              </a>
            </li>
            <li>
              <a href={tander101}>
                Calling Quotations for procurement, set up, configuration and
                maintenance of Google Suite
              </a>
            </li>
            <li>
              <a href={tander102}>
                Calling Quotations for Supply and Installation of Franz
                Diffusion Cell{" "}
              </a>
            </li>
            <li>
              <a href={tander103}>
                Calling Quotations for Audio &amp; Visual Services for
                Convocation Programm{" "}
              </a>
            </li>
            <li>
              <a href={tander104}>
                Requesting for Equipment of pediatric unit in Physiotherapy{" "}
              </a>
            </li>
            <li>
              <a href={tander105}>
                Requesting for Quotation for Weight Drop Brain Injury Appratuss
              </a>
            </li>
            <li>
              <a href={tander106}>
                Quotations are invited for PNG Pipeline work. Last
                Date:02.11.2020
              </a>
            </li>
            <li>
              <a href={tander107}>
                Request for quotation for Weight Drop Brain Injury Apparatus –
                Last Date: 28.09.2020
              </a>
            </li>
            <li>
              <a href={tander108}>Request for quotation for Repair of Lab</a>
            </li>
            <li>
              <a href={tander109}>
                Request for quotation for Repair work of the furniture
              </a>
            </li>
            <li>
              <a href={tander110}>
                Request for the quotation of hiring for patent agent for PCT
                patent filling
              </a>
            </li>
            <li>
              <a href={tander111}>
                NIT For Pre and post Examination Work – Confidential{" "}
              </a>
            </li>
            <li>
              <a href={tander112}>NIT for Conduction of Online Examination </a>
            </li>
            <li>
              <a href={tander113}>
                Conduction of Online Examination – Confidential{" "}
              </a>
            </li>
            <li>
              <a href={tander114}>Tender Peds </a>
            </li>
            <li>
              <a href={tander115}>Tender for Transport Service</a>
            </li>
            <li>
              <a href={tander116}>Tender For Tent</a>
            </li>
            <li>
              <a href={tander117}>
                Quotations are invited for PNG Pipeline work. Last
                date:31.01.2020
              </a>
            </li>
            <li>
              <a href={tander118}>
                Quotations are invited for Supply and Installation of Franz
                Diffusion Cell at DPSRU
              </a>
            </li>
            <li>
              <a href={tander119}>
                Request for proposal (RFP) for Operating DPSRU Hostel Mess
              </a>
            </li>
            <li>
              <a href={tander120}>
                Request for the quotation for printing and designing of
                Pharmannual 2018-19. Last Date – 27.05.2019
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
export default Tenders;
