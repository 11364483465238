import React, { useState } from "react";
import "./QuestionPapers.css";

import mp1 from "./QuestionPaper Files/mp1.pdf";
import mp2 from "./QuestionPaper Files/mp2.pdf";
import mp3 from "./QuestionPaper Files/mp3.pdf";
import mp4 from "./QuestionPaper Files/mp4.pdf";
import mp5 from "./QuestionPaper Files/mp5.pdf";
import mp6 from "./QuestionPaper Files/mp6.pdf";
import mp7 from "./QuestionPaper Files/mp7.pdf";
import mp8 from "./QuestionPaper Files/mp8.pdf";
import mp9 from "./QuestionPaper Files/mp9.pdf";
import mp10 from "./QuestionPaper Files/mp10.pdf";
import mp11 from "./QuestionPaper Files/mp11.pdf";
import mp12 from "./QuestionPaper Files/mp12.pdf";
import mp13 from "./QuestionPaper Files/mp13.pdf";
import mp14 from "./QuestionPaper Files/mp14.pdf";
import mp15 from "./QuestionPaper Files/mp15.pdf";

import MHA from "./QuestionPaper Files/MHA.pdf";
import MPH from "./QuestionPaper Files/MPH.pdf";
import MBAPM from "./QuestionPaper Files/MBAPM.pdf";
import MBAPET from "./QuestionPaper Files/MBAPET.pdf";
import MSCAI from "./QuestionPaper Files/M.-Pharm-Executive-MRA-1st-Semester-May-2022.pdf";
import MPTS from "./QuestionPaper Files/MPTS-1st-Semester-May-2022.pdf";

import BPharm from "./QuestionPaper Files/BPharm.pdf";
import BPharm2 from "./QuestionPaper Files/BPharm2.pdf";
import BPharm3 from "./QuestionPaper Files/BPharm3.pdf";
import BPharm4 from "./QuestionPaper Files/BPharm4.pdf";
import BPharm5 from "./QuestionPaper Files/BPharm5.pdf";
import BPharm6 from "./QuestionPaper Files/BPharm6.pdf";
import BPharm7 from "./QuestionPaper Files/BPharm7.pdf";
import BPharm8 from "./QuestionPaper Files/BPharm8.pdf";

import BPT1 from "./QuestionPaper Files/BPT1.pdf";
import BPT3 from "./QuestionPaper Files/BPT3.pdf";
import BPT5 from "./QuestionPaper Files/BPT5.pdf";
import BPT6 from "./QuestionPaper Files/BPT6.pdf";
import BPT7 from "./QuestionPaper Files/BPT7.pdf";
import BPT8 from "./QuestionPaper Files/BPT8.pdf";

import BSS1 from "./QuestionPaper Files/BSS1.pdf";
import BSS3 from "./QuestionPaper Files/BSS3.pdf";
import BSS4 from "./QuestionPaper Files/BSS4.pdf";
import BSS5 from "./QuestionPaper Files/BSS5.pdf";
import BSS6 from "./QuestionPaper Files/BSS6.pdf";

import BPAYU from "./QuestionPaper Files/BPAYU.pdf";

import BMLT from "./QuestionPaper Files/BMLT.pdf";

import dp221 from "./QuestionPaper Files/dp221.pdf";
import dp222 from "./QuestionPaper Files/dp222.pdf";
import dp223 from "./QuestionPaper Files/dp223.pdf";
import dp224 from "./QuestionPaper Files/dp224.pdf";
import dp225 from "./QuestionPaper Files/dp225.pdf";

import dp211 from "./QuestionPaper Files/dp211.pdf";
import dp212 from "./QuestionPaper Files/dp212.pdf";

import dp201 from "./QuestionPaper Files/dp201.pdf";
import dp202 from "./QuestionPaper Files/dp202.pdf";

import dp191 from "./QuestionPaper Files/dp191.pdf";
import dp192 from "./QuestionPaper Files/dp192.pdf";
import dp193 from "./QuestionPaper Files/dp193.pdf";
import dp194 from "./QuestionPaper Files/dp194.pdf";

import dp181 from "./QuestionPaper Files/dp181.pdf";
import dp182 from "./QuestionPaper Files/dp182.pdf";
import dp183 from "./QuestionPaper Files/dp183.pdf";
import dp184 from "./QuestionPaper Files/dp184.pdf";

import dp171 from "./QuestionPaper Files/dp171.pdf";
import dp172 from "./QuestionPaper Files/dp172.pdf";

import dmlt from "./QuestionPaper Files/dmlt.pdf";
import msc2022aipm from "./QuestionPaper Files/M.Sc_.-AI-PM-1st-Semester-May-2022.pdf";

// 2023
import bp101t from "./QuestionPaper Files/2023/BP 101T Q.P.pdf";
import bp102t from "./QuestionPaper Files/2023/BP 102T Q.P.pdf";
import bp103t from "./QuestionPaper Files/2023/BP 103T Q.P.pdf";
import bp104t from "./QuestionPaper Files/2023/BP 104T Q.P.pdf";

import bp201t from "./QuestionPaper Files/2023/BP 201T Q.P.pdf";
import bp202t from "./QuestionPaper Files/2023/BP 202T Q.P.pdf";
import bp203t from "./QuestionPaper Files/2023/BP 203T Q.P.pdf";
import bp204t from "./QuestionPaper Files/2023/BP 204T Q.P.pdf";

import bp301t from "./QuestionPaper Files/2023/BP 301T Q.P.pdf";
import bp302t from "./QuestionPaper Files/2023/BP 302T Q.P.pdf";
import bp303t from "./QuestionPaper Files/2023/BP 302T Q.P.pdf";
import bp304t from "./QuestionPaper Files/2023/BO 304T Q.P.pdf";
import bp401t from "./QuestionPaper Files/2023/BP 401T Q.P.pdf";
import bp402t from "./QuestionPaper Files/2023/BP 402T Q.P.pdf";
import bp403t from "./QuestionPaper Files/2023/BP 403T Q.P.pdf";
import bp404t from "./QuestionPaper Files/2023/BP 404T Q.P.pdf";
import bp405t from "./QuestionPaper Files/2023/BP 405T Q.P.pdf";
import bp501t from "./QuestionPaper Files/2023/BP 501T Q.P.pdf";
import bp502t from "./QuestionPaper Files/2023/BP 502T Q.P.pdf";
import bp503t from "./QuestionPaper Files/2023/BP 503T Q.P.pdf";
import bp504t from "./QuestionPaper Files/2023/BP 504T Q.P.pdf";
import bp505t from "./QuestionPaper Files/2023/BP 505T Q.P.pdf";

import bp601t from "./QuestionPaper Files/2023/BP 601T Q.P.pdf";
import bp602t from "./QuestionPaper Files/2023/BP 602T Q.P.pdf";
import bp603t from "./QuestionPaper Files/2023/BP 603T Q.P.pdf";
import bp604t from "./QuestionPaper Files/2023/BP 604T Q.P.pdf";
import bp605t from "./QuestionPaper Files/2023/BP 605T Q.P.pdf";
import bp606t from "./QuestionPaper Files/2023/BP 606T Q.P.pdf";
import bp701tQPOLdBatch from "./QuestionPaper Files/2023/BP 701T Q.P Old Batch.pdf";
import bp701t from "./QuestionPaper Files/2023/BP 701T Q.P.pdf";
import bp702t from "./QuestionPaper Files/2023/BP 702T Q.P.pdf";
import bp702tqpOldbatch from "./QuestionPaper Files/2023/BP 702TQ.P Old Batch.pdf";
import bp703tqpOldbatch from "./QuestionPaper Files/2023/BP 703T Q P Old Batch.pdf";
import bp703t from "./QuestionPaper Files/2023/BP 703T Q.P.pdf";
import bp704opOldBatch from "./QuestionPaper Files/2023/BP 704T Q.P Old Batch .pdf";
import bp705opOldBatch from "./QuestionPaper Files/2023/BP 705T Old Batch.pdf";
import bp706opOldBatch from "./QuestionPaper Files/2023/BP 705T Old Batch.pdf";

import bp801tQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp802tQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp803etQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp804etQP from "./QuestionPaper Files/2023/BP 804ET Q.P.pdf";
import bp805etQP from "./QuestionPaper Files/2023/BP 805ET Q.P.pdf";
import bp808etQP from "./QuestionPaper Files/2023/BP 808ET Q.P.pdf";
import bp809etQP from "./QuestionPaper Files/2023/BP 809ET Q.P.pdf";
import bp811etQP from "./QuestionPaper Files/2023/BP 811ET Q.P.pdf";
import bp812etQP from "./QuestionPaper Files/2023/BP 812ET Q.P.pdf";

import exeDRS101qp from "./QuestionPaper Files/2023/Exe. DRS 101T Q.P.pdf";
import exeDRS102qp from "./QuestionPaper Files/2023/Exe. DRS 102 Q.P.pdf";
import exeDRS103qp from "./QuestionPaper Files/2023/Exe. DRS 103T  Q.P.pdf";
import exeDRS104qp from "./QuestionPaper Files/2023/Exe. DRS 104T Q.P.pdf";
import matCommonPaper from "./QuestionPaper Files/2023/MAT Common Paper.pdf";
import Mph102tQP from "./QuestionPaper Files/2023/MHP 102T Q.P.pdf";
import Mph103tQP from "./QuestionPaper Files/2023/MHP 103T Q.P.pdf";
import Mph104tQP from "./QuestionPaper Files/2023/MHP 104T Q.P.pdf";
import MCC102tqp from "./QuestionPaper Files/2023/MCC 102T Q.P.pdf";
import MCC103tqp from "./QuestionPaper Files/2023/MCC 103T Q.P.pdf";
import MCC104tqp from "./QuestionPaper Files/2023/MCC 104T Q.P.pdf";
import mip104tqp from "./QuestionPaper Files/2023/MIP 104T Q.P.pdf";
import mip102tqp from "./QuestionPaper Files/2023/MIP 102T Q.P.pdf";
import mip103tqp from "./QuestionPaper Files/2023/MIP 103T Q.P.pdf";
import mpa102tqp from "./QuestionPaper Files/2023/MPA 102T Q.P.pdf";
import mpa103tqp from "./QuestionPaper Files/2023/MPA 103T Q.P.pdf";
import mpa104tqp from "./QuestionPaper Files/2023/MPA 104TQ.P.pdf";
import mpb102tqp from "./QuestionPaper Files/2023/MPB 102T Q.P.pdf";
import mpb103tqp from "./QuestionPaper Files/2023/MPB 103T Q.P.pdf";
import mpb104tqp from "./QuestionPaper Files/2023/MPB 104T Q P.pdf";
import mpc103tqp from "./QuestionPaper Files/2023/MPC 103T Q.P.pdf";
import mpc102tqp from "./QuestionPaper Files/2023/MPC 102T Q.P.pdf";
import mpc104tqp from "./QuestionPaper Files/2023/MPC 104T Q.P.pdf";
import mpcr102tqp from "./QuestionPaper Files/2023/MPCR 102T Q.P.pdf";
import mpcr103tqp from "./QuestionPaper Files/2023/MPCR 103T Q.P.pdf";
import mpcr104tqp from "./QuestionPaper Files/2023/MPCR 104T Q.P.pdf";
import mpg102tqp from "./QuestionPaper Files/2023/MPG 102T Q.P.pdf";
import mpg103tqp from "./QuestionPaper Files/2023/MPG 103T Q.P.pdf";
import mpg104tqp from "./QuestionPaper Files/2023/MPG 104T Q.P.pdf";
import mph102tqp from "./QuestionPaper Files/2023/MPH 102T Q.P.pdf";
import mph103tqp from "./QuestionPaper Files/2023/MPH 103T Q.P.pdf";
import mph104tqp from "./QuestionPaper Files/2023/MPH 104T Q.P.pdf";
import mpl102tqp from "./QuestionPaper Files/2023/MPL 102T Q.P.pdf";
import mpl103tqp from "./QuestionPaper Files/2023/MPL 103T Q.P.pdf";
import mpl104tqp from "./QuestionPaper Files/2023/MPL 104T Q.P.pdf";
import mqa102tqp from "./QuestionPaper Files/2023/MQA 102T Q.P.pdf";
import mqa103tqp from "./QuestionPaper Files/2023/MQA 103T Q.P.pdf";
import mqa104tqp from "./QuestionPaper Files/2023/MQA 104T Q.P.pdf";
import mra101tqp from "./QuestionPaper Files/2023/MRA 101T Q.P.pdf";
import mra102tqp from "./QuestionPaper Files/2023/MRA 102T Q.P.pdf";
import mra103tqp from "./QuestionPaper Files/2023/MRA 103T Q.P.pdf";
import mra104tqp from "./QuestionPaper Files/2023/MRA 104T Q.P.pdf";
import ygs104tqp from "./QuestionPaper Files/2023/YGS 104T Q.P.pdf";

import exemra201tqp from "./QuestionPaper Files/2023/Exe- MRA 201T Q.P.pdf";
import exemra202tqp from "./QuestionPaper Files/2023/Exe-MRA 202T Q.P.pdf";
import exemra203tqp from "./QuestionPaper Files/2023/Exe-MRA 203T Q.P.pdf";
import exemra204tqp from "./QuestionPaper Files/2023/EX MRA 204T Q.P.pdf";
import mcc201tQP from "./QuestionPaper Files/2023/MCC 201T Q.P.pdf";
import mcc202tQP from "./QuestionPaper Files/2023/MCC 202T Q.P.pdf";
import mcc203tQP from "./QuestionPaper Files/2023/MCC 203T Q.P.pdf";
import mcc204tQP from "./QuestionPaper Files/2023/MCC 204T Q.P.pdf";
import mhp201tqp from "./QuestionPaper Files/2023/MHP 201T Q.P.pdf";
import mhp202tqp from "./QuestionPaper Files/2023/MHP 202T Q.P.pdf";
import mhp203tqp from "./QuestionPaper Files/2023/MHP 203T Q.P.pdf";
import mhp204tqp from "./QuestionPaper Files/2023/MHP 204T Q,P.pdf";
import mip202tqp from "./QuestionPaper Files/2023/MIP 202T Q.P.pdf";
import mip203tqp from "./QuestionPaper Files/2023/MIP 203T Q.P.pdf";
import mip201tqp from "./QuestionPaper Files/2023/MIP 201T Q.P.pdf";
import mip204tqp from "./QuestionPaper Files/2023/MIP 204T Q,P.pdf";
import mpa201tqp from "./QuestionPaper Files/2023/MPA 201T Q.P.pdf";
import mpa202tqp from "./QuestionPaper Files/2023/MPA 202T Q.P.pdf";
import mpa203tqp from "./QuestionPaper Files/2023/MPA 203T Q.P.pdf";
import mpa204tqp from "./QuestionPaper Files/2023/MPA 204T Q,P.pdf";
import mpb201tqp from "./QuestionPaper Files/2023/MPB 201T Q.P.pdf";
import mpb202tqp from "./QuestionPaper Files/2023/MPB 202T Q.P.pdf";
import mpb203tqp from "./QuestionPaper Files/2023/MPB 203T Q.P.pdf";
import mpb204tqp from "./QuestionPaper Files/2023/MPB 204T Q,P.pdf";
import mpc201tqp from "./QuestionPaper Files/2023/MPC 201T Q.P.pdf";
import mpc202tqp from "./QuestionPaper Files/2023/MPC 202T Q.P.pdf";
import mpc203tqp from "./QuestionPaper Files/2023/MPC 203T Q.P.pdf";
import mpc204tqp from "./QuestionPaper Files/2023/MPC 204T Q,P.pdf";
import mpcr201tqp from "./QuestionPaper Files/2023/MPCR 201T Q.P.pdf";
import mpcr202tqp from "./QuestionPaper Files/2023/MPCR 202T Q.P.pdf";
import mpcr203tqp from "./QuestionPaper Files/2023/MPCR 203T Q.P.pdf";
import mpcr204tqp from "./QuestionPaper Files/2023/MPCR 204T Q,P.pdf";
import mpg201tqp from "./QuestionPaper Files/2023/MPG 201T Q.P.pdf";
import mpg202tqp from "./QuestionPaper Files/2023/MPG 202T Q.P.pdf";
import mpg203tqp from "./QuestionPaper Files/2023/MPG 203T Q.P.pdf";
import mpg204tqp from "./QuestionPaper Files/2023/MPG 204T Q,P.pdf";
import mph202tqp from "./QuestionPaper Files/2023/MPH 202T Q.P.pdf";
import mph201tqp from "./QuestionPaper Files/2023/MPH 201T Q.P.pdf";
import mph203tqp from "./QuestionPaper Files/2023/MPH 203T Q.P.pdf";
import mph204tqp from "./QuestionPaper Files/2023/MPH 204T Q,P.pdf";
import mpl201tqp from "./QuestionPaper Files/2023/MPL 201T Q.P.pdf";
import mpl203tqp from "./QuestionPaper Files/2023/MPL 203T Q.P.pdf";
import mqa201tqp from "./QuestionPaper Files/2023/MQA 201T Q.P.pdf";
import mlp202tqp from "./QuestionPaper Files/2023/MPL 202T Q.P.pdf";
import mpl204tql from "./QuestionPaper Files/2023/MPL 204T Q,P.pdf";
import mqa202tqp from "./QuestionPaper Files/2023/MQA 202T Q.P.pdf";
import mqa203tqp from "./QuestionPaper Files/2023/MQA 203T Q.P.pdf";
import mqa204tqp from "./QuestionPaper Files/2023/MQA 204T Q,P.pdf";
import mra201tqp from "./QuestionPaper Files/2023/MRA 201T Q.P.pdf";
import mra202tqp from "./QuestionPaper Files/2023/MRA 202T Q,P.pdf";
import mra203tqp from "./QuestionPaper Files/2023/MRA 203T Q.P.pdf";
import mra204tqp from "./QuestionPaper Files/2023/MRA 204T Q,P.pdf";

// BSC
import bschons1stsem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B. Sc. (Hons.) 1st Semester Scan QP, 2023.pdf";
import bschons6thsem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B. Sc. (Hons.) 6th semester QP.pdf";
import bschons2ndsportssciencesem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B. Sc. (Hons.) Sports Science 2nd Semester Q.P..pdf";
import bschons4thsportssciencesem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B. Sc. (Hons.) Sports Science 4th semester scan QP.pdf";
import bschons3rdsem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B.Sc. 3rd Semester question paper.pdf";
import Bsc5thsem23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/B.Sc. 5th semester question paper.pdf";
import bss302tQP23 from "./QuestionPaper Files/Question Paper 2023/B. Sc/BSS-302T Q.P. 2023.pdf";

import bpt1stsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 1st semester scan QP.pdf";
import bpt2ndsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 2nd Semester Scan QP, 2023.pdf";
import bpt3rdsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 3rd semester scan QP.pdf";
import bpt4thsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 4th Semester Scan QP, 2023.pdf";
import bpt5thsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 5th Semester Question Paper, Jan-2023.pdf";
import bpt6thsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 6th semester QP.pdf";
import bpt7thsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 7th Semester Question Paper, Jan-2023.pdf";
import bpt8thsem23 from "./QuestionPaper Files/Question Paper 2023/BPT/BPT 8th semester QP.pdf";
import yogicsciencebpt105t from "./QuestionPaper Files/Question Paper 2023/BPT/Yogic Science (BPT-105T) Sample Paper.pdf";

import mpt1stsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPT 1st semester scan QP.pdf";
import mptc2ndsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPTC 2nd Semester Scan QP..pdf";
import mptp2ndsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPTP 2nd Semester Scan QP..pdf";
import mpts2ndsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPTS 2nd Semester Scan QP.pdf";
import mpts3rdsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPTS 3rd semester scan QP.pdf";
import mpt4thsem23 from "./QuestionPaper Files/Question Paper 2023/MPT/MPT 4th Semester Scan Q.P..pdf";

import mscss1stsem from "./QuestionPaper Files/Question Paper 2023/MSc/M. Sc. Sports Scince 1st Semester Q.P., April-2023.pdf";
import msc2nssem from "./QuestionPaper Files/Question Paper 2023/MSc/M. Sc. 2nd Semester Q.P..pdf";
import msc3rdsem from "./QuestionPaper Files/Question Paper 2023/MSc/M. Sc. Sports Scince 3rd Semester Q.P., April-2023.pdf";
import msc4thsem from "./QuestionPaper Files/Question Paper 2023/MSc/M. Sc. 4th Semester Q.P..pdf";

// 2024
import bschons1stsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 1st Semester Scan Question Paper, 2024.pdf";
import bschons2ndsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 2nd Semester Scan Question Paper, 2024.pdf";
import bschons3rdsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 3rd Semester Scan Question Paper, 2024.pdf";
import bschons4thsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 4th Semester Scan Question Paper, 2024.pdf";
import bschons5thsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 5th Semester Scan Question Paper, 2024.pdf";
import bschons6thsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 6th Semester Scan Question Paper, 2024.pdf";

import bpt2024_1stsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 1st Semester Scan Question Paper, 2024.pdf";
import bpt2024_2ndsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 2nd Semester Scan Question Paper, 2024.pdf";
import bpt2024_3rdsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 3rd Semester Scan Question Paper, 2024.pdf";
import bpt2024_4thsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 4th Semester Scan Question Paper, 2024.pdf";
import bpt2024_5thsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 5th Semester Scan Question Paper, 2024.pdf";
import bpt2024_6thsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 6th Semester Scan Question Paper, 2024.pdf";
import bpt2024_7thsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 7th Semester Scan Question Paper, 2024.pdf";
import bpt2024_8thsem from "./QuestionPaper Files/Scan Question Paper 2024/BPT/BPT 8th Semester Scan Question Paper, 2024.pdf";

import mscsporttsscience3rdsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/M. Sc. Sports Science/M. Sc. Sports Science 3rd Semester Scan Question Paper, 2024.pdf";
import mscsporttsscience4thsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/M. Sc. Sports Science/M. Sc. Sports Science 4th Semester Scan Question Paper, 2024.pdf";

import mpt1stsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/MPT/MPT 1st Semester Scan Question Paper, 2024.pdf";
import mptscp2ndsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/MPT/MPTS,C,P 2nd Semester Scan Question Paper, 2024.pdf";
import mptscp3rdsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/MPT/MPTS,C,P 3rd Semester Scan Question Paper, 2024.pdf";
import mptscp4thsem2024 from "./QuestionPaper Files/Scan Question Paper 2024/MPT/MPTS,C,P 4th Semester Scan Question Paper, 2024.pdf";

import mhm303tsamplepaper24 from "./QuestionPaper Files/Scan Question Paper 2024/MHM-303T Sample Paper.pdf";
import samPaperofYogicScience24 from "./QuestionPaper Files/Scan Question Paper 2024/Sample Paper of Yogic Science (BPT-105T).pdf";

// 31Jan 2025
import BpharmAyu101t2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/BPA-101T.pdf";

import BpharmAyu102t2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/BPA-102T.pdf";
import BpharmAyu103t2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/BPA-103T.pdf";

import BpharmAyu104t2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/BPA-104T.pdf";
import HS101T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/HS-101T.pdf";
import HS102T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/B.Pharm (Ayur) QP 1st Semester June 2022/HS-102T.pdf";

import BbaCh101T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-101T.pdf";
import BbaCh102T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-102T.pdf";
import BbaCh103T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-103T.pdf";
import BbaCh104T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-104T.pdf";
import BbaCh105T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-105T.pdf";
import BbaCh106T2022 from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/BBA-CH QP 1st Semester May 2022/BBA-106T.pdf";

import DML101T from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/DMLT QP 1st Semester April 2022/DML-101T.pdf";
import DML102T from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/DMLT QP 1st Semester April 2022/DML-102T.pdf";
import DML103T from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/DMLT QP 1st Semester April 2022/DML-103T.pdf";
import DML104T from "./QuestionPaper Files/2022 data in 2025/Scanned Question Papers Allied 1st Semester, 2022/DMLT QP 1st Semester April 2022/DML-104T.pdf";

import MbaPETPM103 from "./QuestionPaper Files/2023/MBA_PET PM _ 103.pdf";
import MbPETPM102t2023 from "./QuestionPaper Files/2023/MB-PET - 102T.pdf";
import Mmc104t2023 from "./QuestionPaper Files/2023/MMC - 104T.pdf";

// allied Science
import alleidMBAHHM103T1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA HHM - 103T I sem.pdf";
import alleidMBAPET104T1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA PET 104T I sem.pdf";
import alleidMBAPETpm1031stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA PET,PM 103 I Sem.pdf";
import alleidMBAhhmmph1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-HHM MPH I Sem.pdf";
import alleidMBAitm1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-ITM - 103T I sem.pdf";
import alleidMBAitmHHMMPH101T1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-ITM HHM MPH - 101T I sem.pdf";
import alleidMBAitm1ST from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-ITM I Sem.pdf";
import alleidMBAitmHHMMPH104T1STSEM from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-ITM-HHM-MPH - 104T I sem.pdf";
import alleidMBApm102t1STSEM from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MBA-PM 102T I sem.pdf";
import alleidMBApet102t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MB-PET 102T I sem.pdf";
import alleidMBpm102tissem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MB-PM 102T I sem.pdf";
import alleidMph103tstsem from "./QuestionPaper Files/2023/Allied Sciences/MBA I sem/MPH - 103T I sem.pdf";
// MSc

import mscaipm102t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MSc I Sem/MSc (AIPM - 102T) I sem.pdf";
import mscaipm103t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MSc I Sem/MSc (AIPM - 103T)I sem.pdf";
import mscaipm104t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MSc I Sem/MSc (AIPM - 104T) I sem.pdf";
import mscaipm101t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MSc I Sem/MSc AIPM 101T I sem.pdf";
import mscaipm105t1stsem from "./QuestionPaper Files/2023/Allied Sciences/MSc I Sem/MSc AIPM 105T I sem.pdf";

import mscaipm302t from "./QuestionPaper Files/2023/Allied Sciences/MSc III Sem/M. Sc. AIPM-302T .pdf";
import mscaipm304t from "./QuestionPaper Files/2023/Allied Sciences/MSc III Sem/MSc AIPM 304T III sem.pdf";
import mscaipm305t from "./QuestionPaper Files/2023/Allied Sciences/MSc III Sem/MSc AIPM 305T IIIsem.pdf";
import mscaipm307t from "./QuestionPaper Files/2023/Allied Sciences/MSc III Sem/MSc AIPM 307T IIISem.pdf";

// bpahrm2025
import bp101Tqp2025 from "./QuestionPaper Files/2025/1st Sem, January 2025/BP 101T Q.P.pdf";
import bp102Tqp2025 from "./QuestionPaper Files/2025/1st Sem, January 2025/BP 102T Q.P.pdf";
import bp103Tqp2025 from "./QuestionPaper Files/2025/1st Sem, January 2025/BP 103T Q.P.pdf";
import bp104Tqp2025 from "./QuestionPaper Files/2025/1st Sem, January 2025/BP 104T Q.P.pdf";

import bp301Tqp2025 from "./QuestionPaper Files/2025/3rd Sem, January 2025/BP- 301T Q.P.pdf";
import bp302Tqp2025 from "./QuestionPaper Files/2025/3rd Sem, January 2025/BP- 302T Q.P.pdf";
import bp303Tqp2025 from "./QuestionPaper Files/2025/3rd Sem, January 2025/BP- 303T Q.P.pdf";
import bp304Tqp2025 from "./QuestionPaper Files/2025/3rd Sem, January 2025/BP- 304T Q.P.pdf";

import bp501tqp2025 from "./QuestionPaper Files/2025/5th Sem, January 2025/BP- 501T Q.P.pdf";
import bp502tqp2025 from "./QuestionPaper Files/2025/5th Sem, January 2025/BP- 502T Q.P.pdf";
import bp503tqp2025 from "./QuestionPaper Files/2025/5th Sem, January 2025/BP- 503T Q.P.pdf";
import bp504tqp2025 from "./QuestionPaper Files/2025/5th Sem, January 2025/BP- 504T Q.P.pdf";
import bp505tqp2025 from "./QuestionPaper Files/2025/5th Sem, January 2025/BP- 505T Q.P.pdf";

import Bp701tqp2025 from "./QuestionPaper Files/2025/7th Sem, January 2025/BP- 701T Q.P.pdf";
import Bp702tqp2025 from "./QuestionPaper Files/2025/7th Sem, January 2025/BP- 702T Q.P.pdf";
import Bp703tqp2025 from "./QuestionPaper Files/2025/7th Sem, January 2025/BP- 703T Q.P.pdf";
import Bp704tqp2025 from "./QuestionPaper Files/2025/7th Sem, January 2025/BP- 704T Q.P.pdf";

// 1st April 2025
import MbaPmandITM3rd from "./QuestionPaper Files/2025/1 Apr 2025/Allied PG/MBA-PM & ITM 3rd Semester Scan Question Papers 2025.pdf";
import MbaPmandhhmmphIT1st from "./QuestionPaper Files/2025/1 Apr 2025/Allied PG/MBA-PM,ITM,HHM & MPH 1st Semester Scan Question Papers 2025.pdf";
import mphmhmmhc3rdsem from "./QuestionPaper Files/2025/1 Apr 2025/Allied PG/MPH,MHM & MHC 3rd Semester Scan Question Papers 2025.pdf";

import BscHonsSportsScience1stsem25 from "./QuestionPaper Files/2025/1 Apr 2025/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 1st Semester Scan Question Papers 2025.pdf";
import BscHonsSportsScience3rdsem25 from "./QuestionPaper Files/2025/1 Apr 2025/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 3rd Semester Scan Question Papers 2025.pdf";
import BscHonsSportsScience5thsem25 from "./QuestionPaper Files/2025/1 Apr 2025/B. Sc. (Hons.) Sports Science/B. Sc. (Hons.) Sports Science 5th Semester Scan Question Papers 2025.pdf";

import bpt1stSem2025 from './QuestionPaper Files/2025/1 Apr 2025/BPT/BPT 1st Semester Scan Question Papers 2025.pdf'
import bpt3rdSem2025 from './QuestionPaper Files/2025/1 Apr 2025/BPT/BPT 3rd Semester Scan Question Papers 2025.pdf'
import bpt5thSem2025 from './QuestionPaper Files/2025/1 Apr 2025/BPT/BPT 5th Semester Scan Question Papers 2025.pdf'
import bpt7thSem2025 from './QuestionPaper Files/2025/1 Apr 2025/BPT/BPT 7th Semester Scan Question Papers 2025.pdf'

import mpt1stsem2025 from './QuestionPaper Files/2025/1 Apr 2025/MPT/MPT (All Branches) 1st Semester Scan Question Papers 2025.pdf'
import mptc3rdsem2025 from './QuestionPaper Files/2025/1 Apr 2025/MPT/MPTC 3rd Semester Scan Question Papers 2025.pdf'
import mptp3rdsem2025 from './QuestionPaper Files/2025/1 Apr 2025/MPT/MPTP 3rd Semester Scan Question Papers 2025.pdf'
import mpts3rdsem2025 from './QuestionPaper Files/2025/1 Apr 2025/MPT/MPTS 3rd Semester Scan Question Papers 2025.pdf'

function QuestionPaper() {
  const [mp2022, setMp2022] = useState(false);
  const [mha2022, setMha2022] = useState(false);
  const [mph2022, setMph2022] = useState(false);
  const [mbaPm2022, setMbaPm2022] = useState(false);
  const [mbaPet2022, setMbaPet2022] = useState(false);
  const [mscAipm2022, setMscAipm2022] = useState(false);
  const [mpsm2022, setMpsm2022] = useState(false);
  const [bp2022, setBp2022] = useState(false);
  const [bphy2022, setBphy2022] = useState(false);
  const [bscss2022, setBscss2022] = useState(false);
  const [bpa2022, setBpa2022] = useState(false);
  const [bmlt2022, setBmlt2022] = useState(false);

  // diploma
  const [dp2022, setDp2022] = useState(false);
  const [dp2021, setDp2021] = useState(false);
  const [dp2020, setDp2020] = useState(false);
  const [dp2019, setDp2019] = useState(false);
  const [dp2018, setDp2018] = useState(false);
  const [dp2017, setDp2017] = useState(false);

  const [dmlt2022, setDmlt2022] = useState(false);

  // 2023
  const [bPharm2023, setBpharm2023] = useState(false);
  const [mPharm1stSem2023, setMpharm1stsem2023] = useState(false);
  const [mPharm2ndSem2023, setMpharm2ndsem2023] = useState(false);
  const [alliedSciences2023, setAlliedSciences2023] = useState(false);

  // 2jan2025
  const [bsc2023, setBsc2023] = useState(false);
  const [bpt2023, setBpt2023] = useState(false);
  const [mpt2023, setMpt2023] = useState(false);
  const [msc2023, setMSc2023] = useState(false);

  const [bscHons2024, setBscHons2024] = useState(false);
  const [bpt2024, setBpt2024] = useState(false);
  const [mscSS2024, setMscSS2024] = useState(false);
  const [mpt2024, setMpt2024] = useState(false);

  // 31stjan 2025
  const [bPharmAyr2022, setBPharmAyu2022] = useState(false);
  const [BbaCH2022, setBbaCH2022] = useState(false);
  const [DMLT1st2022, setDMLT1st2022] = useState(false);

  const [bpharm2025, setBpharm2025] = useState(false);
  const [alliedPG, setAlliedPG] = useState(false);
  const [bscSportScience2025, setBscSportScience2025] = useState(false);
  const [Bpt2025, setBpt2025] = useState(false)
  const [Mpt2025, setMpt2025] = useState(false)

  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">QUESTION PAPERS</h2>

      {/* 2025 */}
      <h3 className="dpsru-secondary-heading-for-every-page">2025</h3>


{/* box */}
<div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMpt2025(!Mpt2025)}
        >
       MPT
        </button>

        {Mpt2025 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <br />{" "}
              <li>
                <a
                  href={mpt1stsem2025}
                  target="_blank"
                  rel="noreferrer"
                >
              MPT (All Branches) 1st Semester
                </a>
              </li>
             
              <li>
                <a
                  href={mptc3rdsem2025}
                  target="_blank"
                  rel="noreferrer"
                >
           MPTC 3rd Semester
                </a>
              </li>
             
              <li>
                <a
                  href={mptp3rdsem2025}
                  target="_blank"
                  rel="noreferrer"
                >
        MPTP 3rd Semester
                </a>
              </li>
             
             
              <li>
                <a
                  href={mpts3rdsem2025}
                  target="_blank"
                  rel="noreferrer"
                >
       MPTS 3rd Semester
                </a>
              </li>
             
           
             
            </ul>
          </div>
        ) : null}
      </div>




      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBpt2025(!Bpt2025)}
        >
       BPT
        </button>

        {Bpt2025 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <br />{" "}
              <li>
                <a
                  href={bpt1stSem2025}
                  target="_blank"
                  rel="noreferrer"
                >
                 BPT 1st Semester
                </a>
              </li>
             
              <li>
                <a
                  href={bpt3rdSem2025}
                  target="_blank"
                  rel="noreferrer"
                >
                 BPT 3rd Semester
                </a>
              </li>
             
              <li>
                <a
                  href={bpt5thSem2025}
                  target="_blank"
                  rel="noreferrer"
                >
                 BPT 5th Semester
                </a>
              </li>
             
              <li>
                <a
                  href={bpt7thSem2025}
                  target="_blank"
                  rel="noreferrer"
                >
                 BPT 7th Semester
                </a>
              </li>
             
            </ul>
          </div>
        ) : null}
      </div>






      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBscSportScience2025(!bscSportScience2025)}
        >
          B. Sc. (Hons.) Sports Science
        </button>

        {bscSportScience2025 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <br />{" "}
              <li>
                <a
                  href={BscHonsSportsScience1stsem25}
                  target="_blank"
                  rel="noreferrer"
                >
                  B. Sc. (Hons.) Sports Science 1st Semester
                </a>
              </li>
              <li>
                <a
                  href={BscHonsSportsScience3rdsem25}
                  target="_blank"
                  rel="noreferrer"
                >
                  B. Sc. (Hons.) Sports Science 3rd Semester
                </a>
              </li>
              <li>
                <a
                  href={BscHonsSportsScience5thsem25}
                  target="_blank"
                  rel="noreferrer"
                >
                  B. Sc. (Hons.) Sports Science 5th Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setAlliedPG(!alliedPG)}
        >
          Allied PG
        </button>

        {alliedPG ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <br />{" "}
              <li>
                <a href={MbaPmandhhmmphIT1st} target="_blank" rel="noreferrer">
                  MBA-PM,ITM,HHM & MPH 1st Semester
                </a>
              </li>
              <li>
                <a href={MbaPmandITM3rd} target="_blank" rel="noreferrer">
                  MBA-PM & ITM 3rd Semester
                </a>
              </li>
              <li>
                <a href={mphmhmmhc3rdsem} target="_blank" rel="noreferrer">
                  MPH,MHM & MHC 3rd Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBpharm2025(!bpharm2025)}
        >
          B. Pharm
        </button>

        {bpharm2025 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <br />{" "}
              <h3 className="question-paper-inner-heading-third-new">
                1st Semester
              </h3>{" "}
              <li>
                <a href={bp101Tqp2025} target="_blank" rel="noreferrer">
                  BP 101T Q.P.
                </a>
              </li>
              <li>
                <a href={bp102Tqp2025} target="_blank" rel="noreferrer">
                  BP 102T Q.P.
                </a>
              </li>
              <li>
                <a href={bp103Tqp2025} target="_blank" rel="noreferrer">
                  BP 103T Q.P.
                </a>
              </li>
              <li>
                <a href={bp104Tqp2025} target="_blank" rel="noreferrer">
                  BP 104T Q.P.
                </a>
              </li>
              <br />{" "}
              <h3 className="question-paper-inner-heading-third-new">
                3rd Semester
              </h3>{" "}
              <li>
                <a href={bp301Tqp2025} target="_blank" rel="noreferrer">
                  BP- 301T Q.P.
                </a>
              </li>
              <li>
                <a href={bp302Tqp2025} target="_blank" rel="noreferrer">
                  BP- 302T Q.P.
                </a>
              </li>
              <li>
                <a href={bp303Tqp2025} target="_blank" rel="noreferrer">
                  BP- 303T Q.P.
                </a>
              </li>
              <li>
                <a href={bp304Tqp2025} target="_blank" rel="noreferrer">
                  BP- 304T Q.P.
                </a>
              </li>
              <br />{" "}
              <h3 className="question-paper-inner-heading-third-new">
                5th Semester
              </h3>{" "}
              <li>
                <a href={bp501tqp2025} target="_blank" rel="noreferrer">
                  BP- 501T Q.P.
                </a>
              </li>
              <li>
                <a href={bp502tqp2025} target="_blank" rel="noreferrer">
                  BP- 502T Q.P.
                </a>
              </li>
              <li>
                <a href={bp503tqp2025} target="_blank" rel="noreferrer">
                  BP- 503T Q.P.
                </a>
              </li>
              <li>
                <a href={bp504tqp2025} target="_blank" rel="noreferrer">
                  BP- 504T Q.P.
                </a>
              </li>
              <li>
                <a href={bp505tqp2025} target="_blank" rel="noreferrer">
                  BP- 505T Q.P.
                </a>
              </li>
              <br />{" "}
              <h3 className="question-paper-inner-heading-third-new">
                7th Semester
              </h3>{" "}
              <li>
                <a href={Bp701tqp2025} target="_blank" rel="noreferrer">
                  BP- 701T Q.P.
                </a>
              </li>
              <li>
                <a href={Bp702tqp2025} target="_blank" rel="noreferrer">
                  BP- 702T Q.P.
                </a>
              </li>
              <li>
                <a href={Bp703tqp2025} target="_blank" rel="noreferrer">
                  BP- 703T Q.P.
                </a>
              </li>
              <li>
                <a href={Bp704tqp2025} target="_blank" rel="noreferrer">
                  BP- 704T Q.P.
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* 2024 */}
      <h3 className="dpsru-secondary-heading-for-every-page">2024</h3>

      {/* mpt 2024 */}
      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMpt2024(!mpt2024)}
        >
          MPT
        </button>

        {mpt2024 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={mpt1stsem2024} target="_blank" rel="noreferrer">
                  MPT 1st Semester
                </a>
              </li>
              <li>
                <a href={mptscp2ndsem2024} target="_blank" rel="noreferrer">
                  MPT 2nd Semester
                </a>
              </li>

              <li>
                <a href={mptscp3rdsem2024} target="_blank" rel="noreferrer">
                  MPT 3rd Semester
                </a>
              </li>

              <li>
                <a href={mptscp4thsem2024} target="_blank" rel="noreferrer">
                  MPT 4th Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* Msc sports science 2024 */}
      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMscSS2024(!mscSS2024)}
        >
          M. Sc. Sports Science
        </button>

        {mscSS2024 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a
                  href={mscsporttsscience3rdsem2024}
                  target="_blank"
                  rel="noreferrer"
                >
                  M. Sc. Sports Science 3rd Semester
                </a>
              </li>
              <li>
                <a
                  href={mscsporttsscience4thsem2024}
                  target="_blank"
                  rel="noreferrer"
                >
                  M. Sc. Sports Science 4th Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {/* Msc sports science 2024 */}

      {/* Bpt 2024 */}
      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBpt2024(!bpt2024)}
        >
          BPT 2024
        </button>

        {bpt2024 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bpt2024_1stsem} target="_blank" rel="noreferrer">
                  BPT 1st Semester
                </a>
              </li>
              <li>
                <a href={bpt2024_2ndsem} target="_blank" rel="noreferrer">
                  BPT 2nd Semester
                </a>
              </li>

              <li>
                <a href={bpt2024_3rdsem} target="_blank" rel="noreferrer">
                  BPT 3rd Semester
                </a>
              </li>
              <li>
                <a href={bpt2024_4thsem} target="_blank" rel="noreferrer">
                  BPT 4th Semester
                </a>
              </li>
              <li>
                <a href={bpt2024_5thsem} target="_blank" rel="noreferrer">
                  BPT 5th Semester
                </a>
              </li>
              <li>
                <a href={bpt2024_6thsem} target="_blank" rel="noreferrer">
                  BPT 6th Semester
                </a>
              </li>
              <li>
                <a href={bpt2024_7thsem} target="_blank" rel="noreferrer">
                  BPT 7th Semester
                </a>
              </li>

              <li>
                <a href={bpt2024_8thsem} target="_blank" rel="noreferrer">
                  BPT 8th Semester
                </a>
              </li>
              <li>
                <a
                  href={samPaperofYogicScience24}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sample Paper of Yogic Science (BPT-105T)
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {/* Bpt 2024 */}

      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBscHons2024(!bscHons2024)}
        >
          B. Sc. (Hons) Sports Science
        </button>

        {bscHons2024 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bschons1stsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 1st Semester
                </a>
              </li>

              <li>
                <a href={bschons2ndsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 2nd Semester
                </a>
              </li>
              <li>
                <a href={bschons3rdsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 3rd semester
                </a>
              </li>

              <li>
                <a href={bschons4thsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 4th semester
                </a>
              </li>

              <li>
                <a href={bschons5thsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 5th semester
                </a>
              </li>

              <li>
                <a href={bschons6thsem2024} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) Sports Science 6th semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* 2024 */}

      <h3 className="dpsru-secondary-heading-for-every-page">2023</h3>

      {/* box */}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setAlliedSciences2023(!alliedSciences2023)}
        >
          Allied Sciences
        </button>

        {alliedSciences2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              {/* <li>
                <a href={MbaPETPM103} target="_blank" rel="noreferrer">
                  MBA_PET/PM _ 103
                </a>
              </li> */}
              {/* <li>
                <a href={MbPETPM102t2023} target="_blank" rel="noreferrer">
                  MB-PET - 102T
                </a>
              </li> */}
              <li>
                <a href={Mmc104t2023} target="_blank" rel="noreferrer">
                  MMC - 104T
                </a>
              </li>
              <li>
                <a href={mhm303tsamplepaper24} target="_blank" rel="noreferrer">
                  MHM-303T Sample Paper
                </a>
              </li>

              <br />
              <li>
                <a
                  href={alleidMBAHHM103T1stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA HHM - 103T 1st sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBAPET104T1stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA PET 104T 1st sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBAPETpm1031stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA PET,PM 103 1st Sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBAhhmmph1stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA-HHM MPH 1st Sem
                </a>
              </li>

              <li>
                <a href={alleidMBAitm1stsem} target="_blank" rel="noreferrer">
                  MBA-ITM - 103T 1st sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBAitmHHMMPH101T1stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA-ITM HHM MPH - 101T 1ST sem
                </a>
              </li>

              <li>
                <a href={alleidMBAitm1ST} target="_blank" rel="noreferrer">
                  MBA-ITM 1st Sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBAitmHHMMPH104T1STSEM}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA-ITM-HHM-MPH - 104T 1st sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBApm102t1STSEM}
                  target="_blank"
                  rel="noreferrer"
                >
                  MBA-PM 102T 1st sem
                </a>
              </li>

              <li>
                <a
                  href={alleidMBApet102t1stsem}
                  target="_blank"
                  rel="noreferrer"
                >
                  MB-PET 102T 1st sem
                </a>
              </li>

              <li>
                <a href={alleidMBpm102tissem} target="_blank" rel="noreferrer">
                  MB-PM 102T 1st sem
                </a>
              </li>

              <li>
                <a href={alleidMph103tstsem} target="_blank" rel="noreferrer">
                  MPH - 103T 1st sem
                </a>
              </li>
              <br />

              <li>
                <a href={mscaipm102t1stsem} target="_blank" rel="noreferrer">
                  MSc (AIPM - 102T) I sem
                </a>
              </li>

              <li>
                <a href={mscaipm103t1stsem} target="_blank" rel="noreferrer">
                  MSc (AIPM - 103T)I sem
                </a>
              </li>

              <li>
                <a href={mscaipm104t1stsem} target="_blank" rel="noreferrer">
                  MSc (AIPM - 104T)I sem
                </a>
              </li>

              <li>
                <a href={mscaipm101t1stsem} target="_blank" rel="noreferrer">
                  MSc AIPM 101T I sem
                </a>
              </li>
              <li>
                <a href={mscaipm105t1stsem} target="_blank" rel="noreferrer">
                  MSc AIPM 105T I sem
                </a>
              </li>
              <br />

              <li>
                <a href={mscaipm302t} target="_blank" rel="noreferrer">
                  M. Sc. AIPM-302T
                </a>
              </li>

              <li>
                <a href={mscaipm304t} target="_blank" rel="noreferrer">
                  MSc AIPM 304T III sem
                </a>
              </li>

              <li>
                <a href={mscaipm305t} target="_blank" rel="noreferrer">
                  MSc AIPM 305T III sem
                </a>
              </li>

              <li>
                <a href={mscaipm307t} target="_blank" rel="noreferrer">
                  MSc AIPM 307T III sem
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBsc2023(!bsc2023)}
        >
          B. Sc. (Hons)
        </button>

        {bsc2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bschons1stsem23} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) 1st Semester
                </a>
              </li>
              <li>
                <a href={bschons6thsem23} target="_blank" rel="noreferrer">
                  B. Sc. (Hons.) 6th semester
                </a>
              </li>

              <li>
                <a
                  href={bschons2ndsportssciencesem23}
                  target="_blank"
                  rel="noreferrer"
                >
                  B. Sc. (Hons.) Sports Science 2nd Semester
                </a>
              </li>
              <li>
                <a
                  href={bschons4thsportssciencesem23}
                  target="_blank"
                  rel="noreferrer"
                >
                  B. Sc. (Hons.) Sports Science 4th semester
                </a>
              </li>
              <li>
                <a href={bschons3rdsem23} target="_blank" rel="noreferrer">
                  B.Sc. 3rd Semester
                </a>
              </li>
              <li>
                <a href={Bsc5thsem23} target="_blank" rel="noreferrer">
                  B.Sc. 5th semester
                </a>
              </li>
              <li>
                <a href={bss302tQP23} target="_blank" rel="noreferrer">
                  BSS-302T Q.P. 2023
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  BPT*/}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBpt2023(!bpt2023)}
        >
          BPT
        </button>

        {bpt2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bpt1stsem23} target="_blank" rel="noreferrer">
                  BPT 1st semester
                </a>
              </li>
              <li>
                <a href={bpt2ndsem23} target="_blank" rel="noreferrer">
                  BPT 2nd Semester
                </a>
              </li>

              <li>
                <a href={bpt3rdsem23} target="_blank" rel="noreferrer">
                  BPT 3rd semester
                </a>
              </li>
              <li>
                <a href={bpt4thsem23} target="_blank" rel="noreferrer">
                  BPT 4th Semester
                </a>
              </li>
              <li>
                <a href={bpt5thsem23} target="_blank" rel="noreferrer">
                  BPT 5th Semester
                </a>
              </li>
              <li>
                <a href={bpt6thsem23} target="_blank" rel="noreferrer">
                  BPT 6th semester
                </a>
              </li>
              <li>
                <a href={bpt7thsem23} target="_blank" rel="noreferrer">
                  BPT 7th Semester
                </a>
              </li>
              <li>
                <a href={bpt8thsem23} target="_blank" rel="noreferrer">
                  BPT 8th Semester
                </a>
              </li>

              <li>
                <a href={yogicsciencebpt105t} target="_blank" rel="noreferrer">
                  Yogic Science (BPT-105T)
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  MPT*/}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMpt2023(!mpt2023)}
        >
          MPT
        </button>

        {mpt2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={mpt1stsem23} target="_blank" rel="noreferrer">
                  MPT 1st semester
                </a>
              </li>
              <li>
                <a href={mptc2ndsem23} target="_blank" rel="noreferrer">
                  MPTC 2nd Semester
                </a>
              </li>

              <li>
                <a href={mptp2ndsem23} target="_blank" rel="noreferrer">
                  MPTP 2nd Semester
                </a>
              </li>
              <li>
                <a href={mpts2ndsem23} target="_blank" rel="noreferrer">
                  MPTS 2nd Semester
                </a>
              </li>
              <li>
                <a href={mpts3rdsem23} target="_blank" rel="noreferrer">
                  MPTS 3rd semester
                </a>
              </li>
              <li>
                <a href={mpt4thsem23} target="_blank" rel="noreferrer">
                  MPT 4th Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  MSc*/}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMSc2023(!msc2023)}
        >
          MSc
        </button>

        {msc2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={mscss1stsem} target="_blank" rel="noreferrer">
                  M. Sc. Sports Science 1st Sem
                </a>
              </li>
              <li>
                <a href={msc2nssem} target="_blank" rel="noreferrer">
                  M. Sc. 2nd Semester
                </a>
              </li>

              <li>
                <a href={msc3rdsem} target="_blank" rel="noreferrer">
                  M. Sc. Sports Science 3rd Sem
                </a>
              </li>
              <li>
                <a href={msc4thsem} target="_blank" rel="noreferrer">
                  M. Sc. 4th Semester
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading"></h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMpharm1stsem2023(!mPharm1stSem2023)}
        >
          M.Pharm 1st semester
        </button>

        {mPharm1stSem2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={matCommonPaper} target="_blank" rel="noreferrer">
                  MAT Common Paper
                </a>
              </li>
              <li>
                <a href={exeDRS101qp} target="_blank" rel="noreferrer">
                  Exe. DRS 101T Q.P.
                </a>
              </li>

              <li>
                <a href={exeDRS102qp} target="_blank" rel="noreferrer">
                  Exe. DRS 102 Q.P.
                </a>
              </li>
              <li>
                <a href={exeDRS103qp} target="_blank" rel="noreferrer">
                  Exe. DRS 103T Q.P.
                </a>
              </li>
              <li>
                <a href={exeDRS104qp} target="_blank" rel="noreferrer">
                  Exe. DRS 104T Q.P.
                </a>
              </li>
              <li>
                <a href={Mph102tQP} target="_blank" rel="noreferrer">
                  MHP 102T Q.P.
                </a>
              </li>
              <li>
                <a href={Mph103tQP} target="_blank" rel="noreferrer">
                  MHP 103T Q.P.
                </a>
              </li>
              <li>
                <a href={Mph104tQP} target="_blank" rel="noreferrer">
                  MHP 104T Q.P.
                </a>
              </li>
              <li>
                <a href={MCC102tqp} target="_blank" rel="noreferrer">
                  MCC 102T Q.P.
                </a>
              </li>
              <li>
                <a href={MCC103tqp} target="_blank" rel="noreferrer">
                  MCC 103T Q.P.
                </a>
              </li>
              <li>
                <a href={MCC104tqp} target="_blank" rel="noreferrer">
                  MCC 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mip102tqp} target="_blank" rel="noreferrer">
                  MIP 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mip103tqp} target="_blank" rel="noreferrer">
                  MIP 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mip104tqp} target="_blank" rel="noreferrer">
                  MIP 104T Q.P.
                </a>
              </li>

              <li>
                <a href={mpa102tqp} target="_blank" rel="noreferrer">
                  MPA 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mpa103tqp} target="_blank" rel="noreferrer">
                  MPA 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mpa104tqp} target="_blank" rel="noreferrer">
                  MPA 104T Q.P.
                </a>
              </li>

              <li>
                <a href={mpb102tqp} target="_blank" rel="noreferrer">
                  MPB 102T Q.P.
                </a>
              </li>

              <li>
                <a href={mpb103tqp} target="_blank" rel="noreferrer">
                  MPB 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mpb104tqp} target="_blank" rel="noreferrer">
                  MPB 104T Q P.
                </a>
              </li>
              <li>
                <a href={mpc102tqp} target="_blank" rel="noreferrer">
                  MPC 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc103tqp} target="_blank" rel="noreferrer">
                  MPC 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc104tqp} target="_blank" rel="noreferrer">
                  MPC 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr102tqp} target="_blank" rel="noreferrer">
                  MPCR 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr103tqp} target="_blank" rel="noreferrer">
                  MPCR 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr104tqp} target="_blank" rel="noreferrer">
                  MPCR 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg102tqp} target="_blank" rel="noreferrer">
                  MPG 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg103tqp} target="_blank" rel="noreferrer">
                  MPG 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg104tqp} target="_blank" rel="noreferrer">
                  MPG 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mph102tqp} target="_blank" rel="noreferrer">
                  MPH 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mph103tqp} target="_blank" rel="noreferrer">
                  MPH 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mph104tqp} target="_blank" rel="noreferrer">
                  MPH 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mpl102tqp} target="_blank" rel="noreferrer">
                  MPL 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mpl103tqp} target="_blank" rel="noreferrer">
                  MPL 103T Q.P.
                </a>
              </li>

              <li>
                <a href={mpl104tqp} target="_blank" rel="noreferrer">
                  MPL 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa102tqp} target="_blank" rel="noreferrer">
                  MQA 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa103tqp} target="_blank" rel="noreferrer">
                  MQA 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa104tqp} target="_blank" rel="noreferrer">
                  MQA 104T Q.P.
                </a>
              </li>
              <li>
                <a href={mra101tqp} target="_blank" rel="noreferrer">
                  MRA 101T Q.P.
                </a>
              </li>
              <li>
                <a href={mra102tqp} target="_blank" rel="noreferrer">
                  MRA 102T Q.P.
                </a>
              </li>
              <li>
                <a href={mra103tqp} target="_blank" rel="noreferrer">
                  MRA 103T Q.P.
                </a>
              </li>
              <li>
                <a href={mra104tqp} target="_blank" rel="noreferrer">
                  MRA 104T Q.P.
                </a>
              </li>
              <li>
                <a href={ygs104tqp} target="_blank" rel="noreferrer">
                  YGS 104T Q.P.
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading">M.Pharm 2nd Semester</h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMpharm2ndsem2023(!mPharm2ndSem2023)}
        >
          M.Pharm 2nd Semester
        </button>

        {mPharm2ndSem2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={exemra201tqp} target="_blank" rel="noreferrer">
                  Exe- MRA 201T Q.P.
                </a>
              </li>
              <li>
                <a href={exemra202tqp} target="_blank" rel="noreferrer">
                  Exe-MRA 202T Q.P.
                </a>
              </li>
              <li>
                <a href={exemra203tqp} target="_blank" rel="noreferrer">
                  Exe-MRA 203T Q.P.
                </a>
              </li>
              <li>
                <a href={exemra204tqp} target="_blank" rel="noreferrer">
                  Exe-MRA 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mcc201tQP} target="_blank" rel="noreferrer">
                  MCC 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mcc202tQP} target="_blank" rel="noreferrer">
                  MCC 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mcc203tQP} target="_blank" rel="noreferrer">
                  MCC 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mcc204tQP} target="_blank" rel="noreferrer">
                  MCC 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mhp201tqp} target="_blank" rel="noreferrer">
                  MHP 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mhp202tqp} target="_blank" rel="noreferrer">
                  MHP 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mhp203tqp} target="_blank" rel="noreferrer">
                  MHP 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mhp204tqp} target="_blank" rel="noreferrer">
                  MHP 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mip201tqp} target="_blank" rel="noreferrer">
                  MIP 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mip202tqp} target="_blank" rel="noreferrer">
                  MIP 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mip203tqp} target="_blank" rel="noreferrer">
                  MIP 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mip204tqp} target="_blank" rel="noreferrer">
                  MIP 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpa201tqp} target="_blank" rel="noreferrer">
                  MPA 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpa202tqp} target="_blank" rel="noreferrer">
                  MPA 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mpa203tqp} target="_blank" rel="noreferrer">
                  MPA 203T Q.P.
                </a>
              </li>

              <li>
                <a href={mpa204tqp} target="_blank" rel="noreferrer">
                  MPA 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpb201tqp} target="_blank" rel="noreferrer">
                  MPB 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpb202tqp} target="_blank" rel="noreferrer">
                  MPB 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mpb203tqp} target="_blank" rel="noreferrer">
                  MPB 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mpb204tqp} target="_blank" rel="noreferrer">
                  MPB 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc201tqp} target="_blank" rel="noreferrer">
                  MPC 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc202tqp} target="_blank" rel="noreferrer">
                  MPC 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc203tqp} target="_blank" rel="noreferrer">
                  MPC 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mpc204tqp} target="_blank" rel="noreferrer">
                  MPC 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr201tqp} target="_blank" rel="noreferrer">
                  MPCR 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr202tqp} target="_blank" rel="noreferrer">
                  MPCR 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr203tqp} target="_blank" rel="noreferrer">
                  MPCR 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mpcr204tqp} target="_blank" rel="noreferrer">
                  MPCR 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg201tqp} target="_blank" rel="noreferrer">
                  MPG 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg202tqp} target="_blank" rel="noreferrer">
                  MPG 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg203tqp} target="_blank" rel="noreferrer">
                  MPG 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mpg204tqp} target="_blank" rel="noreferrer">
                  MPG 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mph201tqp} target="_blank" rel="noreferrer">
                  MPH 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mph202tqp} target="_blank" rel="noreferrer">
                  MPH 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mph203tqp} target="_blank" rel="noreferrer">
                  MPH 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mph204tqp} target="_blank" rel="noreferrer">
                  MPH 204T Q.P.
                </a>
              </li>
              <li>
                <a href={mpl201tqp} target="_blank" rel="noreferrer">
                  MPL 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mpl203tqp} target="_blank" rel="noreferrer">
                  MPL 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mpl204tql} target="_blank" rel="noreferrer">
                  MPL 204T Q,P.
                </a>
              </li>
              <li>
                <a href={mqa201tqp} target="_blank" rel="noreferrer">
                  MQA 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa202tqp} target="_blank" rel="noreferrer">
                  MQA 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa203tqp} target="_blank" rel="noreferrer">
                  MQA 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mqa204tqp} target="_blank" rel="noreferrer">
                  MQA 204T Q,P.
                </a>
              </li>
              <li>
                <a href={mlp202tqp} target="_blank" rel="noreferrer">
                  MPL 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mra201tqp} target="_blank" rel="noreferrer">
                  MRA 201T Q.P.
                </a>
              </li>
              <li>
                <a href={mra202tqp} target="_blank" rel="noreferrer">
                  MRA 202T Q.P.
                </a>
              </li>
              <li>
                <a href={mra203tqp} target="_blank" rel="noreferrer">
                  MRA 203T Q.P.
                </a>
              </li>
              <li>
                <a href={mra204tqp} target="_blank" rel="noreferrer">
                  MRA 204T Q.P.
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading">
          Bachelor of Pharmacy
        </h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setBpharm2023(!bPharm2023)}
        >
          Bachelor of Pharmacy
        </button>
        {bPharm2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bp101t} target="_blank" rel="noreferrer">
                  BP 101T Q.P.
                </a>
              </li>
              <li>
                <a href={bp102t} target="_blank" rel="noreferrer">
                  BP 102T Q.P.
                </a>
              </li>
              <li>
                <a href={bp103t} target="_blank" rel="noreferrer">
                  BP 103T Q.P.
                </a>
              </li>
              <li>
                <a href={bp104t} target="_blank" rel="noreferrer">
                  BP 104T Q.P.
                </a>
              </li>{" "}
              <br />
              <li>
                <a href={bp201t} target="_blank" rel="noreferrer">
                  BP 201T Q.P.
                </a>
              </li>
              <li>
                <a href={bp202t} target="_blank" rel="noreferrer">
                  BP 202T Q.P.
                </a>
              </li>
              <li>
                <a href={bp203t} target="_blank" rel="noreferrer">
                  BP 203T Q.P.
                </a>
              </li>
              <li>
                <a href={bp204t} target="_blank" rel="noreferrer">
                  BP 204T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp301t} target="_blank" rel="noreferrer">
                  BP 301T Q.P.
                </a>
              </li>
              <li>
                <a href={bp302t} target="_blank" rel="noreferrer">
                  BP 302T Q.P.
                </a>
              </li>
              <li>
                <a href={bp303t} target="_blank" rel="noreferrer">
                  BP 303T Q.P.
                </a>
              </li>
              <li>
                <a href={bp304t} target="_blank" rel="noreferrer">
                  BO 304T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp401t} target="_blank" rel="noreferrer">
                  BP 401T Q.P.
                </a>
              </li>
              <li>
                <a href={bp402t} target="_blank" rel="noreferrer">
                  BP 402T Q.P.
                </a>
              </li>
              <li>
                <a href={bp403t} target="_blank" rel="noreferrer">
                  BP 403T Q.P.
                </a>
              </li>
              <li>
                <a href={bp404t} target="_blank" rel="noreferrer">
                  BP 404T Q.P.
                </a>
              </li>
              <li>
                <a href={bp405t} target="_blank" rel="noreferrer">
                  BP 405T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp501t} target="_blank" rel="noreferrer">
                  BP 501T Q.P.
                </a>
              </li>
              <li>
                <a href={bp502t} target="_blank" rel="noreferrer">
                  BP 502T Q.P.
                </a>
              </li>
              <li>
                <a href={bp503t} target="_blank" rel="noreferrer">
                  BP 503T Q.P.
                </a>
              </li>
              <li>
                <a href={bp504t} target="_blank" rel="noreferrer">
                  BP 504T Q.P.
                </a>
              </li>
              <li>
                <a href={bp505t} target="_blank" rel="noreferrer">
                  BP 505T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp601t} target="_blank" rel="noreferrer">
                  BP 601T Q.P.
                </a>
              </li>
              <li>
                <a href={bp602t} target="_blank" rel="noreferrer">
                  BP 602T Q.P.
                </a>
              </li>
              <li>
                <a href={bp603t} target="_blank" rel="noreferrer">
                  BP 603T Q.P.
                </a>
              </li>
              <li>
                <a href={bp604t} target="_blank" rel="noreferrer">
                  BP 604T Q.P.
                </a>
              </li>
              <li>
                <a href={bp605t} target="_blank" rel="noreferrer">
                  BP 605T Q.P.
                </a>
              </li>
              <li>
                <a href={bp606t} target="_blank" rel="noreferrer">
                  BP 606T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp701tQPOLdBatch} target="_blank" rel="noreferrer">
                  BP 701T Q.P Old Batch
                </a>
              </li>
              <li>
                <a href={bp701t} target="_blank" rel="noreferrer">
                  BP 701T Q.P.
                </a>
              </li>
              <li>
                <a href={bp702tqpOldbatch} target="_blank" rel="noreferrer">
                  BP 702TQ.P Old Batch
                </a>
              </li>
              <li>
                <a href={bp702t} target="_blank" rel="noreferrer">
                  BP 702T Q.P.
                </a>
              </li>
              <li>
                <a href={bp703tqpOldbatch} target="_blank" rel="noreferrer">
                  BP 703T Q P Old Batch
                </a>
              </li>
              <li>
                <a href={bp703t} target="_blank" rel="noreferrer">
                  BP 703T Q.P.
                </a>
              </li>
              <li>
                <a href={bp704opOldBatch} target="_blank" rel="noreferrer">
                  BP 704T Q.P Old Batch{" "}
                </a>
              </li>
              <li>
                <a href={bp705opOldBatch} target="_blank" rel="noreferrer">
                  BP 705T Old Batch
                </a>
              </li>
              <li>
                <a href={bp706opOldBatch} target="_blank" rel="noreferrer">
                  BP 706T Q.P Old Batch
                </a>
              </li>{" "}
              <br />
              <li>
                <a href={bp801tQP} target="_blank" rel="noreferrer">
                  BP 801T Q.P.
                </a>
              </li>
              <li>
                <a href={bp802tQP} target="_blank" rel="noreferrer">
                  BP 802T Q.P.
                </a>
              </li>
              <li>
                <a href={bp803etQP} target="_blank" rel="noreferrer">
                  BP 803ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp804etQP} target="_blank" rel="noreferrer">
                  BP 804ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp805etQP} target="_blank" rel="noreferrer">
                  BP 805ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp808etQP} target="_blank" rel="noreferrer">
                  BP 808ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp809etQP} target="_blank" rel="noreferrer">
                  BP 809ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp811etQP} target="_blank" rel="noreferrer">
                  BP 811ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp812etQP} target="_blank" rel="noreferrer">
                  BP 812ET Q.P.
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <h3 className="dpsru-secondary-heading-for-every-page"> (2022)</h3>

      {/* 31Dec 2025 */}

      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setDMLT1st2022(!DMLT1st2022)}
        >
          DMLT QP 1st Semester
        </button>
        {DMLT1st2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={DML101T} target="_blank" rel="noreferrer">
                  DML-101T
                </a>
              </li>
              <li>
                <a href={DML102T} target="_blank" rel="noreferrer">
                  DML-102T
                </a>
              </li>
              <li>
                <a href={DML103T} target="_blank" rel="noreferrer">
                  DML-103T
                </a>
              </li>
              <li>
                <a href={DML104T} target="_blank" rel="noreferrer">
                  DML-104T
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBbaCH2022(!BbaCH2022)}
        >
          BBA-CH QP 1st Semester
        </button>
        {BbaCH2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BbaCh101T2022} target="_blank" rel="noreferrer">
                  BBA-101T
                </a>
              </li>

              <li>
                <a href={BbaCh102T2022} target="_blank" rel="noreferrer">
                  BBA-102T
                </a>
              </li>

              <li>
                <a href={BbaCh103T2022} target="_blank" rel="noreferrer">
                  BBA-103T
                </a>
              </li>

              <li>
                <a href={BbaCh104T2022} target="_blank" rel="noreferrer">
                  BBA-104T
                </a>
              </li>

              <li>
                <a href={BbaCh105T2022} target="_blank" rel="noreferrer">
                  BBA-105T
                </a>
              </li>

              <li>
                <a href={BbaCh106T2022} target="_blank" rel="noreferrer">
                  BBA-106T
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBPharmAyu2022(!bPharmAyr2022)}
        >
          B.Pharm (Ayur) QP 1st Semester
        </button>
        {bPharmAyr2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BpharmAyu101t2022} target="_blank" rel="noreferrer">
                  BPA-101T
                </a>
              </li>

              <li>
                <a href={BpharmAyu102t2022} target="_blank" rel="noreferrer">
                  BPA-102T
                </a>
              </li>

              <li>
                <a href={BpharmAyu103t2022} target="_blank" rel="noreferrer">
                  BPA-103T
                </a>
              </li>

              <li>
                <a href={BpharmAyu104t2022} target="_blank" rel="noreferrer">
                  BPA-104T
                </a>
              </li>

              <li>
                <a href={HS101T2022} target="_blank" rel="noreferrer">
                  HS-101T
                </a>
              </li>

              <li>
                <a href={HS102T2022} target="_blank" rel="noreferrer">
                  HS-102T
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* 31Dec 2025 */}

      {/* box */}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMp2022(!mp2022)}
        >
          Master in Pharmacy
        </button>
        {mp2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={mp1} target="_blank" rel="noreferrer">
                  M. Pharm Executive MRA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp2} target="_blank" rel="noreferrer">
                  M. Pharm MAT Common Paper 1st semester May 2022
                </a>
              </li>
              <li>
                <a href={mp3} target="_blank" rel="noreferrer">
                  M. Pharm MCC 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp4} target="_blank" rel="noreferrer">
                  M. Pharm MHP 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp5} target="_blank" rel="noreferrer">
                  M. Pharm MIP 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp6} target="_blank" rel="noreferrer">
                  M. Pharm MPA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp7} target="_blank" rel="noreferrer">
                  M. Pharm MPB 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp8} target="_blank" rel="noreferrer">
                  M. Pharm MPC 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp9} target="_blank" rel="noreferrer">
                  M. Pharm MPCR 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp10} target="_blank" rel="noreferrer">
                  M. Pharm MPG 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp11} target="_blank" rel="noreferrer">
                  M. Pharm MPH 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp12} target="_blank" rel="noreferrer">
                  M. Pharm MPL 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp13} target="_blank" rel="noreferrer">
                  M. Pharm MQA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp14} target="_blank" rel="noreferrer">
                  M. Pharm MRA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp15} target="_blank" rel="noreferrer">
                  M. Pharm YGS (Yoga Science) 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMha2022(!mha2022)}
        >
          Master of Hospital Administration
        </button>
        {mha2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MHA} target="_blank" rel="noreferrer">
                  MHA 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  3*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMph2022(!mph2022)}
        >
          Master of Public Health
        </button>
        {mph2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MPH} target="_blank" rel="noreferrer">
                  M. PH. First Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  4*/}
      <div className="questionPaper-content-container">
        {/* <h4 className="questionPaper-Container-heading">
          MBA ( Pharmaceutical Management)
        </h4> */}
        <button
          className="questionPaper-btn"
          onClick={() => setMbaPm2022(!mbaPm2022)}
        >
          MBA ( Pharmaceutical Management)
        </button>
        {mbaPm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MBAPM} target="_blank" rel="noreferrer">
                  MBA PM 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  5*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMbaPet2022(!mbaPet2022)}
        >
          MBA (Pharma Economics and Trade)
        </button>
        {mbaPet2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MBAPET} target="_blank" rel="noreferrer">
                  MBA PET 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  6*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMscAipm2022(!mscAipm2022)}
        >
          M.Sc. (Artificial Intelligence and Precision Medicine)
        </button>
        {mscAipm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={msc2022aipm} target="_blank" rel="noreferrer">
                  M.Sc. AI & PM 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  6*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setMpsm2022(!mpsm2022)}
        >
          Master of Physiotherapy in Sports Medicine
        </button>
        {mpsm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MPTS} target="_blank" rel="noreferrer">
                  MPTS 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  7*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBp2022(!bp2022)}
        >
          Bachelor of Pharmacy
        </button>
        {bp2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPharm} target="_blank" rel="noreferrer">
                  B Pharm 1st Semester April 2022
                </a>
              </li>
              <li>
                <a href={BPharm2} target="_blank" rel="noreferrer">
                  B Pharm 3rd Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm3} target="_blank" rel="noreferrer">
                  B Pharm 3rd Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm4} target="_blank" rel="noreferrer">
                  B Pharm 5th Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm5} target="_blank" rel="noreferrer">
                  B Pharm 5th Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm6} target="_blank" rel="noreferrer">
                  B Pharm 7th Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm7} target="_blank" rel="noreferrer">
                  B Pharm 7th Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm8} target="_blank" rel="noreferrer">
                  B Pharm 8th Semester July 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  8*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBphy2022(!bphy2022)}
        >
          Bachelor of Physiotherapy
        </button>
        {bphy2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPT1} target="_blank" rel="noopener">
                  BPT 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={BPT3} target="_blank" rel="noopener">
                  BPT 3rd&nbsp; Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT5} target="_blank" rel="noopener">
                  BPT 5th Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT6} target="_blank" rel="noopener">
                  BPT 6th Semester August&nbsp; 2022
                </a>
              </li>
              <li>
                <a href={BPT7} target="_blank" rel="noopener">
                  BPT 7th Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT8} target="_blank" rel="noopener">
                  BPT 8th Semester August 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  9*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBscss2022(!bscss2022)}
        >
          B.Sc. (Hons.) Sports Science
        </button>
        {bscss2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BSS1} target="_blank" rel="noopener">
                  BSS 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={BSS3} target="_blank" rel="noopener">
                  BSS 3rd Semester (New Scheme), March 2022
                </a>
              </li>
              <li>
                <a href={BSS4} target="_blank" rel="noopener">
                  BSS 4th Semester, August&nbsp; 2022
                </a>
              </li>
              <li>
                <a href={BSS5} target="_blank" rel="noopener">
                  BSS 5th Semester (Old Scheme), March 2022
                </a>
              </li>
              <li>
                <a href={BSS6} target="_blank" rel="noopener">
                  BSS 6th Semester (Old Scheme), July&nbsp; 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  10*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBpa2022(!bpa2022)}
        >
          Bachelor of Pharmacy (Ayurveda)
        </button>
        {bpa2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPAYU} target="_blank" rel="noopener">
                  B.Pharm (Ayur) 1st Semester June 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  10*/}
      <div className="questionPaper-content-container">
        <button
          className="questionPaper-btn"
          onClick={() => setBmlt2022(!bmlt2022)}
        >
          BMLT
        </button>
        {bmlt2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BMLT} target="_blank" rel="noopener">
                  BMLT 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* Diploma programmes */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        Diploma programmes
      </h3>

      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">Diploma in Pharmacy</h4>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2022(!dp2022)}
          >
            2022
          </button>
          {dp2022 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp221} target="_blank" rel="noreferrer">
                    1st Year Annual Examination August 2022
                  </a>
                </li>
                <li>
                  <a href={dp222} target="_blank" rel="noreferrer">
                    2nd Year Annual Examination May, 2022
                  </a>
                </li>
                <li>
                  <a href={dp223} target="_blank" rel="noreferrer">
                    D. Pharm 2nd Year Supplementary , August 2022
                  </a>
                </li>
                <li>
                  <a href={dp224} target="_blank" rel="noreferrer">
                    D. Pharm 2nd Year Supplementary Examination , April 2022
                  </a>
                </li>
                <li>
                  <a href={dp225} target="_blank" rel="noreferrer">
                    D. Pharm 1st Year Supplementary Examination April, 2022
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2021(!dp2021)}
          >
            2021
          </button>
          {dp2021 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp211}>
                    D. Pharm 1st Year Annual Examination. September, 2021
                  </a>
                </li>
                <li>
                  <a href={dp212}>
                    D. Pharm 2nd Year Annual Examination, May 2021
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2020(!dp2020)}
          >
            2020
          </button>
          {dp2020 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp201}>
                    D. Pharm 1st Year Supplementary Examination, October 2020
                  </a>
                </li>
                <li>
                  <a href={dp202}>
                    D. Pharm 2nd Year Annual Examination, September 2020
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2019(!dp2019)}
          >
            2019
          </button>
          {dp2019 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp191}>
                    D. Pharm 2nd Year Supplementary Examination, September, 2019
                  </a>
                </li>
                <li>
                  <a href={dp192}>
                    D. Pharm 1st Year Supplementary Examination, September, 2019
                  </a>
                </li>
                <li>
                  <a href={dp193}>
                    D. Pharm 1st Year Annual Examination, April 2019
                  </a>
                </li>
                <li>
                  <a href={dp194}>
                    D. Pharm 2nd Year Annual Examination, April 2019
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2018(!dp2018)}
          >
            2018
          </button>
          {dp2018 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp181}>
                    D. Pharm 2nd Year Supplementary Examination, September, 2018
                  </a>
                </li>
                <li>
                  <a href={dp182}>
                    D. Pharm 1st Year Supplementary Examination, September, 2018
                  </a>
                </li>
                <li>
                  <a href={dp183}>
                    D. Pharm 2nd Year Annual Examination, May 2018
                  </a>
                </li>
                <li>
                  <a href={dp184}>
                    D. Pharm 1st Year Annual Examination, May 2018
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2017(!dp2017)}
          >
            2017
          </button>
          {dp2017 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp171}>
                    D. Pharm 1st Year Supplementary Examination, September, 2017
                  </a>
                </li>
                <li>
                  <a href={dp172}>
                    D. Pharm 1st Year Annual Examination, May 2017
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">DMLT</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setDmlt2022(!dmlt2022)}
        >
          2022
        </button>
        {dmlt2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={dmlt} target="_blank" rel="noreferrer">
                  DMLT 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <h3 className="dpsru-secondary-heading-for-every-page">
        Certificate Programmes (WCSC)
      </h3>
    </div>
  );
}

export default QuestionPaper;
